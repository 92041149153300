import React from 'react'
import styled from 'styled-components';

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;

    th, td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
        background-color: white;
    }

    th {
        background-color: #f2f2f2;
    }
`;

const PayServicesTable = () => {
  return (
    <StyledTable>
            <thead>
                <tr>
                    <th colSpan="3" style={{backgroundColor: 'rgba(124, 149, 177, 0.30)', textAlign: 'center'}}>PADECIMIENTOS</th>
                </tr>
                <tr>
                    <th style={{backgroundColor: 'rgba(124, 149, 177, 0.05)', textAlign: 'center'}}>SERVICIO</th>
                    <th style={{backgroundColor: 'rgba(124, 149, 177, 0.05)', textAlign: 'center'}}>TIEMPO DE ESPERA</th>
                    <th style={{backgroundColor: 'rgba(124, 149, 177, 0.05)', textAlign: 'center'}}>APLICA PARA</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Accidentes dentales</td>
                    <td>3 meses</td>
                    <td>Titular</td>
                </tr>
                <tr>
                    <td>Cualquier tipo de cáncer</td>
                    <td>Sin periodo de espera</td>
                    <td>Titular</td>
                </tr>
                <tr>
                    <td>Infarto agudo de miocardio</td>
                    <td>Sin periodo de espera</td>
                    <td>Titular</td>
                </tr>
                <tr>
                    <td>Enfermedades cerebro vasculares</td>
                    <td>Sin periodo de espera</td>
                    <td>Titular</td>
                </tr>
                <tr>
                    <td>Pago directo de servicios por accidentes</td>
                    <td>Sin periodo de espera</td>
                    <td>Titular</td>
                </tr>
                <tr>
                    <td>Fractura de huesos por accidente</td>
                    <td>Sin periodo de espera</td>
                    <td>Titular</td>
                </tr>
                <tr>
                    <td>Apendicectomía (cirugía de apéndice)</td>
                    <td>3 meses</td>
                    <td>Titular y/o Beneficiaros *</td>
                </tr>
                <tr>
                    <td>Colecistectomía (cirugía de vesícula biliar)</td>
                    <td>3 meses</td>
                    <td>Titular y/o Beneficiaros *</td>
                </tr>
                <tr>
                    <td>Hernioplastía (cirugía de hernias)</td>
                    <td>3 meses</td>
                    <td>Titular y/o Beneficiaros *</td>
                </tr>
                <tr>
                    <td>Amigdalectomía (cirugía de amígdalas)</td>
                    <td>3 meses</td>
                    <td>Titular y/o Beneficiaros *</td>
                </tr>
                <tr>
                    <td>Neumonía (Estancia hospitalaria mayor a 48 horas por neumonía)</td>
                    <td>3 meses</td>
                    <td>Titular</td>
                </tr>
            </tbody>
        </StyledTable>
  )
}

export default PayServicesTable