import styled from "styled-components";

export const OverlayModal = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    color: #000;
    text-align: center;

    /* Desktop/H2 */
    font-family: Josefin Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`
export const MainContModal = styled.div`
    padding: 30px 16px 50px 16px;
    width: 500px;
    height: auto;
    background: #FFF;
    position: relative;
    border-radius: 16px;
`
export const ContBtnModal = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 200px;
    margin-top: 100px;
`
export const BtnAceptar = styled.button`
    display: flex;
    width: 140px;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border: none;
    cursor: pointer;
    border-radius: 40px;
    background: var(--Primary, #00C46B);

    color: var(--Background, #F6F6F6);
    text-align: center;

    /* Common/Button */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`

export const BtnCancelar = styled.button`
    display: flex;
    width: 140px;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border: 1px solid var(--Primary, #00C46B);
    cursor: pointer;
    border-radius: 40px;
    background: none;

    color: var(--Background, #00C46B);
    text-align: center;

    /* Common/Button */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`