import React, { useEffect, useState, useRef } from 'react'
import Hero from '../../../components/HistorialServicios/Hero/Hero'
import CardsHistorial from '../../../components/HistorialServicios/Hero/cards/CardsHistorial'
import CardHorariosTeleconsulta from '../../../components/HistorialServicios/CardHorarios/CardHoriariosTeleconsulta'
//Assets
import maletin from '../../../assets/laptop.svg'
//styles
import { CardsContainer, LlamadasSubt, LlamadasSubtMob, MainContainer, MainContainerMob } from './HistorialServicios.Styled'
import CardHorarios from '../../../components/HistorialServicios/CardHorarios/CardHorarios'
import requester from '../../../Apis/requester'
import PageBase from '../../../components/PageBase';
import CardHorariosCharts from '../../../components/HistorialServicios/CardHorarios/CardHorariosCharts'
import HistoryCardsCarrousel from './HistoryCardsCarrousel';

export default function HistorialServicios() {
  // eslint-disable-next-line 
  const [data, setData] = useState(null);
  const [openCard, setOpenCard] = useState(null);
  const idPacient = '31022b9d-2df1-4410-b4c2-d18665792357';
  const llamadasSubtRef = useRef(null);
  const llamadasSubDESKTOPNOMAMEStRef = useRef(null);

  const getSchedule = async () => {
    const dataSchedule = await requester({
      url: `/api/getAppointments/${idPacient}`,
    });
    setData(dataSchedule.response[0]);
  };

  useEffect(() => {
    getSchedule();
  }, []);

  const handleCardClick = (cardName) => {
    if (openCard === cardName) {
      // Si la tarjeta que se hizo clic ya está abierta, ciérrala.
      setOpenCard(null);
    } else {
      // Si la tarjeta que se hizo clic no está abierta, ábrela.
      setOpenCard(cardName);
    }
  };

  return (
    <PageBase>
      <MainContainer>
        <Hero title={"Historial de servicios"} />
        {/* <CardsContainer> */}
        {/* <CardsHistorial
            imgCard={maletin}
            titulo={"Historial de Notas Médicas"}
            onClick={() => handleCardClick("notasMedicas")}
            isOpen={openCard === "notasMedicas"}
          /> */}
        {/* <CardsHistorial
            imgCard={maletin}
            titulo={"Historial de llamadas de Orientación Médica Telefónica"}
            onClick={() => handleCardClick("llamadasTelefonicas")}
            isOpen={openCard === "llamadasTelefonicas"}
          /> */}
        {/* <CardsHistorial
            imgCard={maletin}
            titulo={"Historial de Teleconsultas"}
            onClick={() => handleCardClick("teleconsultas")}
            isOpen={openCard === "teleconsultas"}
          /> */}
        {/* <CardsHistorial
            imgCard={maletin}
            titulo={"Gráficas de Control Médico"}
            onClick={() => handleCardClick("graficasControl")}
            isOpen={openCard === "graficasControl"}
          /> */}
        {/* </CardsContainer> */}

        <>
          {/* {openCard === "notasMedicas" && (
            <HistoryCardsCarrousel
              components={[
                <CardHorarios
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Interrogatorio"}
                  opcion2={"Medicamento"}
                  opcion3={"Indicaciones generales"}
                />,
                <CardHorarios
                  name='Lozano perez'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Interrogatorio"}
                  opcion2={"Medicamento"}
                  opcion3={"Indicaciones generales"}
                />,
                <CardHorarios
                  name='Lozano tres'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Interrogatorio"}
                  opcion2={"Medicamento"}
                  opcion3={"Indicaciones generales"}
                />,
              ]}
            />
          )} */}
          {/* {openCard === "llamadasTelefonicas" && ( */}
          <HistoryCardsCarrousel llamadasSubtRef={llamadasSubtRef}
            components={[
              <CardHorarios
                name='Lozano uno'
                date='13/12/2013'
                time='10:30'
                status='open'
                folio='1234156'
                opcion1={"Antecedente"}
                opcion2={"Nota"}
                opcion3={"Recomendación del médico"}
                opcion4={"Datos"}
              />,
              <CardHorarios
                name='Lozano dos'
                date='13/12/2013'
                time='10:30'
                status='open'
                folio='1234156'
                opcion1={"Antecedente"}
                opcion2={"Nota"}
                opcion3={"Recomendación del médico"}
                opcion4={"Datos"}
              />,
              <CardHorarios
                name='Lozano tres'
                date='13/12/2013'
                time='10:30'
                status='open'
                folio='1234156'
                opcion1={"Antecedente"}
                opcion2={"Nota"}
                opcion3={"Recomendación del médico"}
                opcion4={"Datos"}
              />,
            ]}
          />
          {/* {openCard === "teleconsultas" && (
            <HistoryCardsCarrousel
              components={[
                <CardHorariosTeleconsulta
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Calificación"}
                  opcion2={"Comentarios"}
                />,
                <CardHorariosTeleconsulta
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Calificación"}
                  opcion2={"Comentarios"}
                />,
                <CardHorariosTeleconsulta
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Calificación"}
                  opcion2={"Comentarios"}
                />,
              ]}
            />
          )} */}
          {/* {openCard === "graficasControl" && (
            <HistoryCardsCarrousel
              components={[
                <CardHorariosCharts
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  isOpen={openCard === "graficasControl"}
                />,
                <CardHorariosCharts
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  isOpen={openCard === "graficasControl"}
                />,
                <CardHorariosCharts
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  isOpen={openCard === "graficasControl"}
                />,
              ]}
            />
          )} */}
        </>
      </MainContainer>
      <MainContainerMob>
        <Hero title={"Historial de servicio"} />
        {/* <CardsContainer>
          <CardsHistorial
            imgCard={maletin}
            titulo={"Historial de Notas Médicas"}
            onClick={() => handleCardClick("notasMedicas")}
            isOpen={openCard === "notasMedicas"}
          />
          {openCard === "notasMedicas" && (
            <HistoryCardsCarrousel
              components={[
                <CardHorarios
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Interrogatorio"}
                  opcion2={"Medicamento"}
                  opcion3={"Indicaciones generales"}
                />,
                <CardHorarios
                  name='Lozano perez'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Interrogatorio"}
                  opcion2={"Medicamento"}
                  opcion3={"Indicaciones generales"}
                />,
                <CardHorarios
                  name='Lozano tres'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Interrogatorio"}
                  opcion2={"Medicamento"}
                  opcion3={"Indicaciones generales"}
                />,
              ]}
            />
          )}
        </CardsContainer> */}
        {/* <CardsContainer>
          <div ref={cardsHistorialRef}>
            <CardsHistorial
              imgCard={maletin}
              titulo={"Historial de llamadas de Orientación Médica Telefónica"}
              onClick={() => handleCardClick("llamadasTelefonicas")}
              isOpen={openCard === "llamadasTelefonicas"}
            />
          </div>
          {openCard === "llamadasTelefonicas" && ( */}
        <HistoryCardsCarrousel llamadasSubtRef={llamadasSubDESKTOPNOMAMEStRef}
          components={[
            <CardHorarios
              name='Lozano uno'
              date='13/12/2013'
              time='10:30'
              status='open'
              folio='1234156'
              opcion1={"Antecedente"}
              opcion2={"Nota"}
              opcion3={"Recomendación del médico"}
              opcion4={"Datos"}
            />,
            <CardHorarios
              name='Lozano dos'
              date='13/12/2013'
              time='10:30'
              status='open'
              folio='1234156'
              opcion1={"Antecedente"}
              opcion2={"Nota"}
              opcion3={"Recomendación del médico"}
              opcion4={"Datos"}
            />,
            <CardHorarios
              name='Lozano tres'
              date='13/12/2013'
              time='10:30'
              status='open'
              folio='1234156'
              opcion1={"Antecedente"}
              opcion2={"Nota"}
              opcion3={"Recomendación del médico"}
              opcion4={"Datos"}
            />,
          ]}
        />
        {/* )}
        </CardsContainer> */}
        {/* <CardsContainer>
          <CardsHistorial
            imgCard={maletin}
            titulo={"Historial de Teleconsultas"}
            onClick={() => handleCardClick("teleconsultas")}
            isOpen={openCard === "teleconsultas"}
          />
          {openCard === "teleconsultas" && (
            <HistoryCardsCarrousel
              components={[
                <CardHorariosTeleconsulta
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Calificación"}
                  opcion2={"Comentarios"}
                />,
                <CardHorariosTeleconsulta
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Calificación"}
                  opcion2={"Comentarios"}
                />,
                <CardHorariosTeleconsulta
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Calificación"}
                  opcion2={"Comentarios"}
                />,
              ]}
            />
          )}
        </CardsContainer> */}
        {/* <CardsContainer>
          <CardsHistorial
            imgCard={maletin}
            titulo={"Gráficas de Control Médico"}
            onClick={() => handleCardClick("graficasControl")}
            isOpen={openCard === "graficasControl"}
          />
          {openCard === "graficasControl" && (
            <HistoryCardsCarrousel
              components={[
                <CardHorariosCharts
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  isOpen={openCard === "graficasControl"}
                />,
                <CardHorariosCharts
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  isOpen={openCard === "graficasControl"}
                />,
                <CardHorariosCharts
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  isOpen={openCard === "graficasControl"}
                />,
              ]}
            />
          )}
        </CardsContainer> */}

        {/* <> */}
        {/* {openCard === "notasMedicas" && (
            <HistoryCardsCarrousel
              components={[
                <CardHorarios
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Interrogatorio"}
                  opcion2={"Medicamento"}
                  opcion3={"Indicaciones generales"}
                />,
                <CardHorarios
                  name='Lozano perez'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Interrogatorio"}
                  opcion2={"Medicamento"}
                  opcion3={"Indicaciones generales"}
                />,
                <CardHorarios
                  name='Lozano tres'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Interrogatorio"}
                  opcion2={"Medicamento"}
                  opcion3={"Indicaciones generales"}
                />,
              ]}
            />
          )} */}
        {/* {openCard === "llamadasTelefonicas" && (
            <HistoryCardsCarrousel
              components={[
                <CardHorarios
                  name='Lozano uno'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Antecedente"}
                  opcion2={"Nota"}
                  opcion3={"Recomendación del médico"}
                  opcion4={"Datos"}
                />,
                <CardHorarios
                  name='Lozano dos'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Antecedente"}
                  opcion2={"Nota"}
                  opcion3={"Recomendación del médico"}
                  opcion4={"Datos"}
                />,
                <CardHorarios
                  name='Lozano tres'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Antecedente"}
                  opcion2={"Nota"}
                  opcion3={"Recomendación del médico"}
                  opcion4={"Datos"}
                />,
              ]}
            />
          )} */}
        {/* {openCard === "teleconsultas" && (
            <HistoryCardsCarrousel
              components={[
                <CardHorariosTeleconsulta
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Calificación"}
                  opcion2={"Comentarios"}
                />,
                <CardHorariosTeleconsulta
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Calificación"}
                  opcion2={"Comentarios"}
                />,
                <CardHorariosTeleconsulta
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  opcion1={"Calificación"}
                  opcion2={"Comentarios"}
                />,
              ]}
            />
          )} */}
        {/* {openCard === "graficasControl" && (
            <HistoryCardsCarrousel
              components={[
                <CardHorariosCharts
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  isOpen={openCard === "graficasControl"}
                />,
                <CardHorariosCharts
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  isOpen={openCard === "graficasControl"}
                />,
                <CardHorariosCharts
                  name='Lozano'
                  date='13/12/2013'
                  time='10:30'
                  status='open'
                  folio='1234156'
                  isOpen={openCard === "graficasControl"}
                />,
              ]}
            />
          )}
        </> */}
      </MainContainerMob>
    </PageBase>
  );
}
