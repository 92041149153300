import styled from "styled-components";
import { Box, styled as muiStyled, Typography } from "@mui/material";

export const Frame = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    background: '#FFFFFF',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    marginTop: '32px',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
        background: '#F6f6f6',
        marginTop: '0',
    },
}))

export const ContentWrapper = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    flexShrink: '0',
    alignSelf: 'stretch',
    background: '#FFFFFF',
    padding: '0 94px',
    boxSizing: 'border-box',

    [theme.breakpoints.down('lg')]: {
        padding: '0 50px',
    },
    [theme.breakpoints.down('md')]: {
        background: 'none',
        padding: '0 24px',
    }
}))

export const Content = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    gap: '10px',
    [theme.breakpoints.down('lg')]: {
        gap: '40px',
    },
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
        alignItems: 'center',
        gap: '32px',
    }
}))

export const ContentInfo = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 0px',
    gap: '56px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: '1 0 0',
    [theme.breakpoints.down('md')]: {
        padding: '0',
        gap: '32px',
    },
}))

export const TitleContainer = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

export const Title = muiStyled(Typography)(({ theme }) => ({
    color: '#104C7C',
    fontFamily: 'Josefin Sans',
    fontSize: '66px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    margin: '0',
    padding: '0',
    [theme.breakpoints.down('md')]: {
        fontSize: '40px',
    },
}))

export const BrandName = muiStyled(Typography)(({ theme }) => ({
    color: '#6C81A0',
    fontFamily: 'Josefin Sans',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    margin: '0',
    padding: '0',
    [theme.breakpoints.down('md')]: {
        fontSize: '22px',
    },
}))

export const BrandDescription = muiStyled(Typography)(({ theme }) => ({
    color: '#6C81A0',
    fontFamily: 'Josefin Sans',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    margin: '0',
    padding: '0',
    [theme.breakpoints.down('md')]: {
        fontSize: '16px',
        fontWeight: '400',
    },
}))

export const ContentImg = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignSelf: 'stretch',
    maxWidth: '630px',
    width: '100%',
    minHeight: '584px',
    minWidth: '220px',
    [theme.breakpoints.down('md')]: {
        maxWidth: 'none',
        minHeight: 'min-content',
    },
}))

export const PrincipalImg = styled.img`
    width: 100%;
`

export const Card = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '40px 10px 40px 40px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    alignSelf: 'stretch',
    borderRadius: '10px',
    maxWidth: '630px',
    background: '#FFFFFF',
    boxShadow: '0px 19px 69px 0px rgba(224, 223, 233, 0.38)',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
        padding: '32px 24px',
    },
}))

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
`

export const ServiceFrameText = muiStyled(Typography)(({ theme }) => ({
    color: '#444',
    fontFamily: 'Josefin Sans',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    margin: '0',
    padding: '0',
    [theme.breakpoints.down('md')]: {
        fontSize: '22px',
    },
}))

export const ServiceFrame = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        width: '100%',
    },
}))

export const ButtonFrame = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    width: '256px',
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}))

export const Button = styled.button`
    display: flex;
    width: 100%;
    padding: 16px 24px;
    margin-bottom: 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    background: #104C7C;
    color: white;
    border-style: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Lato';
`
