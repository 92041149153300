import React, { useState, createRef, useEffect } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Frame, Content, IconContainer, ImageCard, TitleCard, Wrapper } from './style'
import CardServiciosTeleconsulta from '../CardServicios/CardServiciosTeleconsulta';
import CardServiciosConcentracionCitas from "../CardServicios/CardServiciosConcentracionCitas";
import CardServiciosAmbulancia from "../CardServicios/CardServiciosAmbulancia";
import CardServiciosOrientaciones from "../CardServicios/CardServiciosOrientaciones";
import CardServiciosDeteccion from '../../CardServiciosDeteccion';
import CardDescriptionCheckUp from '../../CardDescriptionCheckUp';
import CardDescriptionGridDiscount from '../../CardDescriptionGridDiscount';
import CardDescriptionDoctorAtHome from '../../CardDescriptionDoctorAtHome';
import CardDescriptionDentalPlan from '../../CardDescriptionDentalPlan';
import CardDescriptionPayServices from '../../CardDescriptionPayServices';
import { Box } from '@mui/material';
import capitalizeFirstLetter from '../../../helpers/capitalizeFirstLetter';
import { Element } from 'react-scroll';
import { Link, animateScroll as scroll } from 'react-scroll'

const BenefitCardsMobil = ({
    cards,
}) => {
    const [isExpandCardName, setisExpandCardName] = useState('')
    const [selectedCardIndex, setSelectedCardIndex] = useState(null);

    const refs = cards.reduce((acc, value, index) => {
        acc[index] = createRef();
        return acc;
    }, {});
    const handleExpandCard = (cardName, index) => {
        if (isExpandCardName === cardName) {
            setisExpandCardName('')

            return
        }
        setisExpandCardName(cardName)
        setSelectedCardIndex(index);
    }

    let selectedPopUpComponent;

    switch (isExpandCardName) {
        case "ambulancia":
            selectedPopUpComponent = <CardServiciosAmbulancia />;
            break;
        case "orientacionesMedicas":
            selectedPopUpComponent = <CardServiciosOrientaciones />;
            break;
        case "Detección oportuna de riesgos":
            selectedPopUpComponent = <CardServiciosDeteccion />;
            break;
        case "checkup":
            selectedPopUpComponent = <CardDescriptionCheckUp />;
            break;
        case "teleconsulta":
            selectedPopUpComponent = <CardServiciosTeleconsulta />;
            break;
        case "concertacion":
            selectedPopUpComponent = <CardServiciosConcentracionCitas />;
            break;
        case "pagoDirectoServicios":
            selectedPopUpComponent = <CardDescriptionPayServices />;
            break;
        case "planDental":
            selectedPopUpComponent = <CardDescriptionDentalPlan />;
            break;
        case "redDescuentos":
            selectedPopUpComponent = <CardDescriptionGridDiscount />;
            break;
        case "medicoFamiliar":
            selectedPopUpComponent = <CardDescriptionDoctorAtHome />;
            break;
        default:
            selectedPopUpComponent = null;
    }

    useEffect(() => {
        if (selectedCardIndex !== null) {
            const myRef = refs[selectedCardIndex];
            const offset = myRef.current?.offsetTop;
            scroll.scrollTo(offset - 50);
        }
    }, [refs, selectedCardIndex]);

    return cards.map((card, index) => (
        <Wrapper key={card.nombreCardPopUp}>
            <Frame
                ref={refs[index]}

                borderRadius={isExpandCardName === card.nombreCardPopUp ? '16px 16px 0 0' : '16px'}
                onClick={() => handleExpandCard(card.nombreCardPopUp, index)}
            >
                <Content>
                    <ImageCard src={card.icono} alt={card.icono} />
                    <TitleCard
                        variant="h3"
                    >
                        {capitalizeFirstLetter(card.titulo)}
                    </TitleCard>
                </Content>
                <IconContainer>
                    {isExpandCardName === card.nombreCardPopUp ? (
                        <ExpandLessIcon htmlColor='#00c46b' />
                    ) : (
                        <ExpandMoreIcon htmlColor='#00C46B' />
                    )}
                </IconContainer>
            </Frame>
            {
                isExpandCardName === card.nombreCardPopUp && (
                    <Box
                        paddingBottom="20px"
                        bgcolor="white"
                        borderRadius={isExpandCardName === card.nombreCardPopUp ? '0 0 16px 16px' : '16px'}
                    >
                        {selectedPopUpComponent}
                    </Box>
                )
            }
        </Wrapper>
    ))
}


export default BenefitCardsMobil