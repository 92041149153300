import { styled as muiStyled, Box, Typography } from "@mui/material";
import styled from "styled-components";

export const Wrapper = muiStyled(Box)(({ theme }) => ({
    padding: '0 24px',
    width: '100%',
    boxSizing: 'border-box',
}));
export const Frame = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
    padding: '8px 16px',
    height: 'auto',
    width: '100%',
    boxSizing: 'border-box',
}));

export const Content = muiStyled(Box)(({ theme }) => ({
    gap: '10px',
    display: 'flex',
    alignItems: 'center',
}));

export const IconContainer = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60px',
    height: '60px',

}));

export const ImageCard = styled.img`
  padding: 12.38px 5.371px 11.89px 4.559px;
  height: 60px;
  box-sizing: border-box;
  `;

export const TitleCard = muiStyled(Typography)`
    text-align: left;
    font-family: Josefin Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
`;