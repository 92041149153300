import React, { useState, useRef, Fragment, useEffect } from 'react';
import ModalTB from '../Modals/ModalTB';
import EditarTel from './EditarTel/EditarTel';
import Calendario from './Calendario/Calendario';
import {
    ContImgGen,
    ContListTel,
    ContListTelMob,
    ContTextList,
    ContUplImg,
    DivisorLisTel,
    DivisorMiPerf,
    ImgPerfText,
    MainContPerf,
    SecContPerf,
    SelImgBtn,
    // SelectCalenPerf,
    SelectCalendText,
    SelectGenPerf,
    SubContPerf,
    CardListTitle,
    CardListText,
    AddTelBtn,
    ContAddBtn,
    ListTelTitle,
    ContLapizTrash,
    ImgList,
    TabRow,
    BtnEditTrash,
    UplImgLabel,
    ButtonSave,
    EncabezadoList,
    ContImgGenn,
    FlexEnd
} from './ImgPerfCard.styles';

import { DateContainer, InputIcon, DateText } from '../../components/MiPerfil/Calendario/Calendario.styles'
// assets
import Circulo from '../../assets/Circle.svg';
import FotoPerfil from '../../assets/image 72.png';
import AgregarTel from './AgregarTel/AgregarTel';
import RecuadroAdd from '../../assets/recuadro-plus.svg'
import LapizEdit from '../../assets/lapiz-edit.svg'
// import Borrar from '../../assets/red-trash-can.svg'
import BorrarGd from '../../assets/Big-trashcan.svg'
import CalendarioIcon from '../../assets/calendar.svg'
import { useDispatch, useSelector } from 'react-redux';
import { selectIdUser, setPhone, setUserImage, userImage } from '../../redux/features/user';
import { ButtonContainer } from './AgregarTel/AgregarTel.styles';
import requester from '../../Apis/requester';




export default function ImgPerfCard({ mostrarPerfil, mostrarTelefono, user }) {
    //Mostrar el modal de eliminar numero
    const [estadoModal1, cambiarEstadoModal1] = useState(false);
    const [mostrarEditar, setMostrarEditar] = useState(false);
    const [todosLosTelefonos, setTodosLosTelefonos] = useState([]);
    const [opcionesTelefonos, setOpcionesTelefonos] = useState(false)
    const [telefonoAEliminar, setTelefonoAEliminar] = useState(null);
    const [telefonoSelected, setTelefonoSelected] = useState({})
    const fileInputRef = useRef(null);
    const [calendarValue, setCalendarValue] = useState(null);
    const [openCalendar, setOpenCalendar] = useState(false);
    const comuteCalendarDaySelected = calendarValue ? calendarValue.format("DD/MM/YYYY") : null;
    const dispatch = useDispatch()
    const userImg = useSelector(userImage)
    const idUser = useSelector(selectIdUser)
    const [mostrarBotones, setMostrarBotones] = useState(true);
    const { sex, birthday, phone } = user


    useEffect(() => {
        if (phone) {
            setTodosLosTelefonos([{ telefono: phone, id: 1 }])
        }
    }, [phone])

    const handleEditarTelefono = (telefonoEditado) => {
        setTodosLosTelefonos((prev) => prev.map((el) => {
            if (el.id === telefonoEditado.id) {
                return telefonoEditado
            }
            return el
        }))
    };


    const handleAgregarTelefonos = (nuevoTelefono) => {
        if (todosLosTelefonos.length === 0) {
            setTodosLosTelefonos([nuevoTelefono]);
            // Oculta el menú después de agregar el teléfono
            cerrarMenuAgregarTelefonos();
        } else {
            alert('Ya has ingresado un teléfono. No se pueden agregar más.');
        }
    };

    const cerrarMenuAgregarTelefonos = () => {
        setOpcionesTelefonos(false);
    };

    const handleMostrarBotones = () => {
        setMostrarBotones(true);
    };


    const handleEliminarTelefono = (telefono) => {
        setTelefonoAEliminar(telefono);
        cambiarEstadoModal1(true);
    };

    const eliminarTelefono = () => {
        if (telefonoAEliminar) {
            const nuevosTelefonos = todosLosTelefonos.filter(
                (telefono) => telefono.id !== telefonoAEliminar.id
            );

            setTodosLosTelefonos(nuevosTelefonos);
        }
        setTelefonoAEliminar(null);
        cambiarEstadoModal1(false);
    };

    const cancelarEliminarTelefono = () => {
        setTelefonoAEliminar(null);
        cambiarEstadoModal1(false);
    };

    // const isValidImage = async (file) => {
    //     const allowedTypes = ['image/jpeg', 'image/png'];
    //     const maxSize = 150; // Tamaño máximo en píxeles

    //     if (file && allowedTypes.includes(file.type)) {
    //         return new Promise((resolve) => {
    //             const img = new Image();
    //             img.onload = () => {
    //                 const width = img.width;
    //                 const height = img.height;

    //                 if (width <= maxSize && height <= maxSize) {
    //                     resolve(true);
    //                 } else {
    //                     resolve(false);
    //                 }
    //             };

    //             img.onerror = () => {
    //                 resolve(false);
    //             };

    //             img.src = URL.createObjectURL(file);
    //         });
    //     }

    //     return false;
    // }; ----------------------------> funcion para validacion de tamaño maximo de archivos

    const isValidImage = async (file) => {
        const allowedTypes = ['image/jpeg', 'image/png'];

        if (file && allowedTypes.includes(file.type)) {
            return new Promise((resolve) => {
                const img = new Image();
                img.onload = () => {
                    resolve(true);
                };

                img.onerror = () => {
                    resolve(false);
                };

                img.src = URL.createObjectURL(file);
            });
        }

        return false;
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (await isValidImage(file)) {

            const formData = new FormData();
            formData.append('file', file);
            formData.append('idUser', idUser);

            const response = await requester({
                url: '/api/update/image-profile',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: formData,
            })
            const data = await response;
            console.log(data)


            const reader = new FileReader();
            reader.onloadend = () => {
                dispatch(setUserImage(reader.result))

            };
            reader.readAsDataURL(file);

            dispatch(setUserImage(data.photo))

        } else {
            // Restablecer la selección si la imagen no cumple con los requisitos
            dispatch(setUserImage(null))

            alert('Por favor, selecciona una imagen válida (JPEG o PNG) con un tamaño máximo de 150x150 px.');
        }
    };

    const handleSelImgBtnClick = () => {
        // Abre el explorador de archivos al hacer clic en el botón
        fileInputRef.current.click();

    };

    const handleEdit = async (id) => {
        setMostrarEditar(!mostrarEditar)
        const telefonoEdit = todosLosTelefonos.find((el) => el.id === id);
        setTelefonoSelected(telefonoEdit);
    }


    return (
        <MainContPerf>
            <ContImgGenn>
                <ContImgGen>
                    {mostrarPerfil && (
                        <>
                            {/* <SubContPerf>
                                <ContUplImg>
                                    <ImgPerfText>Imagen de perfil</ImgPerfText>
                                    <img src={userImg ?? Circulo} alt="Profile Circle" style={{
                                        width: '150px',
                                        height: '150px',
                                        borderRadius: '999%',
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                    }} />
                                    <UplImgLabel htmlFor="fileInput" onClick={handleSelImgBtnClick}>
                                        <SelImgBtn>Seleccione archivo</SelImgBtn>
                                    </UplImgLabel>
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        id="fileInput"
                                        accept="image/jpeg, image/png" // Solo acepta archivos JPG y PNG
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange} />
                                </ContUplImg>
                                <DivisorMiPerf></DivisorMiPerf>
                                <ContUplImg>
                                    <ImgPerfText>Vista previa de imagen</ImgPerfText>
                                    <img src={FotoPerfil} alt="Profile Preview" />
                                </ContUplImg>
                            </SubContPerf> */}
                            {/* <ButtonContainer>
                                <ButtonSave>Guardar</ButtonSave>
                            </ButtonContainer> */}
                        </>
                    )}


                    <SecContPerf>
                        <ContUplImg>
                            <ImgPerfText>Género:</ImgPerfText>
                            <div style={{
                                color: "#6C81A0",
                                fontFamily: "Lato",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "normal",
                            }}>
                                {`${sex}`}
                            </div>
                        </ContUplImg>
                        <ContUplImg>
                            <SelectCalendText>Fecha de nacimiento:</SelectCalendText>
                            <div style={{
                                color: "#6C81A0",
                                fontFamily: "Lato",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "normal",
                            }}>
                                {`${birthday}`}
                            </div>
                            {/* <DateContainer onClick={() => setOpenCalendar(true)}>
                            <InputIcon src={CalendarioIcon} alt="" />
                            <DateText color={!comuteCalendarDaySelected ? "#6C81A0" : "#00C46B"}>
                                {comuteCalendarDaySelected || "DD/MM/YY"}
                            </DateText>
                        </DateContainer>
                        {openCalendar ? <Calendario
                            open={openCalendar}
                            value={calendarValue}
                            onSetValue={setCalendarValue}
                            onClose={() => setOpenCalendar(false)}
                        ></Calendario> : null} */}

                        </ContUplImg>
                    </SecContPerf>
                </ContImgGen>
            </ContImgGenn>

            <ContImgGen>
                <SecContPerf>
                    <ContListTel>
                        <div>

                        </div>
                        <ListTelTitle>Teléfono registrado</ListTelTitle>
                        {
                            mostrarEditar && <EditarTel
                                mostrar={mostrarEditar}
                                setMostrar={setMostrarEditar}
                                telefonoAEditar={telefonoSelected}
                                onEditarTelefono={handleEditarTelefono}
                                idUser={idUser}
                            />
                        }

                        {
                            !mostrarEditar && (
                                <>
                                    <EncabezadoList>
                                        {/* <CardListTitle>Compañía</CardListTitle> */}
                                        <CardListTitle>Teléfono</CardListTitle>
                                        {/* <CardListTitle>Extensión</CardListTitle>
                                        <CardListTitle>Editar</CardListTitle> */}
                                    </EncabezadoList>
                                    {/* <DivisorLisTel></DivisorLisTel> */}
                                    {todosLosTelefonos.map((telefono, index) => (
                                        <>
                                            <ContTextList key={index}>
                                                {/* <CardListText>{telefono.compania}</CardListText> */}
                                                <CardListText>{telefono.telefono}</CardListText>
                                                {/* <CardListText>{telefono.extension}</CardListText> */}
                                                <ContLapizTrash>
                                                    <BtnEditTrash onClick={() => handleEdit(telefono.id)}>
                                                        <ImgList src={LapizEdit} />
                                                    </BtnEditTrash>
                                                    {/* <BtnEditTrash onClick={() => handleEliminarTelefono(telefono)}>
                                                    <ImgList src={Borrar} />
                                                    </BtnEditTrash> */}
                                                </ContLapizTrash>
                                                <ModalTB
                                                    estado={estadoModal1}
                                                    cambiarEstado={cancelarEliminarTelefono}
                                                    onConfirmar={eliminarTelefono}
                                                >
                                                    <img src={BorrarGd} alt="Eliminar número" />
                                                    <p>¿Seguro que deseas eliminar este número {telefonoAEliminar ? telefonoAEliminar.telefono : ''}?</p>
                                                </ModalTB>
                                            </ContTextList>

                                            {/* <DivisorLisTel></DivisorLisTel> */}
                                        </>

                                    ))}

                                    {/* {mostrarBotones && (
                                        <ContAddBtn>
                                            <img src={RecuadroAdd} alt='btn' />
                                            <AddTelBtn onClick={() => {
                                                setOpcionesTelefonos(true);
                                                setMostrarBotones(false);
                                            }}>Agregar teléfono</AddTelBtn>
                                        </ContAddBtn>
                                    )} */}
                                </>
                            )
                        }
                    </ContListTel>
                    <ContListTelMob>
                        <ListTelTitle>Teléfono registrado</ListTelTitle>
                        {
                            mostrarEditar && <EditarTel
                                mostrar={mostrarEditar}
                                setMostrar={setMostrarEditar}
                                telefonoAEditar={telefonoSelected}
                                onEditarTelefono={handleEditarTelefono}
                                idUser={idUser}
                            />
                        }
                        {
                            !mostrarEditar && (
                                <>
                                    {todosLosTelefonos.map((telefono, index) => (
                                        <Fragment key={telefono.id}>
                                            {/* <TabRow>
                                                <CardListTitle>Compañia</CardListTitle>
                                                <CardListText>{telefono.compania}</CardListText>
                                            </TabRow> */}
                                            <TabRow>
                                                <CardListTitle>Telefono</CardListTitle>
                                                <CardListText>{telefono.telefono}</CardListText>
                                            </TabRow>
                                            {/* <TabRow>
                                                <CardListTitle>Extension</CardListTitle>
                                                <CardListText>{telefono.extension}</CardListText>
                                            </TabRow> */}
                                            <TabRow>
                                                <CardListTitle>Editar número</CardListTitle>
                                                <BtnEditTrash onClick={() => handleEdit(telefono.id)}>
                                                    <ImgList src={LapizEdit} />
                                                    {/* <ImgList src={Borrar} /> */}
                                                </BtnEditTrash>
                                            </TabRow>

                                            {/* <DivisorLisTel></DivisorLisTel> */}
                                        </Fragment>

                                    ))}

                                    {/* {mostrarBotones && (
                                        <ContAddBtn>
                                            <img src={RecuadroAdd} alt='btn' />
                                            <AddTelBtn onClick={() => {
                                                setOpcionesTelefonos(true);
                                                setMostrarBotones(false);
                                            }}>Agregar teléfono</AddTelBtn>
                                        </ContAddBtn>
                                    )} */}
                                </>
                            )
                        }
                    </ContListTelMob>
                </SecContPerf>
            </ContImgGen>

            {opcionesTelefonos ? (
                <AgregarTel
                    onAgregar={handleAgregarTelefonos}
                    onCerrar={() => setOpcionesTelefonos(false)}
                    onMostrarBotones={handleMostrarBotones}
                />
            ) : null}
        </MainContPerf >
    );
}
