import { styled as muiStyled, Box, Typography } from "@mui/material";
import styled from "styled-components";

export const Wrapper = muiStyled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
    borderRadius: "16px",
    backgroundColor: "#f6f6f6",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    padding: "16px",
    boxSizing: "border-box",
    width: "195px",
    height: "auto",
    cursor: "pointer",
}));

export const Image = styled.img``;

export const Name = muiStyled(Typography)`
    color: #5F6267;
    text-align: center;

    font-family: Josefin Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;
