import React from 'react'
import { styled, Box, Typography } from '@mui/material'

const Frame = styled(Box)`
    display: flex;
    justify-content: center;
    padding: 10px 0;
    background-color: #F2F4F8;
`

const Wrapper = styled(Box)`
    height: 60px;
    width: 90%;
    display: flex;
`

const TextContainer = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const Text = styled(Typography)`
`


const NavTabMenuClinicRecord = ({
    sectionSelected,
    onSetSectionSelected,
}) => {
    const isSectionSelected = (() => {
        switch (sectionSelected) {
            case 'Mi expediente portable':
                return 1
            case 'Historia clínica':
                return 2
            case 'Mi agenda':
                return 3
            default:
                return true
        }
    })()
    return (
        <Frame>
            <Wrapper>
                <TextContainer
                    borderRadius="10px 0px 0px 10px"
                    bgcolor={isSectionSelected === 1 ? "#104C7C" : "#FFFFFF"}
                    onClick={() => onSetSectionSelected('Mi expediente portable')}
                >
                    <Text
                        color={isSectionSelected === 1 ? "#FFFFFF" : "#000000"}
                    >
                        Mi expediente portable
                    </Text>
                </TextContainer>
                <TextContainer
                    borderLeft="9px solid #D5D5D5"
                    borderRight="9px solid #D5D5D5"
                    bgcolor={isSectionSelected === 2 ? "#104C7C" : "#FFFFFF"}
                    onClick={() => onSetSectionSelected('Historia clínica')}
                >
                    <Text
                        color={isSectionSelected === 2 ? "#FFFFFF" : "#000000"}
                    >
                        Historia clínica
                    </Text>
                </TextContainer>
                <TextContainer
                    borderRadius="0px 10px 10px 0px"
                    bgcolor={isSectionSelected === 3 ? "#104C7C" : "#FFFFFF"}
                    onClick={() => onSetSectionSelected('Mi agenda')}
                >
                    <Text
                        color={isSectionSelected === 3 ? "#FFFFFF" : "#000000"}
                    >
                        Mi agenda
                    </Text>
                </TextContainer>
            </Wrapper>
        </Frame>
    )
}

export default NavTabMenuClinicRecord