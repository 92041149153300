import React from 'react'
import { Frame, ContentWrapper, Logo, ItemMenu, BurgerMenu, MenuContainer, Header } from './style'
import { useNavigate } from 'react-router-dom';
import burgerMenu from '../../../assets/burgerMenu.svg'
import useAuthUserLogout from '../../../hooks/useAuthUserLogout';

const MobilNavBar = ({ logo, onClose }) => {
    const navigate = useNavigate();
    const logout = useAuthUserLogout()

    const handleNavigate = (path) => {
        navigate(path)
    }


    return (
        <Frame>
            <Header>
                <Logo src={logo} alt='logo' onClick={() => handleNavigate('/lp')} />
                <MenuContainer onClick={onClose}>
                    <BurgerMenu src={burgerMenu} alt='' />
                </MenuContainer>
            </Header>
            <ContentWrapper>
                <ItemMenu>
                    Visión General
                </ItemMenu>
                <ItemMenu
                    onClick={() => handleNavigate('/historial-servicios')}
                >
                    Historial Servicios
                </ItemMenu>
                <ItemMenu
                    onClick={() => handleNavigate('/mi-perfil')}
                >
                    Mi Perfil
                </ItemMenu>
                <ItemMenu
                    onClick={logout}>Cerrar sesión
                </ItemMenu>
            </ContentWrapper>
        </Frame>
    )
}

export default MobilNavBar