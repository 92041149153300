import styled from "styled-components";
import { styled as muiStyled, Box } from "@mui/material";

export const DescriptionFrame = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`


export const CardsContainer = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '37px',
    [theme.breakpoints.down('lg')]: {
        gap: '20px',
    },
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    }
}))

