import useGetQuestionsAndAnswers from "./useGetQuestionsAndAnswers";

const useFormViewGetArray = () => {
    const questionsAndAnswers = useGetQuestionsAndAnswers();
    if (!questionsAndAnswers) return [];
    const objectsWithKey0 = questionsAndAnswers.filter(obj => "0" in obj);
    const heredofamiliaresRespuestasPositivos = objectsWithKey0.filter(obj => obj.category === 'Heredofamiliares' && obj['0']['answer'] === '1');
    const heredofamiliaresRespuestasArray = heredofamiliaresRespuestasPositivos.map(obj => obj.text);

    const heredofamiliaresRespuestas = [{
        pregunta: '¿En su familia existen casos de?',
        respuestas: heredofamiliaresRespuestasArray
    }]

    const vidaPersonalesRespuestasPositivos = objectsWithKey0.filter(obj => obj.category === 'vidaPersonales' && obj['0']['answer'] === '1');
    const vidaPersonalesRespuestasArray = vidaPersonalesRespuestasPositivos.map(obj => obj.text);

    const vidaPersonalesRespuestas = [{
        pregunta: '¿Tiene usted los siguientes hábitos?',
        respuestas: vidaPersonalesRespuestasArray
    }]

    const remainQuestions = objectsWithKey0.filter(obj => obj.category !== 'Heredofamiliares' && obj.category !== 'vidaPersonales');

    const remainQuestionsWithAnswers = remainQuestions.map(element => [{
        pregunta: element.text,
        respuestas: element['0']['answer'] === '1' ? ['Sí'] : ['No']
    }]).flat();

    return [
        ...heredofamiliaresRespuestas,
        ...vidaPersonalesRespuestas,
        ...remainQuestionsWithAnswers,
    ]
};

export default useFormViewGetArray;