import React, { useState, useEffect } from "react";
import {
  Frame,
  ContentWrapper,
  Logo,
  ItemMenu,
  BurgerMenu,
  MenuContainer,
  MobilFrame,
} from "./style";

import burgerMenu from "../../assets/burgerMenu.svg";
import MobilNavBar from "./MobilNavBar";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from '@mui/material';

const NavBar = ({ logo, bgColor }) => {
  const navigate = useNavigate();
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [isOpen, setIsOpen] = useState(false);

  const handleNavigate = (path) => {
    navigate(path);
  };

  useEffect(() => {
    setIsOpen(false)
  }, [isMobile])

  useEffect(() => {
    const handleScroll = () => {
      if (isOpen && isMobile) {
        // Deshabilitar scroll
        document.documentElement.style.overflow = 'hidden';
        document.body.style.overflow = 'hidden';
        document.documentElement.style.height = '100%';
        document.body.style.height = '100%';
      } else {
        // Habilitar scroll
        document.documentElement.style.overflow = 'auto';
        document.body.style.overflow = 'auto';
        document.documentElement.style.height = 'auto';
        document.body.style.height = 'auto';
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
      document.documentElement.style.height = 'auto';
      document.body.style.height = 'auto';
    };
  }, [isOpen, isMobile]);

  return (
    <>
      <Frame bgcolor={bgColor} display="flex">
        <Logo src={logo} alt="logo" display={!isOpen && isMobile ? 'flex' : 'none'} onClick={() => handleNavigate("/lp")} />
        <ContentWrapper>
          <ItemMenu>Visión General</ItemMenu>
          <ItemMenu onClick={() => handleNavigate("/historial-servicios")}>
            Historial Servicios
          </ItemMenu>
          <ItemMenu onClick={() => handleNavigate("/mi-perfil")}>
            Mi Perfil
          </ItemMenu>
        </ContentWrapper>
        <MenuContainer onClick={() => setIsOpen(true)} display={!isOpen && isMobile ? 'flex' : 'none'}>
          <BurgerMenu src={burgerMenu} alt="" />
        </MenuContainer>
      </Frame>
      <MobilFrame
        sx={{
          display: isMobile ? "flex" : "none",
          transform: isOpen ? "translateX(0)" : "translateX(-100%)",
          transition: isOpen ? "transform 0.2s ease-in-out" : "transform 0.2s ease-in-out",
        }}
      >
        <MobilNavBar logo={logo} onClose={() => setIsOpen(false)} isOpen={isOpen} />
      </MobilFrame>
    </>
  );
};

export default NavBar;
