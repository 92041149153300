import React from "react";
import close from "../../assets/Close.svg";
import {
    Frame,
    Wrapper,
    ImageCard,
    DescriptionFrame,
    TitleContainer,
    TitleCard,
    CloseImg,
    DescriptionContainer,
    InfoContainer,
    ImgaeContainer,
} from "./style";

const BasePopUpWraperCard = ({
    image,
    positionImageStart,
    children,
    onClose,
    title,
    renderRightSide,
    boxShadow,
}) => {
    return (
        <Wrapper>
            <Frame
                alignItems={positionImageStart ? 'none' : 'center'}
                boxShadow={boxShadow ? boxShadow : '0px 36.72655px 133.37538px 0px rgba(224, 223, 233, 0.38)'}
            >
                <ImgaeContainer>
                    <ImageCard src={image} alt={image} />
                </ImgaeContainer>
                <DescriptionFrame>
                    <TitleContainer>
                        <TitleCard>{title}</TitleCard>
                        <CloseImg src={close} alt="close" onClick={onClose} />
                    </TitleContainer>
                    <DescriptionContainer>
                        {children}
                        <InfoContainer>{renderRightSide}</InfoContainer>
                    </DescriptionContainer>
                </DescriptionFrame>
            </Frame>
        </Wrapper>
    );
};

export default BasePopUpWraperCard;
