import React from "react";
import {
  MainContainer,
  SubContainer,
  Title,
  SubContainerM,
  ContainerM,
  ContWelcome,
  ContWelcomeMob,
} from "./Hero.Styled";

import DisplayAuth from "../../DisplayAuth/DisplayAuth";

const CoverHeader = ({ title }) => {
  return (
    <>
      <ContWelcome>
        <DisplayAuth />
      </ContWelcome>
      <MainContainer>
        <SubContainer>
          <Title>{title}</Title>
        </SubContainer>
      </MainContainer>
      <ContainerM>
        <SubContainerM>
          <ContWelcomeMob>
            <DisplayAuth />
          </ContWelcomeMob>
          <Title>{title}</Title>
        </SubContainerM>
      </ContainerM>
    </>
  );
};

export default CoverHeader;
