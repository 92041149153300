import React from 'react'
import { Wrapper } from './styles'
import QuestionOrAnswer from '../QuestionOrAnswer/QuestionOrAnswer'
import formRegisterAnswer from '../../shared/formRegisterAnswer'

const CardQuestions = ({ answers, width, idPacient, saving, onSetSaving }) => {

    const saveResponse = (answer, userResponse) => {
        formRegisterAnswer(answer, userResponse, idPacient, onSetSaving)
    }

    return (
        <Wrapper width={{ lg: width || "490px", md: width || '350px' }}>
            {answers.map((answer) => (
                <QuestionOrAnswer
                    key={answer.id}
                    answer={answer}
                    saving={saving}
                    onSaveResponse={saveResponse}
                    onSetSaving={onSetSaving}
                />
            ))}
        </Wrapper>
    )
}

export default CardQuestions