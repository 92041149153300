import React, { useState } from "react";
import { useMediaQuery, useTheme } from '@mui/material';
import {
  MainContainerDeteccion,
  InnerContainer,
  TeleconsultaButton,
  Button,
  ButtonContainer,
} from "./CardPasoServicio.style";
import AddEmailModal from "../../../Modals/AddEmailModal";
import requester from "../../../../Apis/requester";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux/features/user";

export default function CardPasosTeleconsulta({
  ctaText,
}) {
  const user = useSelector(selectUser)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [email, setEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')
  const [openEmailModal, setOpenEmailModal] = useState(false)

  const getuser = async () => {
   /*  if (!user.email) {
      setOpenEmailModal(true)
      return
    }

    try {
      const response = await requester({
        url: '/api/auth/generate-single-password',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: { email: user.email },
      })
      const dataToken = await response;
      const token = dataToken.token;
      window.location.replace(`${process.env.REACT_APP_CLIENT_URL}/login-token/${token}`)
    } catch (error) {
      console.error(error);
    } */
    const URL = 'https://demoqa.hexalud.com';

    // Usa window.location para redirigir
    window.location.href = URL;
  };

  return (
    <MainContainerDeteccion>
      <InnerContainer>
       {/*  <TeleconsultaButton /> */}
        <ButtonContainer>
          <Button
            bgColor={isMobile ? '#104C7C' : 'white'}
            color={isMobile ? 'white' : '#104C7C'}
            onClick={getuser}
          >
            {ctaText}
          </Button>
        </ButtonContainer>
      </InnerContainer>
      <AddEmailModal
        open={openEmailModal}
        email={email}
        confirmEmail={confirmEmail}
        onChangeEmail={(e) => setEmail(e.target.value)}
        onChangeConfirmEmail={(e) => setConfirmEmail(e.target.value)}
        handleClose={() => setOpenEmailModal(false)}
        getuser={getuser}
        idUser={user.idUser}
      />
    </MainContainerDeteccion>
  );
}
