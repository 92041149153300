import React from 'react'
import styled from 'styled-components'
import useAuthUserLogout from '../hooks/useAuthUserLogout';

export const LogOutBtn = styled.button`
    width: 168px;
    padding: 10px;;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 40px;
    border-color: #104C7C;
    border-style: solid;
    height: 40px;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #104C7C;
    background-color: #FFFFFF;
    cursor: pointer;
    @media (max-width: 1210px) {
        justify-content: center;
        font-size: 22px;
    }
    
`

const LogOutButton = () => {
    const logout = useAuthUserLogout()
    return (
        <LogOutBtn
            onClick={() => logout()}
        >
            Cerrar sesión
        </LogOutBtn>
    )
}

export default LogOutButton