import styled from "styled-components";
import { Box, Typography, styled as muiStyled } from "@mui/material";

export const Frame = muiStyled(Box)(({ theme }) => ({
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: "50px",
    background: "#FFFFFF",
    padding: "0 94px",
    boxSizing: "border-box",
    marginTop: "32px",
    [theme.breakpoints.down("lg")]: {
        padding: "0 50px",
        marginTop: "16px",
    },
    [theme.breakpoints.down("md")]: {
        gap: "20px",
        justifyContent: "space-between",
        height: "63px",
        padding: "0 24px",
    }
}));

export const ContentWrapper = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    flex: '1 0 0',
    width: '100%',
    gap: '59px',
    [theme.breakpoints.down('lg')]: {
        gap: '30px',
    },
    [theme.breakpoints.down('md')]: {
        display: 'none',
    }
}))

export const Logo = styled.img`
    width: 339px;
    height: 63px;
    min-width: 200px;
    cursor: pointer;
    @media (max-width: 1210px) {
        width:177px
    }
`

export const ItemMenu = muiStyled(Typography)(({ theme }) => ({
    color: '#6C81A0',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    cursor: 'pointer',
    margin: '0',
    padding: '0',
    width: 'auto',
    textWrap: 'nowrap',
    [theme.breakpoints.down('lg')]: {
        // width: '100%',
    }
}))

export const MenuContainer = muiStyled(Box)(({ theme }) => ({
    width: '24px',
    cursor: 'pointer',
}))

export const BurgerMenu = styled.img`
    width: 24px;
`

export const MobilFrame = muiStyled(Box)(({ theme }) => ({
    background: "#FFFFFF",
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '3',
    overflow: 'hidden',
    top: '0',
    padding: '5px 0 0 0',
}))