import React, { useState } from 'react'
import PrincipalServices from '../PrincipalServices/PrincipalServices'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Collapse from '@mui/material/Collapse';
import { useMediaQuery, useTheme } from '@mui/material';
import { Card, CardContent, CardHeader, ServiceFrame, ServiceFrameText, IconContainer } from './style'

const CardPrincipalServices = ({
    pricipalServices,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const [expand, setExpand] = useState(false)

    return (
        <Card className={expand ? "expand" : ''}>
            <CardContent>
                <CardHeader>
                    <ServiceFrameText>
                        Servicios Disponibles
                    </ServiceFrameText>
                    <IconContainer onClick={() => setExpand((prev) => !prev)}>
                        {expand ? (
                            <ExpandLessIcon htmlColor='#00c46b' />
                        ) : (
                            <ExpandMoreIcon htmlColor='#00C46B' />
                        )}
                    </IconContainer>
                </CardHeader>
                <Collapse in={isMobile ? expand : true} timeout="auto" unmountOnExit>
                    <ServiceFrame>
                        {pricipalServices.map((card) => (
                            <PrincipalServices key={card.nombreCardPopUp} image={card.icono} />
                        ))}
                    </ServiceFrame>
                </Collapse>
            </CardContent>
        </Card>
    )
}

export default CardPrincipalServices