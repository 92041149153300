import React, { useState } from "react";
import CardBaseForm from "../../components/CardBaseForm/CardBaseForm";
import PageBase from "../../components/PageBase";
import BackButtonPage from "../../components/BackButtonPage";
import { TitleContainer, Title, Content } from "./style";
import CardQuestions from "../../components/CardQuestions/CardQuestions";
import CarrouselQuestions from "../../components/CarrouselQuestions/CarrouselQuestions";
import useGetQuestionsAndAnswers from "../../hooks/useGetQuestionsAndAnswers";
import splitInTwoArrays from "../../helpers/splitInTwoArrays";
import Spinner from "../../components/Spinner";
import { useUser } from "../../shared/useUser";

const RiskDetectionForm = () => {
    const [saving, setSaving] = useState(false)
    const idPacient = useUser().idPacient
    const questionsAndAnswers = useGetQuestionsAndAnswers();
    if (!questionsAndAnswers) return <Spinner />;
    // console.log('questionsAndAnswers')
    const heredofamiliaresQuestions = questionsAndAnswers.filter(
        (question) => question.category === "Heredofamiliares"
    );
    const { firstHalf: heredofamiliaresFirst, secondHalf: heredofamiliaresSecond } = splitInTwoArrays(heredofamiliaresQuestions);

    const habitosVidaQuestions = questionsAndAnswers.filter((questons) => questons.category === "vidaPersonales")
    const { firstHalf: habitosVidaFirst, secondHalf: habitosVidaSecond } = splitInTwoArrays(habitosVidaQuestions);

    const habitosSaludQuestions = questionsAndAnswers.filter((questons) => questons.category === "habitosSalud")

    const antecedentesPatologicosQuestions = questionsAndAnswers.filter((questons) => questons.category === "patologicoPersonales")

    if (
        !heredofamiliaresFirst.length === 0 &&
        !heredofamiliaresSecond.length === 0
    )
        return <Spinner />;

    return (
        <PageBase>
            <Content>
                <TitleContainer>
                    <BackButtonPage path="/lp" />
                    <Title>Detección Oportuna de Riesgos</Title>
                </TitleContainer>
                <CardBaseForm
                    title="Antecedentes Heredofamiliares"
                    subTitle="¿En su familia alguno de estos casos? Marque las opciones correspondientes"
                >
                    <CardQuestions
                        idPacient={idPacient}
                        width="50%"
                        answers={heredofamiliaresFirst}
                        saving={saving}
                        onSetSaving={setSaving}
                    />
                    <CardQuestions
                        idPacient={idPacient}
                        width="50%"
                        answers={heredofamiliaresSecond}
                        saving={saving}
                        onSetSaving={setSaving}
                    />
                </CardBaseForm>
                <CardBaseForm
                    title="Hábitos de Vida Personales "
                    subTitle="¿Tiene usted los siguientes hábitos?"
                >
                    <CardQuestions
                        width="50%"
                        idPacient={idPacient}
                        answers={habitosVidaFirst}
                        saving={saving}
                        onSetSaving={setSaving}
                    />
                    <CardQuestions
                        width="50%"
                        idPacient={idPacient}
                        answers={habitosVidaSecond}
                        saving={saving}
                        onSetSaving={setSaving}
                    />
                </CardBaseForm>
                <CardBaseForm title="Hábitos de Salud" subTitle="">
                    <CardQuestions
                        width="75%"
                        idPacient={idPacient}
                        answers={habitosSaludQuestions}
                        saving={saving}
                        onSetSaving={setSaving}
                    />
                </CardBaseForm>
                <CardBaseForm title="Antecedentes patológicos personales">
                    <CarrouselQuestions
                        idPacient={idPacient}
                        questions={antecedentesPatologicosQuestions}
                        saving={saving}
                        onSetSaving={setSaving}
                    />
                </CardBaseForm>
            </Content>
        </PageBase>
    );
};

export default RiskDetectionForm;
