import React, { useState } from "react";
import emailicon from "../../assets/email.svg";
import BackButtonPage from "../../components/BackButtonPage";
import AuthenticationBase from "../../components/AuthenticationBase/AuthenticationBase";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from "@mui/material";
import {
  InnerContainer,
  Button,
  InputField,
  MainTitle,
  Subtitle,
  ContainerInput,
  InputIcon,
  TitleContainer,
  BackButtonContainer,
} from "./style";

const PasswordRecovery = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('')

  function validateEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(String(email).toLowerCase());
  }

  return (
    <AuthenticationBase>
      <InnerContainer>
        <TitleContainer>
          <BackButtonContainer>
            <BackButtonPage path="/" />
          </BackButtonContainer>
          <MainTitle>Recuperar contraseña</MainTitle>
          <Subtitle>Por favor introduzca su email:</Subtitle>
        </TitleContainer>
        <ContainerInput>
          <InputIcon src={emailicon} alt="email" />
          <InputField
            type="text"
            placeholder="Correo electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </ContainerInput>
        <Button
          disabled={!email || !validateEmail(email)}
          onClick={() => navigate('/')}
        >
          Recuperar
        </Button>
      </InnerContainer>
    </AuthenticationBase>
  );
};

export default PasswordRecovery;
