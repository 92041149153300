import React from 'react'
//assets
// import flecha from '../../../../assets/arrowDown.svg'
//assets<
import { CardContainer, ArrowContainer, CardImg, Title } from './Cards.Styled'

export default function CardsHistorial({ titulo, imgCard, onClick, isOpen, }) {
    return (
        <CardContainer className={isOpen ? 'open' : ''} onClick={onClick}>
            <CardImg src={imgCard} />
            <Title>{titulo} </Title>
            {/* <ArrowContainer>
                <img alt='' style={{ cursor: 'pointer' }} src={flecha} />
            </ArrowContainer> */}
        </CardContainer>
    )
}
