import React from 'react'
import AuthUser from '../../assets/AuthUser.svg'
import { useUser } from '../../shared/useUser'
import { Frame, AuthText, AuthImg } from './style'
import LogOutButton from '../LogOutButton'
import { useMediaQuery, useTheme } from '@mui/material';

const DisplayAuth = () => {
  const userName = useUser().name
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Frame>
      <AuthText>
        Bienvenido(a) {userName}
      </AuthText>
      <AuthImg src={AuthUser} alt='auth-user' />
      {!isMobile && <LogOutButton />}
    </Frame>
  )
}

export default DisplayAuth