import styled from "styled-components";
import { styled as muiStyled, Box, Typography } from "@mui/material";

export const TitleCard = muiStyled(Typography)`
    text-align: center;
    font-family: Josefin Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    width: 239px;

`;

export const SubtitleCard = muiStyled(Typography)`
    text-align: center;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
`;

export const Button = styled(Box)`
  display: flex;
  width: 188px;
  padding: 16px 24px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 40px;
  border-color: #00c46b;
  border-style: solid;
  border-width: 1px;
  color: #00c46b;
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  box-sizing: border-box;
`;

export const ImageCard = styled.img`
  padding: 12.38px 5.371px 11.89px 4.559px;
  height: 60px;
  box-sizing: border-box;
  filter: ${props => props.color};
  `;

export const MainContaier = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    width: '298px',
    maxHeight: '310px',
    minHeight: '300px',
    padding: '32px 20px 30px 20px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    borderRadius: '10px',
    boxShadow: '0px 19px 69px 0px rgba(224, 223, 233, 0.38)',
    cursor: 'pointer',
    boxSizing: 'border-box',
    [theme.breakpoints.down('lg')]: {
        maxWidth: 'unset',
        minWidth: 'unset',
        width: '254px',
    },
    [theme.breakpoints.down('md')]: {
        maxWidth: 'unset',
        minWidth: 'unset',
        width: '100%',
    },
}))

export const Header = muiStyled(Box)`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
