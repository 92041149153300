import React from 'react';
import { Grid, Typography } from '@mui/material'
import styled from 'styled-components';
import Spinner from '../Spinner';
import useFormViewGetArray from '../../hooks/useFormViewGetArray';

const QuestionContainer = styled(Grid)`
    margin-bottom: 24px;
    width: 100%;

`;

const AnswerContainer = styled(Grid)`
    display: flex;
    align-items: center;
    /* margin-bottom: 5px; */
    margin-bottom: 24px;

`;

export const Dot = styled('span')`
    font-size: 40px;
    margin-top: -8px;
`;

export const Question = styled(Typography)`
    color: #000;
font-family: Lato;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 24px;

`

export const Answer = styled(Typography)`
    color: #000;
font-family: Lato;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 24px;

`

const FormView = () => {
    const formResult = useFormViewGetArray() || []

    if (formResult.length === 0) return <Spinner />

    return (
        <Grid container width="100%" spacing={3}>
            {formResult.map((pregunta, index) => (
                <QuestionContainer item xs={6} key={index}> {/* Change xs from 12 to 6 */}
                    <Question>{pregunta.pregunta}</Question>
                    <Grid container spacing={3} direction={{ xs: 'column', sm: 'column', md: 'row' }} > {/* Change direction from row to columns */}
                        {pregunta.respuestas.map((respuesta, index) => (
                            <AnswerContainer item xs={6} key={index}>
                                <Dot>&#8226;</Dot>
                                <Typography>{respuesta}</Typography>
                            </AnswerContainer>
                        ))}
                    </Grid>
                </QuestionContainer>
            ))}
        </Grid>
    );
};

export default FormView;
