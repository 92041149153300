import { styled as muiStyled } from "@mui/material";
import styled from "styled-components";
import Box from '@mui/material/Box'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const InnerContainer = muiStyled(Box)(({ theme }) => ({
  width: 461,
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
  padding: 10,
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    padding: '32px 24px 32px 24px',
    width: '100%',
  }
}))

export const MainTitle = styled.h2`
  color: #07122f;
  font-family: Josefin Sans;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  @media (max-width: 599.95px) {
    text-align: center;
    font-size: 30px;
}
`;

export const ContainerInput = muiStyled(Box)`
  width: calc(100% - 12px);
  height: 55px;
  border-radius: 8px;
  border: 0.7px solid #d0d0d0;
  display: flex;
  padding: 6px 6px 6px 18px;
  background-color: #ffffff;
  box-sizing: border-box;
  align-items: center;
  gap: 10px;
  &:focus-within {
    box-shadow: 0 0 0 2px rgba(88, 192, 145, 0.25);
  }
`;

export const InputIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const Subtitle = styled.p`
  color: #36455a;

  /* Tittle */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  @media (max-width: 599.95px) {
    text-align: center;
}
`;

export const InputField = styled.input`
  width: 100%;
  height: 100%;
  color: #abb3bb;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-style: none;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const Button = styled.button`
  display: flex;
  width: calc(100% - 12px);
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;
  border-radius: 40px;
  border-color: #00c46b;
  border-style: solid;
  background: var(--Primary, #00c46b);
  box-shadow: 2px 4px 4px 0px rgba(43, 43, 43, 0.1);
  color: var(--Background, #f6f6f6);
  text-align: center;
  cursor: pointer;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  &:disabled {
    background: #e0e0e0;
    border-color: #e0e0e0;
  }
`;

export const TitleContainer = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
  [theme.breakpoints.down('sm')]: {
    gap: 8,
  }
}))

export const BackButtonContainer = muiStyled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 50,
  left: 40,

  [theme.breakpoints.down('md')]: {
    top: 45,
    right: 40,
    left: 'unset',
  }
}))
