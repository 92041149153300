import React, { useState } from 'react'
import { Box, styled } from '@mui/material'
import warning from '../../assets/warning.svg'
import info from '../../assets/info.svg'
import CardPasosDeteccion from '../ConoceBeneficios/CardServicios/CardPasoServicios/CardPasosDeteccion'
import BasePopUpWraperCard from '../BasePopUpWraperCard'
import {
  ExtraInfoCardPopUp,
  InfoContainer,
  TitleInfo,
  InfoText,
} from '../ConoceBeneficios/styles'

export const DescriptionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '37px',
  borderRadius: '10px',
  background: '#FFFFFF',
  boxShadow: '0px 19px 69px 0px rgba(224, 223, 233, 0.38)',
  [theme.breakpoints.down('lg')]: {
    gap: '20px',
  }
}))

export default function CardServiciosDeteccion({ onClose }) {
  const [expand, setExpand] = useState(false)

  return (
    <BasePopUpWraperCard
      image={warning}
      positionImageStart={expand}
      title={'Detección oportuna de riesgos'}
      renderRightSide={
        <ExtraInfoCardPopUp>
          <InfoContainer>
            <img src={info} alt='' />
            <TitleInfo>¿Quiénes pueden solicitarlo? </TitleInfo>
          </InfoContainer>
          <InfoText>El titular de la cuenta y sus beneficiarios.</InfoText>
          <InfoContainer>
            <img src={info} alt='' />
            <TitleInfo>Horarios</TitleInfo>
          </InfoContainer>
          <InfoText>Lunes a viernes de 08:00 a 20:00 horas. </InfoText>
          <InfoText>Sábado de 08:00 a 13:00 horas. </InfoText>
        </ExtraInfoCardPopUp>
      }
      onClose={onClose}
    >
      <DescriptionContainer>
        <CardPasosDeteccion
          expand={expand}
          onSetExpand={setExpand}
        />
      </DescriptionContainer>
    </BasePopUpWraperCard>
  )
}
