import React, { useState } from 'react';
import {
  MainContaier,
  Button,
  SubtitleCard,
  TitleCard,
  ImageCard,
  Header,
} from "./CardBeneficios.styles";

export default function CardBeneficios({
  image,
  title,
  subtitle,
  onClick,
  textButton,
  cardSelected,
  colorTheme,
}) {
  const [mouseOver, setMouseOver] = useState(false)
  const isChangeColor = cardSelected || mouseOver

  return (
    <MainContaier
      bgcolor={isChangeColor ? "#104C7C" : "#ffffff"}
      justifyContent={isChangeColor ? "center" : "flex-start"}
      onMouseOver={() => setMouseOver(true)}
      onMouseOut={() => setMouseOver(false)}
      onClick={() => {
        onClick();
      }}
    >
      <Header>
        <ImageCard
          src={image}
          alt={title}
          color={isChangeColor ? 'hue-rotate(-58deg) brightness(100000) saturate(0)' : ''}
        />
        <TitleCard
          variant="h3"
          color={isChangeColor ? '#ffffff' : '#444'}
        >
          {title}
        </TitleCard>
      </Header>
      <SubtitleCard
        color={isChangeColor ? '#F6F6F6' : '#8D8E8D'}
      >
        {subtitle}
      </SubtitleCard>
      {!isChangeColor && (
        <Button
          color={isChangeColor ? '#F6F6F6' : colorTheme}
          borderColor={isChangeColor ? '#F6F6F6' : colorTheme}
        >
          {textButton}
        </Button>
      )}
    </MainContaier>
  );
}
