// EditarTel.js
import React, { useState, useEffect } from 'react';
import { InputContainer, InputTel, LabelInput, SelectTel, ImgSelect, InputSelect, ButtonSave, ButtonCancel, EditNumCont, EditNumBtnCont, ButtonSaveEdit, InputSelectEdit } from '../AgregarTel/AgregarTel.styles'
import buildng from '../../../assets/buildngsvg.svg';
import telContact from '../../../assets/telContact.svg';
import requester from '../../../Apis/requester';
import { useDispatch } from 'react-redux';
import { setPhone } from '../../../redux/features/user';

export default function EditarTel({ onEditarTelefono, mostrar, setMostrar, telefonoAEditar, idUser }) {
    const [companiaSeleccionada, setCompaniaSeleccionada] = useState(telefonoAEditar?.compania || '');
    const [telefono, setTelefono] = useState(telefonoAEditar?.telefono || '');
    const [extension, setExtension] = useState(telefonoAEditar?.extension || '');
    const dispatch = useDispatch();

    const handleGuardar = async () => {
        // Validar que el número de teléfono tenga exactamente 10 caracteres
        if (telefono.length === 10) {
            // Llamar a la función onEditarTelefono con los valores actualizados
            onEditarTelefono({
                id: telefonoAEditar.id,
                compania: companiaSeleccionada,
                telefono: telefono,
                extension: extension,
            });

            const body = {
                phone: telefono,
                idUser
            }
            const response = await requester({
                url: '/api/user/updatePhone',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: body,
            })
            const data = await response;
            console.log(data)

            if (data.success)
                dispatch(setPhone(telefono))

            setMostrar(false);
        } else {
            // Mostrar un mensaje de error, lanzar una alerta, etc., según tus necesidades
            alert("El número debe contener 10 caracteres, revise su marcación.");
        }
    };

    useEffect(() => {
        // Update state with telefonoAEditar values when it changes
        setCompaniaSeleccionada(telefonoAEditar?.compania || '');
        setTelefono(telefonoAEditar?.telefono || '');
        setExtension(telefonoAEditar?.extension || '');
    }, [telefonoAEditar]);

    const onlyNumericInput = (value) => {
        // Filtrar caracteres no numéricos usando una expresión regular
        const numericValue = value.replace(/\D/g, '');

        // Limitar la longitud del valor a 10 caracteres
        return numericValue.slice(0, 10);
    };
    return (
        <>
            <EditNumCont>
                {/* <InputContainer>
                    <LabelInput>Compañia</LabelInput>
                    <SelectTel
                        className="company-select"
                        value={companiaSeleccionada}
                        onChange={(e) => setCompaniaSeleccionada(e.target.value)}
                    >
                        <option value="">Elegir</option>
                        <option value="company1">Compañía 1</option>
                        <option value="company2">Compañía 2</option>
                    </SelectTel>
                    <ImgSelect src={buildng} />
                </InputContainer> */}
                <InputContainer>
                    <LabelInput>Teléfono</LabelInput>
                    <InputTel
                        type='tel'
                        placeholder='Ingresar teléfono'
                        value={telefono}
                        onChange={(e) => setTelefono(onlyNumericInput(e.target.value, 10))}
                        maxLength={10}
                    />
                    <InputSelectEdit src={telContact} />
                </InputContainer>

                {/* <InputContainer>
                    <LabelInput>Extensión</LabelInput>
                    <InputTel
                        type='tel'
                        placeholder='Ingresar extensión'
                        value={extension}
                        onChange={(e) => setExtension(onlyNumericInput(e.target.value))}
                        maxLength={4}
                    />
                    <InputSelect src={telContact} />
                </InputContainer> */}
                <EditNumBtnCont>
                    <ButtonCancel onClick={() => setMostrar(prevMostrar => !prevMostrar)}>Cancelar</ButtonCancel>
                    <ButtonSaveEdit onClick={handleGuardar}>Guardar</ButtonSaveEdit>
                </EditNumBtnCont>
            </EditNumCont>

        </>
    );
}
