import React from 'react'
import NavBar from '../NavBar/NavBar'
import Footer from '../Footer/Footer'
import { Box } from '@mui/material'
import useGetLpData from '../../hooks/getLpData'
import Spinner from '../Spinner'

const PageBase = ({ children }) => {
    const lpData = useGetLpData().data;

    if (lpData.length === 0) return <Spinner />
    return (
        <Box>
            <NavBar logo={lpData.logoHeader} />
            <Box>
                {children}
            </Box>
            <Footer data={lpData} />
        </Box>
    )
}

export default PageBase