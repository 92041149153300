import styled from "styled-components";

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    cursor: pointer;
     &.open {
        border-top: 9px solid #86F2B1;
    }
    box-shadow: 0px 19px 69px 0px rgba(224, 223, 233, 0.38);
`
export const CardImg = styled.img`
    width: 60px;
`
export const Title = styled.p`
    color: #444;
    text-align: center;

/* Desktop/H3 */
    font-family: Josefin Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`