import styled from "styled-components";

export const CardsContainer = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    background: var(--default-white, #FFF);
    @media (max-width: 1210px) {
        padding: 0;
        font-size:30px;
        text-align: center;
        flex-direction: column;
        gap: 16px;
        width: 90%;
        margin: 0 auto;
    }
    `

export const MainContainer = styled.div`
   width: 90%;
   margin:0 auto;
   display: flex;
   flex-direction: column;
   background-color: #FFF;
       @media (max-width: 1210px) {
        display: none;
    }
  
`
export const MainContainerMob = styled.div`
    display: none;
       @media (max-width: 1210px) {
        width: 90%;
        margin:0 auto;
        display: flex;
        flex-direction: column;
        gap:32px;
        background-color: #FFF;
    }
  
`

export const LlamadasSubt = styled.h2`
    color: #444;
    text-align: center;
    /* Desktop/H2 */
    font-family: Josefin Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`
export const LlamadasSubtMob = styled.h2`
    color: #444;
    text-align: center;
    /* Desktop/H2 */
    font-family: Josefin Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`

export const CardsContainerHorarios = styled.div`
padding:32px;
background-color: #F6F6F6;
border-radius: 10px;
background: #FFF;
box-shadow: 0px 36.727px 133.375px 0px rgba(224, 223, 233, 0.38);
    @media (max-width: 1210px) {
        padding: 0;
        width: 90%;
        margin: 0 auto;
    }
`