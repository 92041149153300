import React from 'react'
import CardPasoServicios from '../ConoceBeneficios/CardServicios/CardPasoServicios/CardPasoServicios'
import BasePopUpWraperCard from '../BasePopUpWraperCard'
import {
  CardsContainer,
} from './style'

const CardDescriptionBenefitWithSteps = ({
  image,
  title,
  textStep1,
  textStep2,
  textStep3,
  secondaryTextStep3,
  footerTextStep1,
  footerTextStep2,
  footerTextStep3,
  renderExtraInfo,
  onClose,
}) => {
  return (
    <BasePopUpWraperCard
      image={image}
      title={title}
      renderRightSide={renderExtraInfo}
      onClose={onClose}
    >
      <CardsContainer>
        <CardPasoServicios
          step='Paso 1'
          description={textStep1}
          footer={footerTextStep1}
        />
        <CardPasoServicios
          step='Paso 2'
          description={textStep2}
          footer={footerTextStep2}
        />
        <CardPasoServicios
          step='Paso 3'
          description={textStep3}
          sedcondaDescription={secondaryTextStep3}
          footer={footerTextStep3}
        />
      </CardsContainer>
    </BasePopUpWraperCard>
  )
}

export default CardDescriptionBenefitWithSteps