import styled from "styled-components";

export const CardsContainer = styled.div`
    width: 90%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    justify-content: center;
    margin: 16px auto;
    gap: 16px;
    padding: 16px 32px;
`
export const BackgroundCont = styled.div`
    display: flex;
    width: 100%;
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 10px;
    background: #F6F6F6;
    box-shadow: 0px 36.727px 133.375px 0px rgba(224, 223, 233, 0.38);
`