import { Typography, styled as muiStyled, Box } from "@mui/material";

export const Content = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    margin: "64px 32px 32px 32px",
    [theme.breakpoints.down('md')]: {
        margin: "32px 8px",
    },
}));

export const TitleContainer = muiStyled(Box)(({ theme }) => ({
    padding: '0 60px',
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
        padding: '0px 10px',
        gap: '20px',

    },
}));

export const Title = muiStyled(Typography)(({ theme }) => ({
    color: '#00C46B',
    fontFamily: 'Josefin Sans',
    fontSize: '66px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    [theme.breakpoints.down('md')]: {
        fontSize: '32px',
    },
}));