import React from "react";
import instagram from '../../assets/Instagram.svg'
import {
    MainContainer,
    BoldText,
    CircleContainer,
    FooterContainer,
    NormalText,
    TextMainContainer,
    LogoImg,
    SocialImg,
    SocialContainer,
    LogoContainer,
} from "./Footer.styles";

import Logo from '../../assets/hexaludLogo.svg'

const Footer = ({ data }) => {
    return (
        <MainContainer>
            <FooterContainer>
                <TextMainContainer>
                    <div>
                        <BoldText>Clientes</BoldText>
                        <NormalText>Login</NormalText>
                        <NormalText>Aviso de privacidad</NormalText>
                    </div>

                    <div>
                        <BoldText>Contáctanos</BoldText>
                        <NormalText>{data?.telefonoFooter}</NormalText>
                        <NormalText>{data?.emailFooter}</NormalText>
                    </div>
                </TextMainContainer>

                <LogoContainer>
                    <SocialContainer>
                        {data?.socials?.map((social, index) => (
                            <CircleContainer key={index}>
                                <a href={social.urlDestino}>
                                    <SocialImg src={social?.urlLogo} alt="" />
                                </a>
                            </CircleContainer>
                        ))}
                        <CircleContainer>
                            <a href='https://www.instagram.com/'>
                                <SocialImg src={instagram} alt="" />
                            </a>
                        </CircleContainer>
                    </SocialContainer>
                </LogoContainer>
            </FooterContainer>
        </MainContainer>
    );
}

export default Footer
