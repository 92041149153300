import styled from "styled-components";
import { styled as muiStyled, Box } from "@mui/material";

export const MainContainer = muiStyled(Box)(({ theme }) => ({
    padding: '80px 99px 100px 99px',
    background: '#104C7C',
    [theme.breakpoints.down('md')]: {
        padding: '40px 24px',
    }
}))

export const FooterContainer = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '32px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: '40px'
    }
}))

export const TextMainContainer = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '32px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
    }
}))

export const LogoImg = styled.img`
    max-width: 288px;
    max-height: 63px;
    margin:0;
`

export const SocialImg = styled.img`
    width: 60px;
`

export const SocialContainer = styled.div`
    display: flex;
    gap:16px;
    align-items: center;
    margin:0;
    justify-content: center;
`

export const BoldText = styled.p`
    color: var(--Body, #ffffff);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`

export const NormalText = styled.p`
    color: var(--Body, #ffffff);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

export const CircleContainer = styled.div`
    display: flex;
    align-items: center;
    gap:16px;
`

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
`
