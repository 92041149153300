import { Typography, styled as muiStyled } from "@mui/material";
import styled from "styled-components";
import Box from '@mui/material/Box'

export const MainContainer = muiStyled(Box)`
  width: 100%;
  display: flex;
  height: 100vh;
`;

export const LeftSide = muiStyled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '50%',
  boxShadow: '4px 0px 60px 0px rgba(0, 45, 137, 0.02)',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    justifyContent: 'center',
    marginTop: '1%',
    zIndex: '1',
  }
}))

export const RightSide = muiStyled(Box)(({ theme }) => ({
  background: '#104C7C',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '14px',
  alignItems: 'center',
  boxShadow: '4px 0px 60px 0px rgba(0, 45, 137, 0.02)',
  width: '50%',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  }

}))

export const InnerLeftSideInner = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '24px',

  [theme.breakpoints.down('sm')]: {
    gap: '0px',
    height: '220px',
  }
}))

export const InnerLeft = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginTop: '20px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '25px',
    gap: '10px',
  }
}))

export const TitleLeft = muiStyled(Typography)(({ theme }) => ({
  color: '#fff',
  margin: 0,
  /* H1 */
  fontFamily: 'Josefin Sans',
  fontSize: '44px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: 'normal',

  [theme.breakpoints.down('sm')]: {
    fontSize: '40px',
  }
}))

export const SubtitleLeft = styled.p`
  color: #fff;
  margin: 0;
  /* Tittle */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 599.95px) {
    display: none;
}
`;

export const BackgroundContainer = muiStyled(Box)(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    height: 'inherit',
    width: 'inherit',
    top: '-100px',
  }
}))

export const BackgroundImg = styled.img`
  width: inherit;
  height: 99.5%;
`

export const RightSideContainer = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  padding: '40px 24px 32px 112px',
  boxSizing: 'border-box',
  flexDirection: 'column',
  alignItems: 'start',
  gap: '24px',
  // marginTop: '32%',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    alignItems: 'start',
    padding: '40px 24px 32px 24px',
    width: '100%',
    marginTop: '0%',
  }
}))

export const IconContainer = muiStyled(Box)(({ theme }) => ({
  height: '48px',
  width: '48px',
}))

export const DividerContainer = muiStyled(Box)(({ theme }) => ({
  width: '48px',
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    height: '55%',
  }
}))
