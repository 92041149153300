import React from 'react';
import RootRoutes from './components/RootRoutes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import store from './redux/app/store'
import { Provider } from 'react-redux'

function App() {
  const theme = createTheme();

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RootRoutes />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
