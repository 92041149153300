import styled from "styled-components";
import { styled as muiStyled, Box } from "@mui/material";

export const Frame = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '20px',
    alignSelf: 'stretch',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'row-reverse',
        width: '100%',
    }

}))

export const AuthText = styled.p`
    color: #6C81A0;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding: 0;
`

export const AuthImg = styled.img`
`
