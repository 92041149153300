import { styled as muiStyled, Box, Typography } from "@mui/material";
import styled from "styled-components";

export const Wrapper = muiStyled(Box)(({ theme }) => ({
    height: '100%',
    width: '100%',
    boxShadow: '0px 19px 69px 0px rgba(224, 223, 233, 0.38)',
    backgroundColor: '#fff',
    padding: '32px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    margin: '32px auto',
    borderRadius: '10px',
}));

export const FileFrame = muiStyled(Box)(({ theme }) => ({
    width: '100%',
    backgroundColor: '#fff',
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '80px',
    boxShadow: '0px 19px 69px 0px rgba(224, 223, 233, 0.38)',
    boxSizing: 'border-box',
    borderRadius: '10px',
}));

export const ToolsBar = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

export const RightToolsBar = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
}));

export const SearchFrame = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '10px',
    backgroundColor: '#F3F2F2',
    borderRadius: '16px',
    padding: '11px 14px',
    maxWidth: '400px',
    minWidth: '200px',
    height: 'auto',
}));


export const SearchInput = styled.input`
    border: none;
    background-color: transparent;
    width: 100%;
    height: auto;
    font-size: 18px;
`;

export const ActionsContainer = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
}));

export const ActionButtonContainer = muiStyled(Box)(({ theme }) => ({
    border: '1px solid',
    borderRadius: '16px',
    display: 'flex',
    padding: '6px',
    gap: '10px',
    boxSizing: 'border-box',
    cursor: 'pointer',
}));

export const TextActionButton = muiStyled(Typography)`
    text-align: center;
    font-family: Josefin Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    align-self: center;
`

export const SectionsListWrapper = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
}));

export const FilesListWrapper = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '100px',
}));

export const ButtonContainer = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
}));
export const Button = styled.button`
    border: none;
    padding: 16px 24px;
    color: #fff;
    background-color: #104C7C;
    border-radius: 40px;
    cursor: pointer;
`;