import styled from "styled-components";
import { Box, styled as muiStyled } from "@mui/material";

export const Frame = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '10px',
    alignItems: 'center',
    gap: '15px',
    borderRadius: '8px',
    border: '1px solid #F6F6F6',
    width: '184px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        border: 'none',
        borderBottom: '1px solid #F6F6F6',
    }
}))

export const ImageContainer = styled.div`
    width: 70px;
    height: 70px;
    align-self: center;
    display: flex;
    align-items: center;
`

export const ImageService = styled.img`
    width: 100%;
    height: 70px;
`

export const ContentService = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

export const Title = styled.h3`
   color: #444;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding: 0;
`

export const Description = styled.h3`
   color: #6C81A0;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
`
