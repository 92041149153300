import { Typography, styled as muiStyled, Box } from "@mui/material";

export const Wrapper = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: "space-between"
}));

export const Answertext = muiStyled(Typography)(({ theme }) => ({
    color: '#444444',
    textAlign: 'left',

    fontFamily: "Josefin Sans",
    fontSize: '20px',
    fontWeight: '700',
    alignSelf: 'center',
    maxWidth: '500px',
    [theme.breakpoints.down('md')]: {
        fontSize: '16px',
    },
}));

export const ButtonsContainer = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
}));

export const Button = muiStyled(Box)(({ theme }) => ({
    cursor: 'pointer',
    height: "37px",
    width: "67px",
    padding: "9px 15px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderRadius: '8px',
}));

export const ButtonText = muiStyled(Typography)(({ theme }) => ({
    fontFamily: "Lato",
    fontSize: '16px',
    fontWeight: '700',
}));