import { useEffect, useState } from 'react'
import requester from '../Apis/requester';
import { useSelector } from 'react-redux'
import { selectUser } from '../redux/features/user';

const useGetQuestionsAndAnswers = () => {
    const [questionsAndAnswers, setQuestionsAndAnswers] = useState(null)
    const user = useSelector(selectUser)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const questionsAndAnswers = await requester({
                    url: `api/family-heirs/questions-and-answers/${user.idPacient}`,
                });
                setQuestionsAndAnswers(questionsAndAnswers.listQuestionsAndAnswers);
            } catch (error) {
                console.error('Error fetching evaluation status:', error);
            }
        };

        fetchData();
    }, [user.idPacient])
    return questionsAndAnswers
}

export default useGetQuestionsAndAnswers