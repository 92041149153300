import styled from "styled-components";
import { styled as muiStyled, Box } from "@mui/material";

export const MainContainer = muiStyled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '32px',
    padding: '32px 0px',
    background: 'white',
    [theme.breakpoints.down('md')]: {
        padding: '0',
        background: '#f6f6f6',
    }
}))

export const TitleContainer = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    [theme.breakpoints.down('md')]: {
        gap: '0px',
    }
}))

export const MainTitleTop = styled.h2`
    color: #104C7C;
    font-family: Josefin Sans;
    font-size: 66px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding-right: 270px;
    @media (max-width: 900px) {
        padding: 0;
        font-size:30px;
        text-align: center;
    }
`
export const MainTitleBottom = styled.h2`
    color: var(--Primary, #104C7C);
    font-family: Josefin Sans;
    font-size: 66px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding-left: 270px;
    @media (max-width: 900px) {
        padding: 0;
        font-size:30px;
        text-align: center;
    }
`

export const Content = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
        padding: '0 24px',
        boxSizing: 'border-box',
    }
}))

export const Row = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    marginBottom: '32px',
    width: '100%',
    justifyContent: 'center',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    }
}))

export const CardPopUpWrapper = muiStyled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '1230px',
    height: 'auto',
    boxSizing: 'border-box',
    display: 'flex',
    marginBottom: '32px',
}))

export const CardsContent = muiStyled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {}
}))

export const ExtraInfoCardPopUp = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: 'max-content',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        padding: '0 10px',
    }
}))

export const InfoText = styled.p`
    color: #5F6267;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin:5px;
`
export const BoldInfoText = styled.p`
    color: var(--Body, #5F6267);
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin:0
`

export const TitleInfo = styled.p`
    color: #051201;

    /* Body */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
`

export const InfoContainer = styled.div`
    display: flex;
    gap:8px;
    align-items: center;
`
