import { useSelector } from "react-redux"
import { selectUser } from "../../redux/features/user"
import { Navigate, Outlet } from "react-router-dom"


const AuthGuard = () => {
    const user = useSelector(selectUser)

    if (!user)
        return <Navigate to="/" />

    return <Outlet />
}

export default AuthGuard;