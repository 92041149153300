import React from 'react'
import { Box, styled } from '@mui/material'
import teleconsulta from '../../../assets/teleconsulta.svg'
import info from '../../../assets/info.svg'
import CardPasosTeleconsulta from './CardPasoServicios/CardPasosTeleconsulta'
import BasePopUpWraperCard from '../../BasePopUpWraperCard'
import { useMediaQuery, useTheme } from '@mui/material';
import {
  ExtraInfoCardPopUp,
  InfoContainer,
  TitleInfo,
  InfoText,
} from '../styles'

export const DescriptionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '37px',
  borderRadius: '10px',
  background: '#FFFFFF',
  boxShadow: '0px 19px 69px 0px rgba(224, 223, 233, 0.38)',
  [theme.breakpoints.down('lg')]: {
    gap: '20px',
  }
}))

export default function CardServiciosTeleconsulta({ onClose }) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <BasePopUpWraperCard
      image={teleconsulta}
      title={'Teleconsulta'}
      boxShadow={isMobile ? 'none' : ''}
      renderRightSide={
        <ExtraInfoCardPopUp>
          <InfoContainer>
            <img src={info} alt='' />
            <TitleInfo>¿Quiénes pueden solicitarlo? </TitleInfo>
          </InfoContainer>
          <InfoText>El titular de la cuenta y sus beneficiarios.</InfoText>
          <InfoContainer>
            <img src={info} alt='' />
            <TitleInfo>Horarios</TitleInfo>
          </InfoContainer>
          <InfoText>Las 24 horas del día, los 365 días del año.</InfoText>
        </ExtraInfoCardPopUp>
      }
      onClose={onClose}
    >
      <DescriptionContainer>
        <CardPasosTeleconsulta
          ctaText='Ir a teleconsulta'
        />
      </DescriptionContainer>
    </BasePopUpWraperCard>
  )
}
