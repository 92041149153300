import React from 'react'
import LandingPage from '../../Views/LandingPage'
import RiskDetectionForm from '../../Views/RiskDetectionForm'
import Login from '../../Views/Login/Login'
import PasswordRecovery from '../../Views/PasswordRecovery/PasswordRecovery'
import HistorialServicios from './../../Views/LandingPage/HistorialServicios/HistorialServicios'
import MiPerfil from '../../Views/LandingPage/MiPerfil/MiPerfil'
import LoginChecker from '../Checkers'
import ClinicalRecord from '../../Views/ClinicalRecord'
import {
    BrowserRouter as Router,
    Route,
} from 'react-router-dom'
import AuthGuard from '../Guards/Auth'
import RoutesWithNotFound from '../Wrappers/RoutesWithNotFound'

const RootRoutes = () => {
    return (
        <Router>
            <RoutesWithNotFound>
                <Route
                    exact
                    path="/"
                    element={
                        <LoginChecker
                            element={<Login />}
                        />
                    }
                />
                <Route
                    exact
                    path="/recovery-password"
                    element={<PasswordRecovery />}
                />
                <Route element={<AuthGuard />}>
                    <Route
                        exact
                        path="/lp"
                        element={<LandingPage />}
                    />
                    <Route
                        exact
                        path="/deteccion-de-riesgos-form"
                        element={<RiskDetectionForm />}
                    />
                    <Route
                        exact
                        path="/historial-servicios"
                        element={<HistorialServicios />}
                    />
                    <Route
                        exact
                        path="/mi-perfil"
                        element={<MiPerfil />}
                    />
                    <Route
                        exact
                        path="/expediente-clinico"
                        element={<ClinicalRecord />}
                    />
                </Route>
            </RoutesWithNotFound>
        </Router>
    )
}

export default RootRoutes