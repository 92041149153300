import { styled as muiStyled, Box } from "@mui/material";
import styled from "styled-components";


export const Content = muiStyled(Box)(({ theme }) => ({
    padding: 26,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    backgroundColor: '#fff',
    width: 460,
    height: 'auto',
    borderRadius: 16,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('md')]: {
        width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
        width: '88%',
        padding: '24px 10px 16px 10px',
    },
}))
export const Text = styled.p`
color: #000;
text-align: center;

font-family: Josefin Sans;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin: 0;
`

export const ConfirmButton = styled.button`
display: flex;
width: 140px;
padding: 16px 24px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 40px;
background: #00C46B;
cursor: pointer;
border: none;
color: #F6F6F6;
text-align: center;

/* Common/Button */
font-family: Lato;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;

&:disabled {
    background: #e0e0e0;
    border-color: #e0e0e0;
  }
`
export const CancelButton = styled.button`
display: flex;
width: 140px;
padding: 16px 24px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 40px;
border: 1px solid #00C46B;
cursor: pointer;
background-color: white;

color: #00C46B;
text-align: center;

font-family: Lato;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
`

export const ButtonsContainer = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
}))