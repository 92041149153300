import { useState, useEffect } from 'react';
import requester from '../Apis/requester';

const useGetLpData = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const lpData = await requester({
                    url: '/api/getDataLP',
                });
                setData(lpData.result);
            } catch (error) {
                console.error('Error fetching LP data:', error);
            }
        };

        fetchData();
    }, []);

    return { data };
};

export default useGetLpData;
