/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Spinner from '../../components/Spinner'
import requester from '../../Apis/requester'
import ReviewLastService from '../../components/ReviewLastService/ReviewLastService'
import Principal from '../../components/Principal/Principal'
import ConoceBeneficios from '../../components/ConoceBeneficios/ConoceBeneficios'
import PageBase from '../../components/PageBase'
import useGetLpData from '../../hooks/getLpData'
import { useUser } from '../../shared/useUser'

const LandingPage = () => {
    const [lastReview, setLastReview] = useState(null)
    const idPacient = useUser().idPacient
    const lpData = useGetLpData().data

    const getLastServiceReview = async () => {
        const lastServiceReview = await requester({
            url: `/api/last-review/${idPacient}`,
        })
        if (!lastServiceReview.response) {
            return
        }

        if (lastServiceReview.response.length === 0) {
            setLastReview(null)
            return
        }

        const review = lastServiceReview.response[0]
        const canEvaluateLastReview = review?.CatServicios_idCatServicios && !review?.score

        if (canEvaluateLastReview && review) {
            setLastReview(review)
        }
    }

    useEffect(() => {
        getLastServiceReview()
    }, [])

    if (lpData.length === 0) return <Spinner />

    return (
        <PageBase>
            <Principal data={lpData} />
            {lastReview && (
                <ReviewLastService
                    idPacient={idPacient}
                    idDatAppointment={lastReview.idDatAppointment}
                    lastReviewServiceId={lastReview.CatServicios_idCatServicios}
                    doctorName={lastReview.doctorName}
                    serviceName={lastReview.serviceName}
                    onGetLastServiceReview={getLastServiceReview}
                />
            )}
            <ConoceBeneficios data={lpData} />
        </PageBase>
    )
}

export default LandingPage