import React from "react";
import escudo from "../../../assets/Shield.svg";
import info from "../../../assets/info.svg";
import CardDescriptionBenefitWithSteps from "../../CardDescriptionBenefitWithSteps";
import {
  ExtraInfoCardPopUp,
  InfoContainer,
  TitleInfo,
  InfoText,
  BoldInfoText,
} from "../styles";

export default function CardServiciosOrientaciones({ onClose }) {
  return (
    <CardDescriptionBenefitWithSteps
      image={escudo}
      title="Orientaciones médicas en salud"
      textStep1="Comunícate al número telefónico:"
      textStep2="Elije la opción 2 (Plan Salud). Después de escuchar la grabación, presiona la opción 1."
      textStep3="Otorga tu Número de Colaborador al operador cuando te sea requerido."
      footerTextStep1={"01 800 020 4050"}
      footerTextStep2={""}
      footerTextStep3={"[Número Colaborador]"}
      secondaryTextStep3="Continúa bajo las indicaciones del operador telefónico."
      renderExtraInfo={
        <ExtraInfoCardPopUp>
          <InfoContainer>
            <img src={info} alt="" />
            <TitleInfo>¿Quiénes pueden solicitarlo?</TitleInfo>
          </InfoContainer>
          <InfoText>El titular de la cuenta y sus beneficiarios.</InfoText>
          <InfoContainer>
            <img src={info} alt="" />
            <TitleInfo>Horarios</TitleInfo>
          </InfoContainer>
          <div>
            <BoldInfoText>Orientación médica: </BoldInfoText>
            <InfoText>24 Horas del día, los 365 días del año.</InfoText>
            <BoldInfoText>Orientación emocional: </BoldInfoText>
            <InfoText>24 Horas del día, los 365 días del año.</InfoText>
            <BoldInfoText>Orientación nutricional: </BoldInfoText>
            <InfoText>Lunes a viernes de 9:00 a 21:00 horas</InfoText>
            <BoldInfoText>Orientación odontológica: </BoldInfoText>
            <InfoText>Lunes a sábado de 9:00 a 21:00 horas</InfoText>
          </div>
        </ExtraInfoCardPopUp>
      }
      onClose={onClose}
    />
  );
}
