import React from "react";
import Pagination from "@mui/material/Pagination";
import { CardsContainerHorarios, LlamadasSubt } from "../HistorialServicios.Styled";
import { PaginationWrapper, Wrapper } from "./style";

const HistoryCardsCarrousel = ({ components, llamadasSubtRef }) => {
    const itemsPerPage = 2;
    const totalPages = Math.ceil(components.length / itemsPerPage);
    const [activePage, setActivePage] = React.useState(0);

    const handlePageChange = (event, page) => {
        setActivePage(page - 1);

        if (llamadasSubtRef && llamadasSubtRef.current) {
            llamadasSubtRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };



    return (
        <Wrapper ref={llamadasSubtRef}>
            <CardsContainerHorarios>
                <LlamadasSubt>Historial de llamadas de orientación médica telefónica</LlamadasSubt>
                {components
                    .slice(activePage * itemsPerPage, (activePage + 1) * itemsPerPage)
                    .map((component, index) => (
                        <React.Fragment key={activePage * itemsPerPage + index}>
                            {component}
                        </React.Fragment>
                    ))}
            </CardsContainerHorarios>
            <PaginationWrapper>
                <Pagination
                    count={totalPages}
                    page={activePage + 1}
                    color="primary"
                    onChange={handlePageChange}
                />
            </PaginationWrapper>
        </Wrapper>
    );
};

export default HistoryCardsCarrousel;
