import { styled as muiStyled } from "@mui/material";
import styled from "styled-components";
import Box from "@mui/material/Box";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

export const MyCalendar = muiStyled(DateCalendar)(({ theme }) => ({
    height: 'auto',
    maxHeight: 'auto',
    textTransform: 'capitalize',
    "& .MuiPickersYear-yearButton.Mui-selected": {
        backgroundColor: "#00c46b",
        color: "white",
    },
    "& .MuiPickersYear-yearButton.Mui-selected:focus": {
        backgroundColor: "#00c46b",
        color: "white",
    },
}));

export const CloseImg = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

export const ImageContainer = styled.div`
    padding: 10px 10px 0px 0px;
    box-sizing: border-box;
`

export const Container = muiStyled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: '2px solid #00c46b',
    boxShadow: 24,
}));