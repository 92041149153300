import React from 'react'
import file from '../../assets/file.svg'
import { Wrapper, Name, Image } from './styled'

const CardDocumentSection = ({
    name = 'file',
    onClick,
}) => {

    return (
        <Wrapper
            onClick={onClick}
        >
            <Image
                src={file}
                alt={name}
            />
            <Name>{name}</Name>
        </Wrapper>
    )
}

export default CardDocumentSection