import { useEffect, useState } from 'react'
import requester from '../Apis/requester';
import { useSelector } from 'react-redux'
import { selectUser } from '../redux/features/user';

const useCanMakeEvaluation = () => {
    const [canMakeEvaluation, setcanMakeEvaluation] = useState(false)
    const user = useSelector(selectUser)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const answersAndQuestions = await requester({
                    url: `api/family-heirs/nums-question/${user.idPacient}`,
                });

                if (answersAndQuestions.numAnswers > 0
                    && answersAndQuestions.numAnswers < answersAndQuestions.numQuestions
                ) {
                    setcanMakeEvaluation(null);
                    return;
                }
                setcanMakeEvaluation(answersAndQuestions.flagComplete);
            } catch (error) {
                console.error('Error fetching evaluation status:', error);
            }
        };

        fetchData();
    }, [user.idPacient])

    return canMakeEvaluation
}

export default useCanMakeEvaluation