import React from 'react'
import { Box, styled as muiStyled } from '@mui/material'
import styled from 'styled-components'
import emailicon from "../assets/email.svg";

const Wrapper = muiStyled(Box)(({ theme }) => ({
    width: '100%',
    height: 55,
    borderRadius: 8,
    border: '0.7px solid #d0d0d0',
    display: 'flex',
    padding: '6px 18px 6px 18px',
    backgroundColor: '#ffffff',
    boxSizing: 'border-box',
    alignItems: 'center',
    gap: 10,
    '&:focus-within': {
        boxShadow: '0 0 0 2px rgba(88, 192, 145, 0.25)',
    },
}))

export const InputIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const InputField = styled.input`
  width: 100%;
  height: 100%;
  color: #07122f;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-style: none;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &::placeholder {
    color: #abb3bb;
}
`;

const EmailInput = ({
    placeholder,
    value,
    onChange,
}) => {
    return (
        <Wrapper>
            <InputIcon src={emailicon} alt="email" />
            <InputField
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
        </Wrapper>
    )
}

export default EmailInput