import React, { useState } from 'react'
import PageBase from '../../components/PageBase'
import ClinicalRecordBase from '../../components/ClinicalRecordBase'
import HistoriaClinica from '../../components/HistoriaClinica/HistoriaClinica'
import RecordPortable from '../../components/RecordPortable'

const ClinicalRecord = () => {
    const sections = [
        'Mi expediente portable',
        'Historia clínica',
        'Mi agenda',
    ]
    const [sectionSelected, setSectionSelected] = useState(sections[0])

    let component

    switch (sectionSelected) {
        case 'Historia clínica':
            component = <HistoriaClinica />
            break
        case 'Mi agenda':
            component = <p>3</p>
            break
        default:
            component = <RecordPortable />
            break
    }
    return (
        <PageBase>
            <ClinicalRecordBase
                sectionSelected={sectionSelected}
                onSetSectionSelected={setSectionSelected}
            >
                {component}
            </ClinicalRecordBase>
        </PageBase>
    )
}

export default ClinicalRecord