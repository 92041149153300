import React, { useState } from 'react'
import CoverHeader from '../../../components/HistorialServicios/Hero/Hero'
import CardPerfilUsuario from '../../../components/CardPefilUsuario/CardPerfilUsuario'
import CardsPerfTel from '../../../components/MiPerfil/CardsPerfTel'
import ImgPerfCard from '../../../components/MiPerfil/ImgPerfCard'

//styles
import { MainContainerPerfil } from '../../../Views/LandingPage/MiPerfil/MiPerfil.styled'
import PageBase from '../../../components/PageBase'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../redux/features/user'


export default function MiPerfil() {

  const [showPerfil, setShowPerfil] = useState(false)
  const [showTelefono, setShowTelefono] = useState(false)
  const user = useSelector(selectUser)

  return (
    <PageBase>
      <MainContainerPerfil>
        <CoverHeader title={"Perfil de usuario"} />
        <CardPerfilUsuario
          mostrarPerfil={showPerfil}
          setMostrarPerfil={setShowPerfil}
          user={user}
        />
        <CardsPerfTel
          mostrarPerfil={showPerfil}
          mostrarTelefono={showTelefono}
          setMostrarPerfil={setShowPerfil}
          setMostrarTelefono={setShowTelefono}
        />
        <ImgPerfCard
          mostrarPerfil={showPerfil}
          mostrarTelefono={showTelefono}
          setMostrarPerfil={setShowPerfil}
          setMostrarTelefono={setShowTelefono}
          user={user}
          
        />
      </MainContainerPerfil>
    </PageBase>
  )
}
