import { Typography, styled as muiStyled } from "@mui/material";
import styled from "styled-components";
import Box from "@mui/material/Box";

export const InnerContainer = muiStyled(Box)(({ theme }) => ({
  width: 461,
  display: "flex",
  flexDirection: "column",
  gap: 40,
  padding: 10,
  boxSizing: "border-box",
  [theme.breakpoints.down("sm")]: {
    padding: "32px 24px 32px 24px",
    width: "100%",
    gap: 24,
  },
}));

export const Container = muiStyled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  border: '2px solid #00c46b',
  boxShadow: 24,
}));

export const MainTitle = styled.h2`
  color: #07122f;
  font-family: Josefin Sans;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  @media (max-width: 599.95px) {
    text-align: center;
    font-size: 30px;
  }
`;

export const ContainerInput = muiStyled(Box)`
  width: calc(100% - 12px);
  height: 55px;
  border-radius: 8px;
  border: 0.7px solid #d0d0d0;
  display: flex;
  padding: 6px 6px 6px 18px;
  background-color: #ffffff;
  box-sizing: border-box;
  align-items: center;
  gap: 10px;
  &:focus-within {
    box-shadow: 0 0 0 2px rgba(88, 192, 145, 0.25);
  }
`;

export const DateContainer = muiStyled(Box)`
display: flex;
width: 245px;
padding: 12px 16px;
justify-content: center;
align-items: center;
gap: 10px;
margin-top: 16px;
border: none;
cursor: pointer;

border-radius: 16px;
background: #F3F2F2;

color: #6C81A0;

/* Common/Button */
font-family: Lato;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
@media (max-width: 1210px) {
    width: 130%;
    margin-top: 0;
}
`;

export const InputIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const Subtitle = styled.p`
  color: #36455a;

  /* Tittle */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  @media (max-width: 599.95px) {
    text-align: center;
  }
`;

export const InputField = styled.input`
  width: 100%;
  height: 100%;
  color: #07122f;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-style: none;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &::placeholder {
    color: #abb3bb;
}
`;

export const RecuperarContainer = muiStyled(Box)(({ theme }) => ({
  width: "97%",
  display: "flex",
  justifyContent: "flex-end",
}));

export const RecuperarText = styled.a`
  color: var(--Primary, #00c46b);
  text-align: right;

  /* Button */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
`;

export const Button = styled.button`
  display: flex;
  width: calc(100% - 12px);
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;
  border-radius: 40px;
  border-color: #00c46b;
  border-style: solid;
  background: var(--Primary, #00c46b);
  box-shadow: 2px 4px 4px 0px rgba(43, 43, 43, 0.1);
  color: var(--Background, #f6f6f6);
  text-align: center;
  cursor: pointer;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  &:disabled {
    background: #e0e0e0;
    border-color: #e0e0e0;
  }
`;

export const TitleContainer = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
  [theme.breakpoints.down('sm')]: {
    gap: 8,
  }
}))

export const DateText = muiStyled(Typography)(({ theme }) => ({
  fontFamily: 'Lato',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: 'normal',
}))

export const AcceptButton = styled.button`
    display: flex;
  width: calc(50% - 12px);
  flex-direction: column;
  align-items: center;
  margin: 0 5px 10px 0;
  padding: 10px;
  border-radius: 40px;
  border-color: #00c46b;
  border-style: solid;
  background: var(--Primary, #00c46b);
  box-shadow: 2px 4px 4px 0px rgba(43, 43, 43, 0.1);
  color: var(--Background, #f6f6f6);
  text-align: center;
  cursor: pointer;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`