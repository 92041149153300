import Axios from "axios";

const requester = async ({ url, method = "GET", data = {}, params = {}, extraHeaders = {} }) => {
  return Axios({
    headers: { authtoken: process.env.REACT_APP_AUTHTOKEN, ...extraHeaders },
    baseURL: process.env.REACT_APP_URL,
    url,
    method, 
    data,
    params,
  })
    .then((res) => res.data)
    .catch((err) => err.response.data || err.response);
};
 
export default requester;
