import styled from "styled-components";

export const CardContainer = styled.div`
    display: flex;
    width: 298px;
    height: 168px;
    padding: 32px 20px 30px 20px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-radius: 0px 0px 10px 10px;
    background: #FFF;
    cursor: pointer;
     &.open {
        border-top: 9px solid #86F2B1;
    }
    box-shadow: 0px 19px 69px 0px rgba(224, 223, 233, 0.38);
        @media (max-width: 1210px) {
        width: 90%;
    }
`
export const CardImg = styled.img`
    width: 60px;
`
export const Title = styled.p`
    color: #444;
    text-align: center;

/* Desktop/H3 */
    font-family: Josefin Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`
export const ArrowContainer = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
`