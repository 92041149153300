import React, { useState } from "react";
import {
  InnerContainer,
  MainTitle,
  RecuperarText,
  Subtitle,
  RecuperarContainer,
  Button,
  TitleContainer,
} from "./styles";
import AuthenticationBase from "../../components/AuthenticationBase/AuthenticationBase";
import { useNavigate } from "react-router-dom";
import Calendar from "../../components/Calendar";
import requester from "../../Apis/requester";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/features/user";
import LoginWithCollaboratorNumber from "./LoginWithCollaboratorNumber";
import LoginWithEmail from "./LoginWithEmail";
import validateEmail from "../../helpers/validateEmail";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userSession = '1';
  const [openCalendar, setOpenCalendar] = useState(false);
  const [calendarValue, setCalendarValue] = useState(null);
  const [numberUser, setNumberUser] = useState('');
  const [userEmail, setUserEmail] = useState('')
  const [password, setPassword] = useState('')

  const computeCalendarDaySelected = calendarValue ? calendarValue.format("DD/MM/YYYY") : null;

  const handleChange = (event) => {
    let value = event.target.value;
    value = value.replace(/[^0-9]/g, "");
    setNumberUser(value);
  };

  const disabledButton = (() => {
    if (userSession === '1') {
      return !numberUser || !computeCalendarDaySelected
    } else {
      return !userEmail || !password || !validateEmail(userEmail)
    }
  })()

  const onSubmit = async (event) => {
    event.preventDefault();

    let resquest = null
    if (userSession === '1') {
      resquest = await requester({
        url: `/api/auth/login-esplus`,
        method: "POST",
        data: {
          id_colaborator: numberUser,
          birthday: calendarValue.format("YYYY-MM-DD"),
        },
      });
    } else {
      resquest = await requester({
        url: `/api/auth/login`,
        method: "POST",
        data: {
          email: userEmail,
          password: password,
        },
      });
    }

    const user = resquest.response;

    if (resquest.success) {
      dispatch(setUser(user))
      localStorage.setItem("userId", resquest.response.idUser);
      localStorage.setItem("userName", resquest.response.name);
      navigate("/lp")
    } else {
      alert(resquest.reason || "Ocurrió un error")
    }

  };

  return (
    <AuthenticationBase>
      <InnerContainer>
        <TitleContainer>
          <MainTitle>Inicia sesión</MainTitle>
          <Subtitle>Por favor introduzca los siguientes datos:</Subtitle>
        </TitleContainer>
        {userSession === '1' && (
          <LoginWithCollaboratorNumber
            numberUser={numberUser}
            onSetNumberUser={handleChange}
            setOpenCalendar={setOpenCalendar}
            computeCalendarDaySelected={computeCalendarDaySelected}
          />
        )}
        {userSession === '0' && (
          <LoginWithEmail
            userEmail={userEmail}
            password={password}
            onSetPassword={setPassword}
            onSetUserEmail={setUserEmail}
          />
        )}
        {userSession === '0' && (
          <RecuperarContainer>
            <RecuperarText onClick={() => navigate("/recovery-password")}>
              Recuperar contraseña
            </RecuperarText>
          </RecuperarContainer>
        )}
        <Button
          disabled={disabledButton}
          onClick={onSubmit}
        >
          Iniciar sesión
        </Button>
      </InnerContainer>
      <Calendar
        open={openCalendar}
        value={calendarValue}
        onSetValue={setCalendarValue}
        onClose={() => setOpenCalendar(false)}
      />
    </AuthenticationBase>
  );
}