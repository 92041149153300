import React from 'react'
import Modal from '@mui/material/Modal';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es-mx';
import dayjs from 'dayjs';
import closeImage from '../../assets/Close.svg'
import { MyCalendar, CloseImg, Container, ImageContainer } from './styles'

const Calendar = ({ open, value, onClose, onSetValue }) => {

    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Container>
                    <ImageContainer>
                        <CloseImg src={closeImage} alt='close' onClick={onClose} />
                    </ImageContainer>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale='es-mx'
                    >
                        <MyCalendar
                            value={value}
                            onChange={(newValue) => {
                                onSetValue(dayjs(newValue))
                            }}
                            maxDate={dayjs()}
                            slotProps={{
                                day: {
                                    sx: {
                                        '&.Mui-selected': {
                                            bgcolor: '#00c46b',
                                            color: 'white',
                                        },
                                        '&.Mui-selected.Mui-disabled': {
                                            bgcolor: '#00c46b',
                                            color: 'white',
                                        },
                                        '&.Mui-selected:hover': {
                                            color: 'white',
                                            bgcolor: '#00c46b',
                                        },
                                        '&.Mui-selected:focus': {
                                            color: 'white',
                                            bgcolor: '#00c46b',
                                        },
                                    },
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Container>
            </Modal>
        </div>
    )
}

export default Calendar