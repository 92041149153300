import styled from "styled-components";
import { styled as muiStyled, Box, Typography, Button } from "@mui/material";

export const Wrapper = muiStyled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "32px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
        marginTop: "0px",
    },
}));



export const Frame = muiStyled(Box)(({ theme }) => ({
    display: "flex",
    padding: "32px 124px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    background: "#F6F6F6",
    width: "85%",
    [theme.breakpoints.down("md")]: {
        width: "100%",
        padding: "32px 24px",
        boxSizing: "border-box",
        justifyContent: 'center',
        alignItems: 'center',
        gap: '32px',
    },
}))

export const Title = muiStyled(Typography)(({ theme }) => ({
    color: "#444",
    fontFamily: "Josefin Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    margin: "0",
    padding: "0",
    textAlign: "left",
    width: "100%",
    [theme.breakpoints.down("md")]: {
        textAlign: "center",
        color: "#00C46B",
        fontSize: "30px",
        width: "90%",
    }
}))

export const Card = muiStyled(Box)`
    display: flex;
    max-width: 772px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 26px;
    box-sizing: border-box;
    box-shadow: 0px 19px 69px 0px rgba(224, 223, 233, 0.38);
`

export const CardFrame = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    width: '772px',
    padding: '32px 32px 32px 56px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
    borderRadius: '10px',
    background: '#FFF',
    alignSelf: 'stretch',
    boxSizing: 'border-box',
    boxShadow: '0px 36.72655px 133.37538px 0px rgba(224, 223, 233, 0.38)',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '32px 24px 32px 24px',
    },
}))

export const TitleWrapper = muiStyled(Box)`
    display: flex;
    align-items: center;
    gap: 38.66px;
    align-self: stretch; 

`

export const TitleContainer = muiStyled(Box)`
    display: flex;
    flex-direction: column;
    gap: 7px;
`

export const AvatarReview = styled.img`
display: flex;
width: 32.331px;
height: 39.71px;
flex-direction: column;
align-items: center;
`

export const DoctorName = styled.h2`
    color: #444;
    font-family: Josefin Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding: 0;
`

export const ServiceName = styled.h2`
    color: #D9D9D9;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding: 0;
`

export const Description = styled.h2`
    color: #666;
    text-align: center;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-self: stretch;
    margin: 0;
    padding: 0;
    @media (max-width: 1210px) {
        width: 100%;
    }
`

export const InputWrapper = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    width: '671px',
    alignItems: 'center',
    borderRadius: '8px',
    gap: '32px',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        width: '100%',
    }
}))
export const InputContainer = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    borderRadius: '8px',
    border: '1px solid #000',
    flex: 'auto',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        width: '100%',
    }
}))

export const StyledInput = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  resize: none;
  box-sizing: border-box;
  height: 82px;
  border-radius: 8px;
  padding: 10px 8px 10px 10px; 
  &::placeholder {
    color: #D9D9D9;
  }
  @media (max-width: 1210px) {
        width: 100%;
    }
`

export const ButtonContainer = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '188px',
    [theme.breakpoints.down('md')]: {
        width: '100%',
    }
}))

export const SendButton = muiStyled(Button)`
    display: flex;
    width: 100%;
    padding: 16px 24px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    height: 54px;
    box-shadow: 2px 4px 4px 0px rgba(43, 43, 43, 0.10);
    border: 0;
    color: white;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Lato';
    &.send {
        background-color: #5F6267;
    }
`
