import React from 'react'
import lineDivisor from '../../assets/line.svg'
import icon from '../../assets/heroImg.svg'
import finger from '../../assets/finger.svg'
import {
  MainContainer,
  RightSide,
  LeftSide,
  TitleLeft,
  SubtitleLeft,
  InnerLeft,
  InnerLeftSideInner,
  BackgroundImg,
  RightSideContainer,
  IconContainer,
  BackgroundContainer,
  DividerContainer,
} from "./style";

const AuthenticationBase = ({ children }) => {
  return (
    <MainContainer
      flexDirection={{ xs: 'column-reverse', md: 'row' }}
      alignItems={{ xs: 'center', md: 'unset' }}
      justifyContent={{ xs: 'flex-end', md: 'unset' }}
    >
      <LeftSide>
        {children}
      </LeftSide>
      <RightSide>
        <RightSideContainer>
          <IconContainer>
            <img src={icon} alt='icon' />
          </IconContainer>
          <InnerLeftSideInner>
            <DividerContainer>
              <img src={lineDivisor} alt='' />
            </DividerContainer>
            <InnerLeft>
              <TitleLeft>Expediente</TitleLeft>
              <TitleLeft>Clínico</TitleLeft>
              <TitleLeft>Electrónico</TitleLeft>
              <SubtitleLeft>Tu salud es primero </SubtitleLeft>
            </InnerLeft>
          </InnerLeftSideInner>
        </RightSideContainer>
        <BackgroundContainer>
          <BackgroundImg
            src={finger}
            alt='fing'
          />
        </BackgroundContainer>
      </RightSide>
    </MainContainer>
  )
}

export default AuthenticationBase
