import React from 'react'
import { Box, styled } from '@mui/material'
import checkup from '../assets/maletin.svg'
import info from '../assets/info.svg'
import BasePopUpWraperCard from './BasePopUpWraperCard'
import CardPasosTeleconsulta from './ConoceBeneficios/CardServicios/CardPasoServicios/CardPasosTeleconsulta'
import { useMediaQuery, useTheme } from '@mui/material';
import {
    ExtraInfoCardPopUp,
    InfoContainer,
    TitleInfo,
    InfoText,
} from './ConoceBeneficios/styles'

export const DescriptionContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '37px',
    borderRadius: '10px',
    background: '#FFFFFF',
    boxShadow: '0px 19px 69px 0px rgba(224, 223, 233, 0.38)',
    [theme.breakpoints.down('lg')]: {
        gap: '20px',
    }
}))

const CardDescriptionCheckUp = ({ onClose }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <BasePopUpWraperCard
            image={checkup}
            title={'Checkup Médico'}
            boxShadow={isMobile ? 'none' : ''}
            renderRightSide={
                <ExtraInfoCardPopUp>
                    <InfoContainer>
                        <img src={info} alt='info' />
                        <TitleInfo>¿Quiénes pueden solicitarlo? </TitleInfo>
                    </InfoContainer>
                    <InfoText>El titular de la cuenta y sus beneficiarios.</InfoText>
                    <InfoContainer>
                        <img src={info} alt='info' />
                        <TitleInfo>Horarios</TitleInfo>
                    </InfoContainer>
                    <InfoText>Lunes a viernes de 08:00 a 20:00 horas.</InfoText>
                    <InfoText>Sábado de 08:00 a 13:00 horas.</InfoText>
                </ExtraInfoCardPopUp>
            }
            onClose={onClose}
        >
            <DescriptionContainer>
                <CardPasosTeleconsulta
                    ctaText="Iniciar Checkup Médico"
                />
            </DescriptionContainer>
        </BasePopUpWraperCard>
    )
}

export default CardDescriptionCheckUp