import React from 'react'
import ambulancia from '../../../assets/ambulalancia.svg'
import xCirculo from '../../../assets/xCirculo.svg'
import info from '../../../assets/info.svg'
import CardDescriptionBenefitWithSteps from '../../CardDescriptionBenefitWithSteps'
import {
  ExtraInfoCardPopUp,
  InfoContainer,
  TitleInfo,
  InfoText,
} from '../styles'

export default function CardServiciosAmbulancia({ onClose }) {
  return (
    <CardDescriptionBenefitWithSteps
      image={ambulancia}
      title={'Ambulancia Terrestre'}
      textStep1={'Comunícate al número telefónico:'}
      textStep2={'Otorga tu Número de Colaborador al operador cuando te sea requerido.'}
      textStep3={'Continúa bajo las indicaciones del operador telefónico.'}
      footerTextStep1={'01 800 020 4050'}
      footerTextStep2={'[Número Colaborador]'}
      footerTextStep3={''}
      renderExtraInfo={
        <ExtraInfoCardPopUp>
          <InfoContainer>
            <img src={xCirculo} alt='' />
            <TitleInfo>Solicitud de ambulancia </TitleInfo>
          </InfoContainer>
          <InfoText>Tu plan no permite solicitar alguna unidad. </InfoText>
          <InfoContainer>
            <img src={info} alt='' />
            <TitleInfo>¿Quiénes pueden solicitarlo? </TitleInfo>
          </InfoContainer>
          <InfoText>El titular de la cuenta y sus beneficiarios.</InfoText>
          <InfoContainer>
            <img src={info} alt='' />
            <TitleInfo>Horarios</TitleInfo>
          </InfoContainer>
          <InfoText>Las 24 horas del día, los 365 días del año.</InfoText>
        </ExtraInfoCardPopUp>
      }
      onClose={onClose}
    />
  )
}
