import React, { useState } from "react";
import { Wrapper, Answertext, ButtonsContainer, Button, ButtonText } from "./styles";

const QuestionOrAnswer = ({ answer, onSaveResponse, saving, onSetSaving }) => {
    const optionSelected = (() => {
        if (!answer[0]) return null;
        if (!answer[0]['answer']) return null;
        if (answer[0]['answer'] === '') return null;

        return answer[0]['answer']
    })()

    const [buttonClicked, setButtonClicked] = useState(optionSelected);
    const handleClick = (response) => {
        onSetSaving(true)
        setButtonClicked(response);
        onSaveResponse(answer, response)
    };
    return (
        <Wrapper>
            <Answertext>{answer.text}</Answertext>
            <ButtonsContainer>
                <Button
                    bgcolor={saving ? '#F5F5F5' : 'white'}
                    boxShadow={buttonClicked === '1' ? '0px 5px 4px 0px rgba(0, 0, 0, 0.06)' : 'none'}
                    onClick={() => handleClick('1')}
                >
                    <ButtonText
                        color={buttonClicked === '1' ? '#00C46B' : '#444444'}
                    >
                        Sí
                    </ButtonText>
                </Button>
                <Button
                    bgcolor={saving ? '#F5F5F5' : 'white'}
                    boxShadow={buttonClicked === '0' ? '0px 5px 4px 0px rgba(0, 0, 0, 0.06)' : 'none'}
                    onClick={() => handleClick('0')}
                >
                    <ButtonText
                        color={buttonClicked === '0' ? '#00C46B' : '#444444'}
                    >
                        No
                    </ButtonText>
                </Button>
            </ButtonsContainer>
        </Wrapper>
    );
};

export default QuestionOrAnswer;
