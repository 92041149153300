import styled from "styled-components";
import { styled as muiStyled, Box } from "@mui/material";

export const Wrapper = muiStyled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    padding: "0 20px",
    width: '100%',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
    },
    [theme.breakpoints.down('md')]: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        padding: '0 16px',
        borderRadius: '0 0 16px 16px',
    }
}))

export const Frame = muiStyled(Box)(({ theme }) => ({
    maxWidth: '1500px',
    display: 'flex',
    gap: '60px',
    background: '#FFF',
    padding: '32px',
    borderRadius: '10px',
    [theme.breakpoints.down('lg')]: {
        gap: '20px',
    },
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        width: '100%',
        boxSizing: 'border-box',
        maxWidth: 'none',
        padding: '0',
    }
}))

export const ImgaeContainer = muiStyled(Box)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        display: 'none',
    }
}))

export const ImageCard = styled.img`
    width: 80.81px;
    height: 70.941px; 
`

export const DescriptionFrame = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`

export const TitleContainer = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        display: 'none',
    }
}))
export const TitleCard = styled.h2`
    color: #444;
    font-family: Josefin Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;

`

export const CloseImg = styled.img`
    width: 12px;
    height: 12px;
    cursor: pointer;
`

export const DescriptionContainer = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '37px',
    [theme.breakpoints.down('lg')]: {
        gap: '20px',
    },
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
    }
}))

export const InfoContainer = muiStyled(Box)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        width: '100%',
    }
}))
