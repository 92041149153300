import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  CardTitle,
  EvaluationText,
  MainContainerDeteccion,
  RepetirEvalText,
  TitleContainer,
  InnerContainer,
  ExpandContainer,
} from "./CardPasoServicio.style";
import xCirle from "../../../../assets/xCirculo.svg";
import validate from "../../../../assets/Validate.svg";
import FormView from "../../../CardServiciosDeteccion/FormView";
import { useNavigate } from 'react-router-dom';
import useCanMakeEvaluation from "../../../../hooks/useCanMakeEvaluation";

export default function CardPasosDeteccion({ expand, onSetExpand }) {
  const navigate = useNavigate();
  const evaluationIsComplete = useCanMakeEvaluation();
  const imageStatus = evaluationIsComplete ? validate : xCirle;

  const handleClick = () => {
    navigate('/deteccion-de-riesgos-form');
  };


  const ctaText = (() => {
    if (evaluationIsComplete) {
      return 'Repetir evaluación';
    }
    if (evaluationIsComplete === null) {
      return 'Continuar evaluación'
    }

    return 'Realizar evaluación';
  })()

  return (
    <MainContainerDeteccion>
      <InnerContainer>
        <TitleContainer>
          <CardTitle>Evaluación completa</CardTitle>
          <img src={imageStatus} alt="" />
        </TitleContainer>
        <RepetirEvalText onClick={handleClick}>{ctaText}</RepetirEvalText>
      </InnerContainer>
      {evaluationIsComplete && (
        <ExpandContainer onClick={() => onSetExpand(!expand)}>
          <EvaluationText>Ver evaluación</EvaluationText>
          {expand ? (
            <ExpandMoreIcon htmlColor='#000E13' />

          ) : (
            <ExpandLessIcon htmlColor='#000E13' />
          )}
        </ExpandContainer>
      )}

      {expand && evaluationIsComplete && <FormView />}
    </MainContainerDeteccion>
  );
}
