import React, { useState } from "react";
//assets
import profileCircled from "../../../assets/profileCircled.svg";
import warning from "../../../assets/circleWarning.svg";
import clock from "../../../assets/clock.svg";
//styles
import {
    MainContainer,
    Divisor,
    DrName,
    DrNameContainer,
    ImgCard,
    TextAreaCard,
    TitleDr,
    TitleGray,
    TitleGrayContaier,
    WarnigContainer,
    ContainerTextArea,
    ImgTextArea,
} from "./CardHorarios.styled";
export default function CardHorarios({
    name,
    date,
    time,
    status,
    folio,
    opcion1,
    opcion2,
    opcion3,
    opcion4,
}) {
    const [optionSelected, setOptionSelected] = useState(1);
    const [comments, setComments] = useState({
        1: "",
        2: "",
        3: "",
        4: "",
    });

    const comment = comments[optionSelected];
    return (
        <MainContainer>
            <ImgTextArea>
                <ImgCard src={profileCircled} alt="profile" />
                <DrNameContainer>
                    <WarnigContainer>
                        <img alt="" src={warning} />
                        <TitleDr>
                            Dr(a): <DrName>{name}</DrName>
                        </TitleDr>
                    </WarnigContainer>
                    <WarnigContainer>
                        <img alt="" src={clock} />
                        <TitleDr>
                            Fecha: <DrName>{date}</DrName>
                        </TitleDr>
                    </WarnigContainer>
                    <WarnigContainer>
                        <img alt="" src={clock} />
                        <TitleDr>
                            Hora: <DrName>{time}</DrName>
                        </TitleDr>
                    </WarnigContainer>
                    <WarnigContainer>
                        <img alt="" src={clock} />
                        <TitleDr>
                            Estatus: <DrName>{status}</DrName>
                        </TitleDr>
                    </WarnigContainer>
                    <WarnigContainer>
                        <img alt="" src={clock} />
                        <TitleDr>
                            Folio: <DrName>{folio}</DrName>
                        </TitleDr>
                    </WarnigContainer>
                </DrNameContainer>
            </ImgTextArea>
            <Divisor></Divisor>
            <TitleGrayContaier>
                <TitleGray
                    className={optionSelected === 1 ? "open" : ""}
                    onClick={() => setOptionSelected(1)}
                >
                    {opcion1}
                </TitleGray>
                <TitleGray
                    className={optionSelected === 2 ? "open" : ""}
                    onClick={() => setOptionSelected(2)}
                >
                    {opcion2}
                </TitleGray>
                <TitleGray
                    className={optionSelected === 3 ? "open" : ""}
                    onClick={() => setOptionSelected(3)}
                >
                    {opcion3}
                </TitleGray>
                <TitleGray
                    className={optionSelected === 4 ? "open" : ""}
                    onClick={() => setOptionSelected(4)}
                >
                    {opcion4}
                </TitleGray>
            </TitleGrayContaier>
            <ContainerTextArea>
                <TextAreaCard
                    value={comment}
                    onChange={(e) =>
                        setComments({
                            ...comments,
                            [optionSelected]: e.target.value,
                        })
                    }
                    placeholder="Por favor, compártenos algún comentario (máximo 200 caracteres)"
                />
            </ContainerTextArea>
        </MainContainer>
    );
}
