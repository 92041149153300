import React from 'react'
import message from '../../../assets/message.svg'
import info from '../../../assets/info.svg'
import CardDescriptionBenefitWithSteps from '../../CardDescriptionBenefitWithSteps'
import {
  ExtraInfoCardPopUp,
  InfoContainer,
  TitleInfo,
  InfoText,
} from "../styles";

export default function CardServiciosConcentracionCitas({ onClose }) {
  return (
    <CardDescriptionBenefitWithSteps
      image={message}
      title={'Concertación de citas'}
      textStep1={'Comunícate al número telefónico y presiona la opción 2'}
      textStep2={'Otorga tu Número de Colaborador al operador cuando te sea requerido.'}
      textStep3={'Continúa bajo las indicaciones del operador telefónico.'}
      footerTextStep1={'01 800 020 4050'}
      footerTextStep2={'[Número Colaborador]'}
      renderExtraInfo={
        <ExtraInfoCardPopUp>
          <InfoContainer>
            <img src={info} alt='' />
            <TitleInfo>¿Quiénes pueden solicitarlo? </TitleInfo>
          </InfoContainer>
          <InfoText>El titular de la cuenta y sus beneficiarios.</InfoText>
          <InfoContainer>
            <img src={info} alt='' />
            <TitleInfo>Horarios</TitleInfo>
          </InfoContainer>
          <InfoText>Lunes a viernes de 08:00 a 20:00 horas.</InfoText>
          <InfoText>Sábado de 08:00 a 13:00 horas.</InfoText>
        </ExtraInfoCardPopUp>
      }
      onClose={onClose}
    />
  )
}
