import React from 'react'
import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 0 20px;
`

export const Frame = styled.div`
    min-width: 1000px;
    height: 188px;
    max-width: 1500px;
    display: flex;
    align-items: center;
    gap: 130px; 
    background: #FFF;
    padding: 32px; 
    border-radius: 10px;
    box-shadow: 0px 36.72655px 133.37538px 0px rgba(224, 223, 233, 0.38);
    @media (max-width: 1340px) {
        padding-left: 20px;
        padding-right: 20px;
        gap: 110px;
    }
`

export const ImageCard = styled.img`
    width: 80.81px;
    height: 70.941px; 
`

export const DescriptionFrame = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`

export const ImageContainer = styled.div`
    display: flex;
    width: 275px;
    height: 150px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #00C46B;
`

export const ContentWrapper = styled.div`
    display: flex;
    gap: 24px;
    justify-content: center;
`

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
`

export const Title = styled.p`
    color: #444;
font-family: Lato;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin: 0;
text-align: center;

`


const CardPayServiceHistory = () => {
  return (
    <Wrapper>
        <Frame>
            <ImageContainer>
                <ImageCard src='' alt='history' />
            </ImageContainer>
            <DescriptionFrame>
                <ContentWrapper>
                    <TextWrapper>
                        <Title>
                        Aún no hay trámites en curso
                        </Title>
                    </TextWrapper>
                </ContentWrapper>
            </DescriptionFrame>
        </Frame>
    </Wrapper>
  )
}

export default CardPayServiceHistory