import React from 'react';
import {
    MainContModal,
    OverlayModal,
    ContBtnModal,
    BtnAceptar,
    BtnCancelar
} from './ModalTB.styled';

export default function ModalTB({ children, estado, cambiarEstado, onConfirmar }) {
    const handleAceptarClick = () => {
        if (onConfirmar) {
            onConfirmar();
        }
        cambiarEstado(!estado);
    };

    return (
        <>
            {estado && (
                <OverlayModal>
                    <MainContModal>
                        {children}
                        <ContBtnModal>
                            <BtnCancelar onClick={() => cambiarEstado(!estado)}>
                                Cancelar
                            </BtnCancelar>
                            <BtnAceptar onClick={handleAceptarClick}>Aceptar</BtnAceptar>
                        </ContBtnModal>
                    </MainContModal>
                </OverlayModal>
            )}
        </>
    );
}