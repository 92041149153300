import React from 'react'
//assets
// import flecha from '../../../../assets/arrowDown.svg'
//assets<
import { CardContainer, CardImg, Title } from './Styles'

export default function CardsExpediente({ titulo, imgCard, onClick, isOpen, }) {
    return (
        <CardContainer className={isOpen ? 'open' : ''} onClick={onClick}>
            <CardImg src={imgCard} />
            <Title>{titulo} </Title>
        </CardContainer>
    )
}