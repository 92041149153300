import React from 'react'
import CardDescriptionBenefitWithSteps from './CardDescriptionBenefitWithSteps'
import saves from '../assets/saves.svg'
import info from '../assets/info.svg'
import styled from "styled-components";
import { ExtraInfoCardPopUp } from './ConoceBeneficios/styles'

export const InfoContainer = styled.div`
    display: flex;
    gap:8px;
    align-items: center;
`

export const TitleInfo = styled.p`
    color: var(--Text-Titulo, #051201);

    /* Body */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
`

export const InfoText = styled.p`
    color: var(--Body, #5F6267);

    /* Legal */
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin:5px;
`

const CardDescriptionGridDiscount = ({ onClose }) => {
    return (
        <CardDescriptionBenefitWithSteps
            image={saves}
            title='Red de descuentos'
            textStep1='Escuchar la grabación y presionar el número como opción (Plan Salud) después presional la opción 1.'
            textStep2='Otorga tu Número de Colaborador al operador cuando te sea requerido.'
            textStep3='Continúa bajo las indicaciones del operador telefónico.'
            footerTextStep1='01 800 020 4050'
            footerTextStep2='[Número Colaborador]'
            footerTextStep3=''
            renderExtraInfo={
                <ExtraInfoCardPopUp>
                    <InfoContainer>
                        <img src={info} alt='info' />
                        <TitleInfo>¿Quiénes pueden solicitarlo? </TitleInfo>
                    </InfoContainer>
                    <InfoText>El titular de la cuenta y sus beneficiarios.</InfoText>
                    <InfoContainer>
                        <img src={info} alt='info' />
                        <TitleInfo>Horarios</TitleInfo>
                    </InfoContainer>
                    <InfoText>Lunes a viernes de 08:00 a 20:00 horas.</InfoText>
                    <InfoText>Sábado de 08:00 a 13:00 horas.</InfoText>
                </ExtraInfoCardPopUp>
            }
            onClose={onClose}
        />
    )
}

export default CardDescriptionGridDiscount