import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { styled as muiStyled, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ReturnButtonContainer = muiStyled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 35,
    width: 35,
    borderRadius: "50%",
    ":hover": {
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
        background: "#00c46b",
    },
    [theme.breakpoints.down("md")]: {
        background: "#ffffff",
        ":hover": {
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
            background: "#ffffff",
        },
    },
}));

const BackIcon = muiStyled(ArrowBackIosIcon)(({ theme }) => ({
    color: "#00c46b",
    cursor: "pointer",
    paddingLeft: 9,
    height: 34,
    fontSize: 24,
    ":hover": {
        color: "#ffffff",
    },
    [theme.breakpoints.down("md")]: {
        ":hover": {
            color: "#00c46b",
        },
    },
}));
const BackButtonPage = ({ path }) => {
    const navigate = useNavigate();

    return (
        <ReturnButtonContainer>
            <BackIcon onClick={() => navigate(path)} />
        </ReturnButtonContainer>
    );
};

export default BackButtonPage;
