const computeCardOrderBenefitsList = (array, maxElementsPerRow) => {
    const totalElementos = array.length;
    const elementosPorFila = [];
    let elementosRestantes = totalElementos;
    let indice = 0;

    while (elementosRestantes > 0) {
        if (indice % 2 === 0) {
            elementosPorFila.push(3);
        } else {
            elementosPorFila.push(maxElementsPerRow);
        }
        elementosRestantes -= elementosPorFila[indice];
        indice++;
    }

    const chunks = [];
    let currentIndex = 0;

    elementosPorFila.forEach((elementosEnFila) => {
        const chunk = array.slice(currentIndex, currentIndex + elementosEnFila);
        chunks.push(chunk);
        currentIndex += elementosEnFila;
    });

    return chunks;
};

export default computeCardOrderBenefitsList
