import styled from "styled-components";

export const MainContainer = styled.div`
    width: 100%;
    height: 222px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: var(--Primary, #104C7C);
    position: relative;
    @media (max-width: 1210px) {
        border-radius: 0;
    }

`
export const SubContainer = styled.div`
    display: flex;
    width: 70%;
    padding: 24px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 19px 69px 0px rgba(224, 223, 233, 0.38);
    position: absolute;
    bottom:0;
    @media (max-width: 1210px) {
        display: none;
    }
`
export const ContWelcome = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 26px;
    margin: 32px 22px 10px 0;
    @media (max-width: 1210px) {
        display: none;
    }
`
export const ContWelcomeMob = styled.div`
    display: none;

    @media (max-width: 1210px) {
        display: flex;
        align-items: center;
        justify-content: start;
        background: none;
        gap: 10px;
        padding: 0px 20px;
        box-sizing: border-box;
        width: 100%;
    }
`
export const Title = styled.h2`
    color: var(--Primary, #104C7C);
    text-align: center;
    font-family: Josefin Sans;
    font-size: 66px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin:0;
        @media (max-width: 1210px) {
        font-size: 40px; 
    }
`
export const SubContainerM = styled.div`
    display: none;
    width: 70%;
    padding: 24px 0px;
    justify-content: center;
    align-items: center;
    gap: 32px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 19px 69px 0px rgba(224, 223, 233, 0.38);
    @media (max-width: 1210px) {
        display: flex;
        flex-direction: column;
        background: none;
        width: 100%;

    }
`
export const ContainerM = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background: #fafafa;
`
export const WelcomeText = styled.p`
    color: #6C81A0;

    /* Common/Tittle */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`