import React from 'react'
import dentalPlan from '../assets/dentalPlan.svg'
import closeimg from '../assets/Close.svg'
import styled from "styled-components";

export const Wrapper = styled.div`
    /* padding: 0 20px; */
`

export const Frame = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 1000px;
    height: auto;
    max-width: 1150px;
    gap: 10px; 
    padding: 32px; 
    border-radius: 10px;
    background-color: #F6F6F6;
    box-shadow: 0px 36.72655px 133.37538px 0px rgba(224, 223, 233, 0.38);
`

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`

export const ImageContainer = styled.div`
    display: flex;
    gap: 70px;
    align-items: center;
    padding: 0 20px;
`

export const TableWrapper = styled.div`

`

export const Title = styled.h2`
   color: #444;
    font-family: Josefin Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`

export const LegalText = styled.p`
    color: #444;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

export const CloseImg = styled.img`
    width: 12px;
    height: 12px;
`

export const DentalImg = styled.img`
    width: 68px;
    height: 76px;
`

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

// Estilos para el encabezado
export const StyledHeader = styled.thead`
/* background: rgba(124, 149, 177, 0.30); */
color: #5F6267;
font-family: Lato;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
border: 1px solid #8D93A1; 
`;

// Estilos para las filas d

// Estilos para las celdas del cuerpo
export const StyledBodyCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  color: #5F6267;

    /* Small Body */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const TitleTr = styled.tr`
    /* border: 1px solid #8D93A1; */
background: rgba(124, 149, 177, 0.30);
`
export const StyledBodyRow = styled.tr`
  &:nth-last-child(-n + 2) {
    background-color: rgba(124, 149, 177, 0.05);
    color: #5F6267;

    /* Small Body */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

  }
`;
export const TitleCols = styled.tr`
border: 1px solid #8D93A1;
background: rgba(124, 149, 177, 0.05);
`
const CardDescriptionDentalPlan = ({ close }) => {
  return (
    <Wrapper>
        <Frame>
            <TitleWrapper>
                    <TitleContainer>
                        <ImageContainer>
                            <DentalImg src={dentalPlan} alt='dental' />
                            <Title>Plan dental</Title>
                        </ImageContainer>
                    </TitleContainer>
                        <CloseImg src={closeimg} alt='close' onClick={close} />
                </TitleWrapper>
            <TableWrapper>
            <StyledTable>
      <StyledHeader>
        <TitleTr>
          <th colSpan="4">BENEFICIOS PLAN DENTAL</th>
        </TitleTr>
        <TitleCols>
          <th colSpan="2" rowSpan="3">SERVICIO</th>
          <th style={{ width: '200px'}}>TIEMPO DE ESPERA</th>
          <th style={{ width: '200px'}}>APLICA PARA</th>
        </TitleCols>
      </StyledHeader>
      <tbody>
        <StyledBodyRow style={{backgroundColor: 'white'}}>
          <StyledBodyCell rowSpan="3" style={{height: '476px'}}>
          Primer Consulta con un costo de $100.00 (este costo se cubre directamente en consultorio por Titular y/o Beneficiario)
          </StyledBodyCell>
          <StyledBodyCell>
          Examen oral completo (en caso de ser necesario, se tomará una radiografía periapical sin costo para un mejor diagnóstico): 1 evento al año, por integrante.          </StyledBodyCell>
          <StyledBodyCell>Sin periodo de espera</StyledBodyCell>
          <StyledBodyCell>Titular y/o Beneficiaros *</StyledBodyCell>
        </StyledBodyRow>
        <StyledBodyRow style={{backgroundColor: 'white'}}>
          <StyledBodyCell>Limpieza oral preventiva, instrucción e higiene bucal: 1 evento al año por integrante.</StyledBodyCell>
          <StyledBodyCell>Sin periodo de espera</StyledBodyCell>
          <StyledBodyCell>Titular y/o 
Beneficiaros *</StyledBodyCell>
        </StyledBodyRow>
        <StyledBodyRow style={{backgroundColor: 'white'}}>
          <StyledBodyCell>Aplicación tópica de fluoruro, la aplicación del fluoruro será bajo diagnóstico médico: 1 evento al año, sólo aplica para los Beneficiaros que sean menores de edad si el dentista así lo considera necesario.</StyledBodyCell>
          <StyledBodyCell>Sin periodo de espera</StyledBodyCell>
          <StyledBodyCell>Titular y/o Beneficiaros *</StyledBodyCell>
        </StyledBodyRow>
        <StyledBodyRow>
          <StyledBodyCell colSpan="2">Consultas con dentistas de nuestra red y Servicios Dentales a costos preferenciales.</StyledBodyCell>
          <StyledBodyCell>Sin periodo de espera</StyledBodyCell>
          <StyledBodyCell>Titular y/o Beneficiaros *</StyledBodyCell>
        </StyledBodyRow>
        <StyledBodyRow>
          <StyledBodyCell colSpan="2">Pago Directo de Servicios por Accidentes Dentales.</StyledBodyCell>
          <StyledBodyCell>3 meses</StyledBodyCell>
          <StyledBodyCell>Titular</StyledBodyCell>
        </StyledBodyRow>
      </tbody>
    </StyledTable>
            </TableWrapper>
            <LegalText>
            * Los beneficiarios en el caso de un Titular soltero aplicarán como beneficiarios sus padres, de lo contrario para el Titular Casado aplicará para su conyugue e hijos menores de 25 años.
            </LegalText>
        </Frame>
    </Wrapper>
  )
}

export default CardDescriptionDentalPlan