import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  width: 90%;
  height: 50px;
  padding: 20px 20px 20px 20px;
  justify-content: start;
  align-items: center;
  gap: 30px;
  border-radius: 0 0 10px 10px;
  background: #FFF;
  box-shadow: 0px 19px 69px 0px rgba(224, 223, 233, 0.38);
  @media (max-width: 1210px) {
      flex-direction: column;
      height: 100%;
   }
`
export const EditNumCont = styled.div`
  display: flex;
  width: 100%;
  height: 135px;
  align-items: center;
  border-radius: 10px;
  background-color: #FFF;
  @media (max-width: 1210px) {
      flex-direction: column;
      height: 100%;
   }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 28px;
  padding-right: 16px;
  position: relative;
  gap: 16px;
  @media (max-width: 1210px) {
      width: 80%;
  }
`

export const LabelInput = styled.p`
  color: var(--Body, #5F6267);
  /* Desktop/H3 */
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`

export const SelectTel = styled.select`
width: 200px;
height: 54px;
border-radius: 16px;
background: #F3F2F2;
color: #BEBEBE;
/* Common/Tittle */
font-family: Lato;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
border-color: #F3F2F2;
border-style: solid;
padding-left: 20%;
@media (max-width: 1210px) {
    width: 110%;
  }
`

export const ImgSelect = styled.img`
  position: absolute;
  bottom:13%;
  left: 5%;
  width:32px
`

export const InputSelectEdit = styled.img`
  position: absolute;
  bottom:15%;
  left: 5%;
  width: 24px;
`

export const InputSelect = styled.img`
  position: absolute;
  bottom: 25%;
  left: 5%;
  width: 24px;
`

export const InputTel = styled.input`
  width: 100%;
  height: 44px;
  border-radius: 16px;
  padding: 0 10px 0 20px;
  background: #F3F2F2;
  color: #BEBEBE;
  border-color: #F3F2F2;
  border-style: solid;
  /* Common/Tittle */
  font-family: Lato;
  text-align: end;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 1210px) {
    width: 80%;
    text-align: center;
    padding: 0 0 0 40px;
  }
`
export const ButtonSave = styled.button`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  cursor: pointer;
  border-radius: 40px;
  border-color: #00C46B;
  border-style: solid;
  background: var(--Primary, #00C46B);
  color: var(--Background, #F6F6F6);
  text-align: center;

  /* Common/Button */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 1210px) {
      width: 100%;
      padding: 12px;
      justify-content: center;
      align-items: center;
    }
`
export const ButtonCancelar = styled.button`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  cursor: pointer;
  border-radius: 40px;
  border-color: #00C46B;
  border-style: solid;
  background: var(--Primary, #F6F6F6);
  color: var(--Background, #00C46B);
  text-align: center;

  /* Common/Button */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 1210px) {
      width: 100%;
      padding: 12px;
      justify-content: center;
      align-items: center;
    }
`
export const ButtonSaveEdit = styled.button`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  cursor: pointer;
  border-radius: 40px;
  border-color: #00C46B;
  border-style: solid;
  background: var(--Primary, #00C46B);
  color: var(--Background, #F6F6F6);
  text-align: center;

  /* Common/Button */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 1210px) {
      width: 100%;
      padding: 12px;
      justify-content: center;
      align-items: center;
    }
`
export const ButtonCancel = styled.button` //se utiliza en EditarTel
display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  cursor: pointer;
  border-radius: 40px;
  border-color: #00C46B;
  border-style: solid;
  background: var(--Primary, #F6F6F6);
  color: var(--Background, #00C46B);
  text-align: center;

  /* Common/Button */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 1210px) {
      width: 100%;
      padding: 12px;
      justify-content: center;
      align-items: center;
    }
`
export const ButtonContainer = styled.div`
  display: flex;
  margin: 20px;
  width: 100%;
  gap: 10px;
  justify-content: flex-end;
  @media (max-width: 1210px) {
      justify-content: center;
    }
`
export const EditNumBtnCont = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: flex-end;
  margin-top: 40px;
  @media (max-width: 1210px) {
      justify-content: center;
      margin-top: 20px;
    }
`
