import requester from "../Apis/requester"
const formRegisterAnswer = (answer, userResponse, idPacient, onSetSaving) => {
    try {
        requester({
            url: 'api/family-heirs/question',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                answer: userResponse,
                idPacient: idPacient,
                idQuestion: answer.id,
            },
        }).finally(() => {
            onSetSaving(false)
        })
    }
    catch (error) {
        console.error(error)
        onSetSaving(false)
    }
}

export default formRegisterAnswer