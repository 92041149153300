import styled from "styled-components";
import { Box, styled as muiStyled, Typography } from "@mui/material";

export const Card = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '40px 10px 40px 40px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    alignSelf: 'stretch',
    borderRadius: '10px',
    maxWidth: '630px',
    background: '#FFFFFF',
    boxShadow: '0px 19px 69px 0px rgba(224, 223, 233, 0.38)',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
        padding: '20px 24px 20px 24px',
        '&.expand': {
            padding: '32px 24px',
        },
    },
}))

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
`

export const CardHeader = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
}))

export const ServiceFrameText = muiStyled(Typography)(({ theme }) => ({
    color: '#444',
    fontFamily: 'Josefin Sans',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    margin: '0',
    padding: '0',
    [theme.breakpoints.down('md')]: {
        fontSize: '22px',
    },
}))

export const ServiceFrame = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        width: '100%',
    },
}))

export const IconContainer = muiStyled(Box)(({ theme }) => ({
    justifyContent: 'center',
    alignItems: 'center',

    display: 'none',
    [theme.breakpoints.down('md')]: {
        display: 'flex',
    },
}));
