import React from 'react'
import CardsHistorial from '../HistorialServicios/Hero/cards/CardsHistorial'

//Styles
import Maletin from '../../assets/laptop.svg'

//styles
import { ContPerfTel } from '../../Views/LandingPage/MiPerfil/MiPerfil.styled'

export default function CardsPerfTel({ mostrarPerfil, setMostrarPerfil, mostrarTelefono, setMostrarTelefono }) {
  return (
    <ContPerfTel>
      <CardsHistorial
        imgCard={Maletin}
        titulo={"Imagen de perfil"}
        onClick={() => setMostrarPerfil(!mostrarPerfil)}
      />
      <CardsHistorial
        imgCard={Maletin}
        titulo={"Teléfono"}
        onClick={() => setMostrarTelefono(!mostrarTelefono)}
      />

    </ContPerfTel>
  )

  //Contenedor ocultado en estilos con display: none
}
