import { Typography, styled as muiStyled, Box } from "@mui/material";

export const Card = muiStyled(Box)(({ theme }) => ({
    padding: "30px 20px",
    boxSizing: 'border-box',
    height: "305px",
    maxWidth: "804px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: '10px',
    flexShrink: 0,
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    boxShadow: "0px 19px 69px 0px rgba(224, 223, 233, 0.38)",
    [theme.breakpoints.down('lg')]: {
        height: "100%",
    }
}));

export const CountText = muiStyled(Typography)(({ theme }) => ({
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
    color: '#051201',
    fontFamily: "Lato",
    fontSize: '18px',
    fontWeight: '700',
}));

export const Frame = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '37px',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        height: 'auto',
        justifyContent: 'flex-start',
        gap: '32px',
    },
}));

export const ArrowContainer = muiStyled(Box)(({ theme }) => ({
    width: '32px',
    height: '32px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const Wrapper = muiStyled(Box)(({ theme }) => ({
    height: "auto",
    width: '100%',
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
}));