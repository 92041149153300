import React from 'react'
import user from "../../assets/user.svg";
import calendar from "../../assets/calendar.svg";
import {
    InputField,
    ContainerInput,
    InputIcon,
    DateText,
    DateContainer,
} from "./styles";

const LoginWithCollaboratorNumber = ({
    numberUser,
    onSetNumberUser,
    setOpenCalendar,
    computeCalendarDaySelected
}) => {
    return (
        <>
            <ContainerInput>
                <InputIcon src={user} alt="" />
                <InputField
                    type="text"
                    value={numberUser}
                    onChange={onSetNumberUser}
                    placeholder="Número de colaborador"
                />
            </ContainerInput>
            <DateContainer onClick={() => setOpenCalendar(true)}>
                <InputIcon src={calendar} alt="" />
                <DateText color={!computeCalendarDaySelected ? "#abb3bb" : "#07122f"}>
                    {computeCalendarDaySelected || "Fecha de nacimiento"}
                </DateText>
            </DateContainer>
        </>
    )
}

export default LoginWithCollaboratorNumber