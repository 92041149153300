import React from 'react'
import CardDescriptionBenefitWithSteps from './CardDescriptionBenefitWithSteps'
import corazon from '../assets/corazon.svg'
import info from '../assets/info.svg'
import { ExtraInfoCardPopUp } from './ConoceBeneficios/styles'
import styled from "styled-components";

export const InfoContainer = styled.div`
    display: flex;
    gap:8px;
    align-items: center;
`

export const TitleInfo = styled.p`
    color: var(--Text-Titulo, #051201);
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
`

export const InfoText = styled.p`
    color: var(--Body, #5F6267);
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin:5px;
`

const CardDescriptionDoctorAtHome = ({ onClose }) => {
    return (
        <CardDescriptionBenefitWithSteps
            image={corazon}
            title='Médico a domicilio'
            textStep1='Comunícate al número telefónico y presiona la opción 1'
            textStep2='Otorga tu Número de Colaborador al operador cuando te sea requerido.'
            textStep3='Continúa bajo las indicaciones del operador telefónico.'
            footerTextStep1='01 800 020 4050'
            footerTextStep2='[Número Colaborador]'
            footerTextStep3=''
            renderExtraInfo={
                <ExtraInfoCardPopUp>
                    <>
                        <InfoContainer>
                            <img src={info} alt='info' />
                            <TitleInfo>¿Quiénes pueden solicitarlo? </TitleInfo>
                        </InfoContainer>
                        <InfoText>El titular de la cuenta y sus beneficiarios.</InfoText>
                    </>
                    <div>
                        <InfoContainer>
                            <img src={info} alt='info' />
                            <TitleInfo>Horarios</TitleInfo>
                        </InfoContainer>
                        <InfoText>Las 24 horas del día, los 365 días del año.</InfoText>
                    </div>
                </ExtraInfoCardPopUp>
            }
            onClose={onClose}
        />
    )
}

export default CardDescriptionDoctorAtHome