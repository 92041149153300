import React, { useState } from "react";
import {
  MainContainer,
  MainTitleTop,
  MainTitleBottom,
  Content,
  Row,
  CardPopUpWrapper,
  CardsContent,
  TitleContainer,
} from "./styles";
import CardBeneficios from "./CardBeneficios/CardBeneficios";
import CardServiciosTeleconsulta from "./CardServicios/CardServiciosTeleconsulta";
import CardServiciosConcentracionCitas from "./CardServicios/CardServiciosConcentracionCitas";
import CardServiciosAmbulancia from "./CardServicios/CardServiciosAmbulancia";
import CardServiciosOrientaciones from "./CardServicios/CardServiciosOrientaciones";
import CardServiciosDeteccion from "../CardServiciosDeteccion";
import CardDescriptionCheckUp from "../CardDescriptionCheckUp";
import CardDescriptionGridDiscount from "../CardDescriptionGridDiscount";
import CardDescriptionDoctorAtHome from "../CardDescriptionDoctorAtHome";
import CardDescriptionDentalPlan from "../CardDescriptionDentalPlan";
import CardDescriptionPayServices from "../CardDescriptionPayServices";
import computeCardOrderBenefitsList from '../../helpers/computeCardOrderBenefitsList'
import { Element } from 'react-scroll';
import { useMediaQuery, useTheme, Box } from '@mui/material';
import BenefitCardsMobil from "./BenefitCardsMobil";
import capitalizeFirstLetter from "../../helpers/capitalizeFirstLetter";

const ConoceBeneficios = ({ data }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const computeMaxItemPerRow = useMediaQuery(theme.breakpoints.down('lg')) ? 3 : 4
  const [cardSelected, setCardSelected] = useState("");
  const [isShowRowFrameCardPopUp, setIsShowRowFrameCardPopUp] = useState(null)

  const filas = computeCardOrderBenefitsList(data.cards, computeMaxItemPerRow);

  const handleClickCard = (cardPopUpName, idRowCardPopUp) => {
    if (cardPopUpName === cardSelected) {
      setCardSelected("");
      setIsShowRowFrameCardPopUp(null)
      return;
    }
    setCardSelected(cardPopUpName);
    setIsShowRowFrameCardPopUp(idRowCardPopUp)
  }

  const handleCloseCardPopUp = () => {
    setCardSelected("");
    setIsShowRowFrameCardPopUp(null)
  }

  let selectedPopUpComponent;

  switch (cardSelected) {
    case "ambulancia":
      selectedPopUpComponent = <CardServiciosAmbulancia onClose={handleCloseCardPopUp} />;
      break;
    case "orientacionesMedicas":
      selectedPopUpComponent = <CardServiciosOrientaciones onClose={handleCloseCardPopUp} />;
      break;
    case "Detección oportuna de riesgos":
      selectedPopUpComponent = <CardServiciosDeteccion onClose={handleCloseCardPopUp} />;
      break;
    case "checkup":
      selectedPopUpComponent = <CardDescriptionCheckUp onClose={handleCloseCardPopUp} />;
      break;
    case "teleconsulta":
      selectedPopUpComponent = <CardServiciosTeleconsulta onClose={handleCloseCardPopUp} />;
      break;
    case "concertacion":
      selectedPopUpComponent = <CardServiciosConcentracionCitas onClose={handleCloseCardPopUp} />;
      break;
    case "pagoDirectoServicios":
      selectedPopUpComponent = <CardDescriptionPayServices onClose={handleCloseCardPopUp} />;
      break;
    case "planDental":
      selectedPopUpComponent = <CardDescriptionDentalPlan onClose={handleCloseCardPopUp} />;
      break;
    case "redDescuentos":
      selectedPopUpComponent = <CardDescriptionGridDiscount onClose={handleCloseCardPopUp} />;
      break;
    case "medicoFamiliar":
      selectedPopUpComponent = <CardDescriptionDoctorAtHome onClose={handleCloseCardPopUp} />;
      break;
    default:
      selectedPopUpComponent = null;
  }

  return (
    <Element name="ConoceBeneficios">
      <MainContainer>
        <TitleContainer>
          <MainTitleTop>Conoce y aprovecha </MainTitleTop>
          <MainTitleBottom>todos tus beneficios</MainTitleBottom>
        </TitleContainer>
        {isMobile ?
          (
            <Box gap="8px" marginBottom="20px" display="flex" flexDirection="column">
              <BenefitCardsMobil
                cards={data.cards}
              />
            </Box>
          )
          : (
            <Content>
              {filas.map((cards, indexRow) => (
                <CardsContent key={indexRow}>
                  <Row>
                    {cards.map((card, i) => (
                      <CardBeneficios
                        key={i}
                        image={card.icono}
                        title={capitalizeFirstLetter(card.titulo)}
                        subtitle={card.descripcion}
                        textButton={capitalizeFirstLetter(card.tituloboton)}
                        cardSelected={cardSelected === card.nombreCardPopUp}
                        colorTheme={card.colorBoton}
                        onClick={() => handleClickCard(card.nombreCardPopUp, indexRow)}
                      />
                    ))}
                  </Row>
                  {isShowRowFrameCardPopUp === indexRow && (
                    <CardPopUpWrapper key={indexRow}>
                      {selectedPopUpComponent}
                    </CardPopUpWrapper>
                  )}
                </CardsContent>
              ))}
            </Content>

          )}
      </MainContainer>
    </Element>
  );
}

export default ConoceBeneficios;