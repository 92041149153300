import React from 'react'
import {
  Wrapper,
  Content,
  Title,
  SubTitle,
  Frame,
} from './style'

const CardBaseForm = ({ children, title, subTitle }) => {
  return (
    <Wrapper>
      <Frame>
        <Title>{title}</Title>
        {subTitle && (
          <SubTitle>{subTitle}</SubTitle>
        )}
        <Content>
          {children}
        </Content>
      </Frame>
    </Wrapper>
  )
}

export default CardBaseForm
