import React, { useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import trash from "../../assets/trashRed.svg";
import CardDocumentSection from "../CardDocumentSection";
import CardFileRecord from "../CardFileRecord";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
    Wrapper,
    FileFrame,
    ToolsBar,
    RightToolsBar,
    SearchFrame,
    SearchInput,
    ActionButtonContainer,
    ActionsContainer,
    TextActionButton,
    SectionsListWrapper,
    FilesListWrapper,
    ButtonContainer,
    Button,
} from "./styled";

const RecordPortable = () => {
    const [typeDocumentsToShow, setTypeDocumentsToShow] = useState(null)

    return (
        <Wrapper>
            <FileFrame>
                <ToolsBar>
                    <RightToolsBar>
                        {typeDocumentsToShow && (
                            <ArrowBackIosIcon
                                htmlColor="#104C7C"
                                sx={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => setTypeDocumentsToShow(null)}
                            />
                        )}
                        <SearchFrame>
                            <SearchOutlinedIcon sx={{ width: "32px", height: "32px" }} />
                            <SearchInput placeholder="Buscar archivo" />
                        </SearchFrame>
                    </RightToolsBar>
                    <ActionsContainer>
                        <ActionButtonContainer>
                            <FileDownloadOutlinedIcon
                                htmlColor="#104C7C"
                                sx={{
                                    width: "30px",
                                    height: "30px",
                                }}
                            />
                            <TextActionButton color="#104C7C">Descargar</TextActionButton>
                        </ActionButtonContainer>
                        <ActionButtonContainer>
                            <CachedOutlinedIcon
                                htmlColor="#104C7C"
                                sx={{
                                    width: "30px",
                                    height: "30px",
                                }}
                            />
                            <TextActionButton color="#104C7C">Actualizar</TextActionButton>
                        </ActionButtonContainer>
                        <ActionButtonContainer>
                            <img src={trash} alt="delete" height={30} width={30} />
                            <TextActionButton color="#D80C0C">Borrar</TextActionButton>
                        </ActionButtonContainer>
                    </ActionsContainer>
                </ToolsBar>
                {!typeDocumentsToShow && (
                    <SectionsListWrapper>
                        <CardDocumentSection
                            name="Mis documentos"
                            onClick={() => setTypeDocumentsToShow(1)}
                        />
                        <CardDocumentSection
                            name="Mis estudios"
                            onClick={() => setTypeDocumentsToShow(2)}
                        />
                        <CardDocumentSection
                            name="Mis recetas"
                            onClick={() => setTypeDocumentsToShow(3)}
                        />
                    </SectionsListWrapper>
                )}
                {typeDocumentsToShow && (
                    <FilesListWrapper>
                        <CardFileRecord
                            name="Privacidad"
                        />
                        <CardFileRecord
                            name="Estudio"
                        />
                    </FilesListWrapper>
                )}
            </FileFrame>
            <ButtonContainer>
                <Button>
                    Cargar archivo
                </Button>
            </ButtonContainer>
        </Wrapper>
    );
};

export default RecordPortable;
