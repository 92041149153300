import React from 'react'
import {
  Frame,
  ContentWrapper,
  Content,
  ContentInfo,
  Title,
  TitleContainer,
  BrandName,
  BrandDescription,
  ContentImg,
  PrincipalImg,
  ButtonFrame,
  Button,
} from './styles'
import CardPrincipalServices from '../CardPrincipalServices';
import DisplayAuth from '../DisplayAuth/DisplayAuth'
import getPricipalServices from '../../helpers/getPricipalServices'
import { Link } from 'react-scroll'
import { useMediaQuery, useTheme } from '@mui/material';

const Principal = ({ data }) => {
  const principalServicesArray = getPricipalServices(data.cards)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Frame>
      <ContentWrapper>
        {!isMobile && (
          <DisplayAuth />
        )}
        <Content>
          <ContentInfo>
            {isMobile && (
              <DisplayAuth />
            )}
            <TitleContainer>
              <Title>Plan de Salud</Title>
              <BrandName variant="h3">{data.nombreMarca}</BrandName>
              <BrandDescription>Disfruta los beneficios que {data.nombreMarca} tiene para ti.</BrandDescription>
            </TitleContainer>
            <CardPrincipalServices pricipalServices={principalServicesArray} />
            <ButtonFrame>
              <Link to="ConoceBeneficios" smooth={true} duration={500} style={{ width: "inherit" }}>
                <Button>
                  Ver todos mis servicios
                </Button>
              </Link>
            </ButtonFrame>
          </ContentInfo>
          <ContentImg>
            <PrincipalImg src={data.urlBanner} alt='principal-img' />
          </ContentImg>
        </Content>
      </ContentWrapper>
    </Frame>
  )
}

export default Principal
