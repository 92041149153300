const splitInTwoArrays = (array) => {
    if (array.length === 0) {
        return { firstHalf: [], secondHalf: [] };
    }
    const middleIndex = Math.ceil(array.length / 2);
    const firstHalf = array.slice(0, middleIndex);
    const secondHalf = array.slice(middleIndex);

    return { firstHalf, secondHalf };
}

export default splitInTwoArrays;
