import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginChecker = ({ element }) => {
  console.log('el login')
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId')


  useEffect(() => {
    if (userId) {
      navigate('/lp')
    }
  }, [navigate, userId]);

  return element
}

export default LoginChecker