import styled from "styled-components";

export const ContPerfTel = styled.div`
    display: none;
    justify-content: center;
    align-items: center;
    background: var(--default-white, #FFF);
    gap: 16px;
    margin: 32px;
    @media (max-width: 1210px) {
        flex-direction: column;
        margin-top: 100px;
    }
`

export const MainContainerPerfil = styled.div`
    width: 90%; 
    margin: 0 auto ; 
    @media (max-width: 1210px) {
        width: 100%
    }
`