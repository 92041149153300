import styled from "styled-components";
import { styled as muiStyled, Box } from "@mui/material";

export const MainContainer = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    width: '204px',
    height: 'auto',
    padding: '32px 20px 30px 20px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
    flexShrink: '0',
    borderRadius: '10px',
    background: '#FFF',
    boxShadow: '0px 19px 69px 0px rgba(224, 223, 233, 0.38)',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '32px',
    }
}))

export const MainContainerDeteccion = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 20px 30px 20px',
    borderRadius: '10px',
    background: '#FFF',
    gap: '24px',
    // width: '640px',
    [theme.breakpoints.down('md')]: {
        padding: '9px',
    }
}))

export const EvaluationText = styled.p`
color: var(--Body, #5F6267);
text-align: center;
margin: 0;

/* H4 */
font-family: Josefin Sans;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
`

export const RepetirEvalText = styled.p`
    color: var(--Primary, #00C46B);
    text-align: center;
    font-family: Josefin Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    cursor: pointer;
`

export const VerEvalText = styled.p`
    color: var(--Body, #5F6267);
    text-align: center;
    margin: 0;

    /* H4 */
    font-family: Josefin Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`

export const CardTitle = styled.h2`
    color: #444;
    text-align: center;

    /* H3 */
    font-family: Josefin Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
`

export const SubtitleCard = styled.p`
    color: #6C81A0;
    text-align: center;
    /* Legal */
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
`

export const PhoneText = styled.p`
    color: #444;
    text-align: center;
    font-family: Josefin Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    margin: 0;
`
export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap:8px;
`

export const InnerContainer = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    width: '600px',
    justifyContent: 'space-between',
    gap: '138px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: '24px',
        width: '100%',
        alignItems: 'flex-start',
    }
}))

export const TeleconsultaButton = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    width: '275px',
    height: '150px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: '0',
    borderRadius: '10px',
    background: '#00c46b',
    [theme.breakpoints.down('md')]: {
        width: '235px',
    },
}))
export const ButtonContainer = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '188px',
    [theme.breakpoints.down('md')]: {
        width: '100%',
    }
}))
export const Button = styled.button`
    display: flex;
    width: 100%;
    padding: 16px 24px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    border-color: #104C7C;
    border-width: 1px;
    border-style: solid;
    color: ${props => props.color};
    text-align: center;
    cursor: pointer;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background-color: ${props => props.bgColor};
`

export const ExpandContainer = styled.div`
    gap: 1px;
    display: flex;
    width: auto;
    align-items: center;
    width: fit-content;
    cursor: pointer;
`
