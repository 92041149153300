import React, { useState} from 'react'
import ambulancia from '../assets/ambulalancia.svg'
import closeimg from '../assets/Close.svg'
import PayServicesTable from './PayServicesTable';
import CardPayServiceFormat from './CardPayServiceFormat';
import CardPayServiceHistory from './CardPayServiceHistory';
import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 0 20px;
`

export const Frame = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 1000px;
    height: auto;
    max-width: 1150px;
    gap: 10px; 
    padding: 32px; 
    border-radius: 10px;
    background-color: #F6F6F6;
    box-shadow: 0px 36.72655px 133.37538px 0px rgba(224, 223, 233, 0.38);
`

export const TitleWrapper = styled.div`
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
`

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`

export const ImageContainer = styled.div`
    display: flex;
    gap: 70px;
    align-items: center;
    padding: 0 20px;
    width: 100%;
`

export const TableWrapper = styled.div`

`

export const Title = styled.h2`
   color: #444;
    font-family: Josefin Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`

export const LegalText = styled.p`
    color: #444;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

export const CloseImg = styled.img`
    width: 12px;
    height: 12px;
`

export const DentalImg = styled.img`
    width: 68px;
    height: 76px;
`

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

// Estilos para el encabezado
export const StyledHeader = styled.thead`
/* background: rgba(124, 149, 177, 0.30); */
color: #5F6267;
font-family: Lato;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
border: 1px solid #8D93A1; 
`;

// Estilos para las filas d

// Estilos para las celdas del cuerpo
export const StyledBodyCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  color: #5F6267;

    /* Small Body */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const TitleTr = styled.tr`
    /* border: 1px solid #8D93A1; */
background: rgba(124, 149, 177, 0.30);
`
export const StyledBodyRow = styled.tr`
  &:nth-last-child(-n + 2) {
    background-color: rgba(124, 149, 177, 0.05);
    color: #5F6267;

    /* Small Body */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

  }
`;
export const TitleCols = styled.tr`
border: 1px solid #8D93A1;
background: rgba(124, 149, 177, 0.05);
`

export const TitleWithMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const MenuContainer = styled.div`
    display: flex;
    width: 100%;
`

export const ItemMenu = styled.div`
    display: flex;
    justify-content: center;
    /* border: 1px solid  #00C46B; */
    border-radius: ${(props) => props.borderRadius};
    border-color: ${(props) => props.bgColor};
    border-style: solid;
    background: #FFF;
    box-shadow: 0px 19px 69px 0px rgba(224, 223, 233, 0.38);
    padding: 8px;
    width: 100%;
    cursor: pointer;
`

const CardDescriptionPayServices = ({ close }) => {
    const [isActive, setIsActive] = useState(0)

  return (
    <Wrapper>
        <Frame>
        <TitleWrapper>
            <TitleContainer>
                <ImageContainer>
                    <DentalImg src={ambulancia} alt='cost' />
                    <TitleWithMenuContainer>
                    <Title>Pago directo de servicios</Title>
                    <MenuContainer>
                        <ItemMenu
                            borderRadius="10px 0px 0px 10px"
                            bgColor={isActive === 0 ? '#00C46B' : 'rgba(95, 98, 103, 0.30)'}
                            onClick={() => setIsActive(0)}
                            >
                                Beneficios
                        </ItemMenu>
                        <ItemMenu
                        bgColor={isActive === 1 ? '#00C46B' : 'rgba(95, 98, 103, 0.30)'}
                        onClick={() => setIsActive(1)}
                        >
                            Formatos
                        </ItemMenu>
                        <ItemMenu
                            borderRadius="0px 10px 10px 0px"
                            bgColor={isActive === 2 ? '#00C46B' : 'rgba(95, 98, 103, 0.30)'}
                            onClick={() => setIsActive(2)}
                        >
                            Historial
                        </ItemMenu>
                    </MenuContainer>
                    </TitleWithMenuContainer>
                </ImageContainer>
            </TitleContainer>
                <CloseImg src={closeimg} alt='close' onClick={close} />
        </TitleWrapper>
       {isActive === 0 && (
         <>
         <TableWrapper>
         <PayServicesTable />
        </TableWrapper>
            <LegalText>
            * Los beneficiarios en el caso de un titular soltero aplicarán como beneficiarios sus padres, de lo contrario para el titular casado aplicará para su conyugue e hijos menores de 25 años.
            </LegalText>
     </>
     )}
     {isActive === 1 && (
         <CardPayServiceFormat />
     )}
     {isActive === 2 && (
         <CardPayServiceHistory />
     )}
        </Frame>
    </Wrapper>
  )
}

export default CardDescriptionPayServices