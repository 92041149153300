import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { removeUser } from '../redux/features/user/'

const useAuthUserLogout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(removeUser())
        localStorage.removeItem("userId");
        localStorage.removeItem("userName")
        navigate("/")
    };

    return logout
};

export default useAuthUserLogout