import { createSlice } from '@reduxjs/toolkit'
import { clearLocalStorage, persistLocalStorage } from '../../../helpers/localStorage'

const authKey = 'user'
export const userSlicer = createSlice({
    name: 'user',
    initialState: {
        user: localStorage.getItem(authKey)
            ? JSON.parse(localStorage.getItem(authKey))
            : null,
        userImage: null,
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
            persistLocalStorage(authKey, state.user)
        },
        removeUser: (state) => {
            state.user = null
            clearLocalStorage(authKey)
        },
        setPhone: (state, action) => {
            state.user.phone = action.payload
            persistLocalStorage(authKey, state.user)
        },
        setUserImage: (state, action) => {
            state.user.photo = action.payload
            persistLocalStorage(authKey, state.user)
        },
        setNewEmail: (state, action) => {
            state.user.email = action.payload
            persistLocalStorage(authKey, state.user)
        },
    },
})

// Action creators are generated for each case reducer function
export const { removeUser, setUser, setUserImage, setPhone, setNewEmail } = userSlicer.actions
export const selectUser = (state) => state.userReducer.user
export const userImage = (state) => state.userReducer.user.photo
export const selectIdUser = (state) => state.userReducer.user.idUser

export default userSlicer.reducer