import React, { useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import QuestionOrAnswer from '../QuestionOrAnswer/QuestionOrAnswer';
import { Wrapper, CountText, ArrowContainer, Frame, Card } from './styles';
import { Box } from '@mui/material';
import formRegisterAnswer from '../../shared/formRegisterAnswer';

const CarrouselQuestions = ({ questions, idPacient, saving, onSetSaving }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const components = [];

    for (let i = 0; i < questions.length; i += 4) {
        components.push(questions.slice(i, i + 4));
    }
    const handleNext = () => {
        setCurrentIndex((currentIndex + 1) % components.length);
    };

    const handlePrev = () => {
        setCurrentIndex((currentIndex - 1 + components.length) % components.length);
    };

    const saveResponse = (answer, userResponse) => {
        formRegisterAnswer(answer, userResponse, idPacient, onSetSaving)
    }

    return (
        <Wrapper>
            <CountText
                variant="h6"
                display={{ xs: 'none', sm: 'none', md: 'flex' }}
            >
                {currentIndex + 1} / {components.length}
            </CountText>
            <Frame>
                <ArrowContainer display={{ xs: 'none', sm: 'none', md: 'flex' }}>
                    {currentIndex > 0 && (
                        <KeyboardArrowLeftIcon htmlColor='#00C46B' onClick={handlePrev} />
                    )}
                </ArrowContainer>
                <Card>
                    {components[currentIndex].map((question) => (
                        <QuestionOrAnswer
                            key={question.id}
                            answer={question}
                            saving={saving}
                            onSaveResponse={saveResponse}
                            onSetSaving={onSetSaving}
                        />
                    ))}
                </Card>
                <Box display="flex">
                    <ArrowContainer display={{ sm: 'flex', md: 'none' }}>
                        {currentIndex > 0 && (
                            <KeyboardArrowLeftIcon htmlColor='#00C46B' onClick={handlePrev} />
                        )}
                    </ArrowContainer>
                    <CountText display={{ sm: 'flex', md: 'none' }} variant="h6">{currentIndex + 1} / {components.length}</CountText>
                    <ArrowContainer>
                        {currentIndex < components.length - 1 && (
                            <KeyboardArrowRightIcon htmlColor='#00C46B'
                                onClick={handleNext}
                            />
                        )}
                    </ArrowContainer>
                </Box>
            </Frame>
        </Wrapper>

    );
};

export default CarrouselQuestions;