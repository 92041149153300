import { Typography, styled as muiStyled, Box } from "@mui/material";

export const Wrapper = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    alignSelf: 'stretch',
    padding: '16px 60px',
    backgroundColor: '#F6F6F6',
    height: "auto",
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '0px 2px',
        boxSizing: 'border-box',
        backgroundColor: '#FFFFFF',
    }
}));

export const Frame = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: '1 0 0',
    alignSelf: 'stretch',
    padding: '32px 56px',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '0px 36.727px 133.375px 0px rgba(224, 223, 233, 0.38)',
    gap: '16px',
    height: "auto",
    [theme.breakpoints.down('md')]: {
        padding: '32px 16px',
        flex: 'none'
    }
}));

export const Content = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '37px',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
}));

export const Title = muiStyled(Typography)(({ theme }) => ({
    color: '#444444',
    fontFamily: 'Josefin Sans',
    fontSize: '32px',
    fontWeight: '700',
    [theme.breakpoints.down('md')]: {
        fontSize: '30px',
    },
}));

export const SubTitle = muiStyled(Typography)(({ theme }) => ({
    color: '#444444',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: '700',
}));

