import React from 'react'
import Modal from '@mui/material/Modal';
import EmailInput from '../../EmailInput';
import validateEmail from '../../../helpers/validateEmail';
import {
    Content,
    Text,
    ConfirmButton,
    CancelButton,
    ButtonsContainer,
} from './styles'
import requester from '../../../Apis/requester';
import { useDispatch } from 'react-redux';
import { setNewEmail } from '../../../redux/features/user';

const AddEmailModal = ({
    open,
    email,
    confirmEmail,
    onChangeEmail,
    onChangeConfirmEmail,
    handleClose,
    getuser,
    idUser
}) => {

    const dispatch = useDispatch()

    const disabledButton = (() => {
        return !email || !confirmEmail || email !== confirmEmail || !validateEmail(email)
    })()

    const handleContinue = async () => {
        const response = await requester({
            url: '/api/user/updateEmail',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: { email: email, idUser },
        })
        const data = await response;
        if (data.success) {
            await dispatch(setNewEmail(email))
            handleClose()
            getuser()
        }
        else alert('Error al actualizar el correo electrónico')
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus
            disableRestoreFocus
            disableAutoFocus
        >
            <Content>
                <Text>
                    Necesitas contar con una dirección de correo electrónico registrada; ingrésala para continuar proseguir
                </Text>
                <EmailInput
                    placeholder="Correo electrónico"
                    value={email}
                    onChange={onChangeEmail} />
                <EmailInput
                    placeholder="Validación correo electrónico"
                    value={confirmEmail}
                    onChange={onChangeConfirmEmail}
                />
                <ButtonsContainer>
                    <CancelButton onClick={handleClose}>
                        Cancelar
                    </CancelButton>
                    <ConfirmButton
                        disabled={disabledButton}
                        onClick={handleContinue}
                    >
                        Continuar
                    </ConfirmButton>
                </ButtonsContainer>
            </Content>
        </Modal>
    )
}

export default AddEmailModal
