import styled from "styled-components";

export const MainContainer = styled.div`
    display: flex;
    width: 100%;
    height: 200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    @media (max-width: 1210px) {
        margin-top:100px;
        background-color: none;
        margin-bottom: 32px;
    }
`
export const ImgText = styled.div`
    display: flex;
    width: 80%;
    height: 100px;
    padding: 32px 20px 30px 20px;

    align-items: center;
    justify-content: space-evenly;
    gap: 24px;
    flex-shrink: 0;
    border-radius: 0px 0px 10px 10px;
    background: #FFF;
    box-shadow: 0px 19px 69px 0px rgba(224, 223, 233, 0.38);
    @media (max-width: 1210px) {
        flex-direction: column;
        width: 90%;
        height:190%;
        padding: 24px;
        background: #f6f6f6;
        gap: 0;
    }
`
export const ContBotones = styled.div`
    width: auto;
    display: flex;
    gap: 10px;
    @media (max-width: 1210px) {
        flex-direction: column;
        width: 100%;
    }

`
export const ContImgText = styled.div`
    display:flex;
    align-items: center;
    gap: 24px;
`

export const PerfTextArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`
export const CardText = styled.p`
    color: #6C81A0;
    margin: 0;

    /* Common/Tittle */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`
export const ClienTextArea = styled.div`
    margin-top: 20px;
`
export const ModPerfBtn = styled.button`
    display: flex;
    width: 220px;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    background: var(--Primary, #104C7C);
    border: none;
    cursor: pointer;    

    color: var(--Background, #F6F6F6);
    text-align: center;
    /* Common/Button */
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 1210px) {
        width: 100%;
        justify-content: center;
        font-size: 22px;
    }

`
