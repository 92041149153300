import React, { useRef } from 'react'
import Circulo from '../../assets/Circle.svg';
import { selectIdUser, setUserImage, userImage } from '../../redux/features/user';
import requester from '../../Apis/requester';
import { useDispatch, useSelector } from 'react-redux';

//styles
import { MainContainer, ImgText, PerfTextArea, ModPerfBtn, ClienTextArea, CardText, ContImgText, ContBotones } from './CardPerfilUsuario.styled'

export default function CardPerfilUsuario({ mostrarPerfil, setMostrarPerfil, user }) {
    const { name, fathersLastName, mothersLastName, idPacient, idColaborador } = user
    const idUser = useSelector(selectIdUser)
    const dispatch = useDispatch()
    const fileInputRef = useRef(null);


    const userImg = useSelector(userImage)

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (await isValidImage(file)) {

            const formData = new FormData();
            formData.append('file', file);
            formData.append('idUser', idUser);

            const response = await requester({
                url: '/api/update/image-profile',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: formData,
            })
            const data = await response;
            console.log(data)


            const reader = new FileReader();
            reader.onloadend = () => {
                dispatch(setUserImage(reader.result))

            };
            reader.readAsDataURL(file);

            dispatch(setUserImage(data.photo))

        } else {
            // Restablecer la selección si la imagen no cumple con los requisitos
            dispatch(setUserImage(null))

            alert('Por favor, selecciona una imagen válida (JPEG o PNG) con un tamaño máximo de 150x150 px.');
        }
    };

    const isValidImage = async (file) => {
        const allowedTypes = ['image/jpeg', 'image/png'];

        if (file && allowedTypes.includes(file.type)) {
            return new Promise((resolve) => {
                const img = new Image();
                img.onload = () => {
                    resolve(true);
                };

                img.onerror = () => {
                    resolve(false);
                };

                img.src = URL.createObjectURL(file);
            });
        }

        return false;
    };

    const handleSelImgBtnClick = () => {
        // Abre el explorador de archivos al hacer clic en el botón
        fileInputRef.current.click();

    };

    return (
        <MainContainer>
            <ImgText>
                <ContImgText>
                    <img src={userImg ?? Circulo} alt='profileImage' style={{
                        width: '120px',
                        height: '120px',
                        borderRadius: '999%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                    }} />
                    <PerfTextArea>
                        <CardText>{`${name} ${fathersLastName} ${mothersLastName}`}</CardText>
                        <ClienTextArea>
                            <CardText>Numero de cliente:</CardText>
                            <CardText>{`${(process.env.REACT_APP_USER_SESSION === '1') ? idColaborador : idPacient}`}</CardText>
                        </ClienTextArea>
                    </PerfTextArea>
                </ContImgText>

                <ContBotones>
                    <ModPerfBtn htmlFor="fileInput" onClick={handleSelImgBtnClick}>Modificar foto de perfil</ModPerfBtn>
                    <input
                        ref={fileInputRef}
                        type="file"
                        id="fileInput"
                        accept="image/jpeg, image/png" // Solo acepta archivos JPG y PNG
                        style={{ display: 'none' }}
                        onChange={handleFileChange} />
                </ContBotones>

            </ImgText>
        </MainContainer>

    )
}
