import React, { useState } from 'react'
import requester from '../../Apis/requester'
import avatarreview from '../../assets/Images/avatar_commentery.png'
import StarRating from '../StarRating'
import {
    Frame,
    Title,
    Wrapper, Card,
    CardFrame,
    TitleWrapper,
    AvatarReview,
    TitleContainer,
    DoctorName,
    ServiceName,
    Description,
    InputWrapper,
    InputContainer,
    StyledInput,
    SendButton,
    ButtonContainer,
} from './style'

const ReviewLastService = ({
    lastReviewServiceId,
    idDatAppointment,
    idPacient,
    doctorName,
    serviceName,
    onGetLastServiceReview,
}) => {
    const [isSendReview, setIsSendReview] = useState(false)
    const [comment, setComment] = useState('')
    const [valueRating, setValueRating] = useState(Array.from({ length: 5 }, () => false));
    const scoreValue = valueRating.lastIndexOf(true) + 1;

    const SendReview = async () => {
        setIsSendReview(true)
        try {
            await requester({
                url: `/api/reviews`,
                method: 'POST',
                data: {
                    id: lastReviewServiceId,
                    idAppointment: idDatAppointment,
                    comment,
                    idPatient: idPacient,
                    score: scoreValue,
                    scoreMessage: 'bueno',
                    tipo: 'last-appointment',
                    title: 'review',
                }
            })
        } catch (error) {
            console.log('error', error)
        }
        setIsSendReview(false)
        onGetLastServiceReview()
        setComment('')
        setValueRating(Array.from({ length: 5 }, () => false))
    }

    const isBtnDisabled = isSendReview || scoreValue === 0 || comment.length === 0

    return (
        <Wrapper>
            <Frame>
                <Title variant="h2">Evalua tu último servicio</Title>
                <Card>
                    <CardFrame>
                        <TitleWrapper>
                            <AvatarReview src={avatarreview} alt='avatar-review' />
                            <TitleContainer>
                                <DoctorName>{doctorName}</DoctorName>
                                <ServiceName>{serviceName}</ServiceName>
                            </TitleContainer>
                        </TitleWrapper>
                        <Description>Nos interesa mucho tu opinión, cuéntanos, ¿cómo te atendieron?</Description>
                        <StarRating
                            valueRating={valueRating}
                            disabled={isSendReview}
                            onSetValueRating={setValueRating}
                        />
                        <InputWrapper>
                            <InputContainer>
                                <StyledInput
                                    type="text"
                                    value={comment}
                                    disabled={isSendReview}
                                    placeholder="Por favor, compártenos algún comentario (máximo 200 caracteres)"
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </InputContainer>
                            <ButtonContainer>
                                <SendButton
                                    disableRipple
                                    disabled={isBtnDisabled}
                                    sx={{
                                        backgroundColor: isBtnDisabled ? '#5F6267' : '#00C46B',
                                        textTransform: 'none',
                                        color: '#ffffff',
                                        '&:hover': {
                                            backgroundColor: isBtnDisabled ? '#5F6267' : '#00C46B',
                                            color: '#fff',
                                        },
                                        '&:disabled': {
                                            color: '#fff',
                                            cursor: 'not-allowed',
                                            pointerEvents: 'all',
                                        },
                                    }}
                                    onClick={SendReview}
                                >
                                    Evaluar
                                </SendButton>
                            </ButtonContainer>
                        </InputWrapper>
                    </CardFrame>
                </Card>
            </Frame>
        </Wrapper>
    )
}

export default ReviewLastService