import { styled as muiStyled, Box } from "@mui/material";

export const Wrapper = muiStyled(Box)(({ theme }) => ({
    padding: "30px 20px",
    boxSizing: 'border-box',
    height: "305px",
    maxWidth: "804px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: '10px',
    flexShrink: 0,
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    boxShadow: "0px 19px 69px 0px rgba(224, 223, 233, 0.38)",
    [theme.breakpoints.down('lg')]: {
        height: "100%",
    },
    [theme.breakpoints.down('md')]: {
        height: "auto",
        padding: "30px 10px",
        width: "100%",
    },
}));