import styled from "styled-components";
import { Box, Typography, styled as muiStyled } from "@mui/material";

export const Frame = muiStyled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    background: "#FFFFFF",
    boxSizing: "border-box",
    gap: "30px",
    height: "63px",
    padding: "0 24px",
}));

export const ContentWrapper = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '60px',
}))

export const Logo = styled.img`
    width: 339px;
    height: 63px;
    min-width: 200px;
    cursor: pointer;
    @media (max-width: 1210px) {
        width:177px
    }
`

export const ItemMenu = muiStyled(Typography)(({ theme }) => ({
    color: '#6C81A0',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    cursor: 'pointer',
    margin: '0',
    padding: '0',
    width: 'auto',
    textWrap: 'nowrap',
}))

export const MenuContainer = muiStyled(Box)(({ theme }) => ({
    width: '24px',
    display: 'none',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
        display: 'flex',
    }
}))

export const BurgerMenu = styled.img`
    width: 24px;
`

export const Header = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
}))