import React, { useState } from 'react'
import CardsExpediente from '../CardsExpediente/Index';

//assets
import maletin from '../../assets/laptop.svg';

//styles
import { CardsContainer } from './HistoriaClinica.styled';

export default function HistoriaClinica() {
    const [openCard, setOpenCard] = useState(null);


    const handleCardClick = (cardName) => {
        if (openCard === cardName) {
            // Si la tarjeta que se hizo clic ya está abierta, ciérrala.
            setOpenCard(null);
        } else {
            // Si la tarjeta que se hizo clic no está abierta, ábrela.
            setOpenCard(cardName);
        }
    };

    return (
        <CardsContainer>
            <CardsExpediente
                imgCard={maletin}
                titulo={"Antecedentes heredofamiliares"}
                onClick={() => handleCardClick("heredofamiliares")}
                isOpen={openCard === "heredofamiliares"}
            />
            <CardsExpediente
                imgCard={maletin}
                titulo={"Antecedentes personales patológicos"}
                onClick={() => handleCardClick("personalesPatologicos")}
                isOpen={openCard === "personalesPatologicos"}
            />
            <CardsExpediente
                imgCard={maletin}
                titulo={"Antecedentes personales quirúrgicos"}
                onClick={() => handleCardClick("personalesQuirurgicos")}
                isOpen={openCard === "personalesQuirurgicos"}
            />
            <CardsExpediente
                imgCard={maletin}
                titulo={"Antecedentes personales traumáticos"}
                onClick={() => handleCardClick("personalesTraumaticos")}
                isOpen={openCard === "personalesTraumaticos"}
            />
            <CardsExpediente
                imgCard={maletin}
                titulo={"Antecedentes personales no patológicos"}
                onClick={() => handleCardClick("noPatologicos")}
                isOpen={openCard === "noPatologicos"}
            />
            <CardsExpediente
                imgCard={maletin}
                titulo={"Antecedentes ginecobstétricos"}
                onClick={() => handleCardClick("ginecobstetricos")}
                isOpen={openCard === "ginecobstetricos"}
            />
            <CardsExpediente
                imgCard={maletin}
                titulo={"Inmunizaciones"}
                onClick={() => handleCardClick("inmunizaciones")}
                isOpen={openCard === "inmunizaciones"}
            />
            <CardsExpediente
                imgCard={maletin}
                titulo={"Detecciones"}
                onClick={() => handleCardClick("detecciones")}
                isOpen={openCard === "detecciones"}
            />
        </CardsContainer>
    )
}
