// AgregarTel.js
import React, { useState, useEffect, useRef } from 'react';
import { MainContainer, InputContainer, InputTel, LabelInput, SelectTel, ImgSelect, InputSelect, AddButton, ButtonSave, ButtonContainer, ButtonCancelar } from './AgregarTel.styles';
import buildng from '../../../assets/buildngsvg.svg';
import telContact from '../../../assets/telContact.svg';

export default function AgregarTel({ onAgregar, onCerrar, onMostrarBotones }) {
  const [telefonos, setTelefonos] = useState([]);
  const [companiaSeleccionada, setCompaniaSeleccionada] = useState('');
  const [telefono, setTelefono] = useState('');
  const [extension, setExtension] = useState('');
  const cerrarMenuRef = useRef(false);

  // const handleAgregarClick = () => {
  //   if (companiaSeleccionada !== '' && telefono !== '' && extension !== '') {
  //     const nuevoTelefono = {
  //       id: `id-${Math.random().toString(36).substring(2, 9)}-${Date.now()}`,
  //       compania: companiaSeleccionada,
  //       telefono,
  //       extension,
  //     };

  const handleAgregarClick = () => {
    // Validar que el número de teléfono tenga exactamente 10 caracteres
    if (telefono.length === 10) {
      const nuevoTelefono = {
        id: `id-${Math.random().toString(36).substring(2, 9)}-${Date.now()}`,
        telefono
      };

      // Llamar a la función proporcionada por el padre para enviar los datos
      onAgregar(nuevoTelefono);

      // Agregar el nuevo teléfono al estado
      setTelefonos([...telefonos, nuevoTelefono]);

      // Limpiar los campos después de agregar
      setCompaniaSeleccionada('');
      setTelefono('');
      setExtension('');
    } else {
      // Mostrar una alerta si el número de teléfono no tiene 10 caracteres
      alert("El número debe contener 10 caracteres, revise su marcación.");
    }
  };

  const handleCancelarClick = () => {
    // Invocar la función proporcionada por el padre para mostrar el botón nuevamente
    onMostrarBotones();

    // Cerrar el menú
    onCerrar();
  };

  const handleGuardarClick = () => {
    if (telefonos.length > 0) {
      // Se han agregado teléfonos, marcar para cerrar el menú
      cerrarMenuRef.current = true;
    }
  };

  // Efecto para cerrar el menú cuando cerrarMenuRef es true
  useEffect(() => {
    if (cerrarMenuRef.current) {
      onCerrar();
      cerrarMenuRef.current = false; // Restablecer la referencia
    }
  }, [onCerrar]);

  const onlyNumericInput = (value, maxLength) => {
    // Filtrar caracteres no numéricos usando una expresión regular
    const numericValue = value.replace(/\D/g, '');

    // Limitar la longitud del valor
    return numericValue.slice(0, maxLength);
  };


  return (
    <>
      <MainContainer>
        {/* <InputContainer>
          <LabelInput>Compañia</LabelInput>
          <SelectTel
            className="company-select"
            value={companiaSeleccionada}
            onChange={(e) => setCompaniaSeleccionada(e.target.value)}
          >
            <option value="">Elegir</option>
            <option value="company1">Compañía 1</option>
            <option value="company2">Compañía 2</option>
            Agrega más opciones según sea necesario
          </SelectTel>
          <ImgSelect src={buildng} />
        </InputContainer > */}
        <InputContainer>
          {/* <LabelInput>Teléfono</LabelInput> */}
          <InputTel
            type='tel'
            placeholder='Ingresar teléfono'
            value={telefono}
            onChange={(e) => setTelefono(onlyNumericInput(e.target.value, 10))}
            maxLength={10}
          />
          <InputSelect src={telContact} />
        </InputContainer >
        {/* <InputContainer>
          <LabelInput>Extensión</LabelInput>
          <InputTel
            type='tel'
            placeholder='Ingresar extensión'
            value={extension}
            onChange={(e) => setExtension(onlyNumericInput(e.target.value))}
            maxLength={4}
          />
          <InputSelect src={telContact} />
        </InputContainer> */}
        <ButtonContainer ButtonContainer >
          <ButtonCancelar onClick={() => { onCerrar(); handleCancelarClick() }}>Cancelar</ButtonCancelar>
          <ButtonSave onClick={() => { handleAgregarClick(); handleGuardarClick() }}>Guardar</ButtonSave>
        </ButtonContainer >
      </MainContainer >

    </>
  );
}
