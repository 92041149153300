import React from 'react'
import { Frame, ImageContainer, ImageService, ContentService, Title, Description } from './style'

const PrincipalServices = ({image}) => {
  return (
    <Frame>
        <ImageContainer>
            <ImageService src={image} alt='principal-services' />
        </ImageContainer>
        <ContentService>
            <Title>Servicio</Title>
            <Description>Dato</Description>
        </ContentService>
    </Frame>
  )
}

export default PrincipalServices