import React from "react";
import {
  MainContainer,
  CardTitle,
  PhoneText,
  SubtitleCard,
} from "./CardPasoServicio.style";

export default function CardPasoServicios({
  step,
  description,
  footer,
  sedcondaDescription,
}) {
  return (
    <MainContainer>
      <CardTitle>{step}</CardTitle>
      <SubtitleCard>{description}</SubtitleCard>
      {footer && (
        <PhoneText>{footer}</PhoneText>
      )}
      {sedcondaDescription && (
        <SubtitleCard>{sedcondaDescription}</SubtitleCard>
      )}
    </MainContainer>
  );
}
