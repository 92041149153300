import styled from "styled-components";

export const MainContPerf = styled.div`
    display: flex;
    width: 80%;
    padding: 10px 32px;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    background: #fbfbfb;
    box-shadow: 5px rgba(224, 223, 233, 0.5);
`
export const SubContPerf = styled.div`
    display: flex;
    width: 90%;
    height: 320px;
    padding: 2px 20px;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 19px 69px 0px rgba(224, 223, 233, 0.38);
    @media (max-width: 1210px) {
        flex-direction: column;
        height: 100%;
    }
`
export const ContImgGenn = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    border-radius: 10px;
`
export const ContImgGen = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const SecContPerf = styled.div`
    display: flex;
    width: 90%;
    //height: 240px;
    padding: 32px 20px 30px 20px;
    justify-content: center;
    align-items: center;
    //gap: 200px;

    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 19px 69px 0px rgba(224, 223, 233, 0.38);
    @media (max-width: 1210px) {
        flex-direction: column;
        ;
    }
`
export const ContUplImg = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 24px auto;
    padding: 0 40px;
    gap: 30px;
    @media (max-width: 1210px) {
        padding: 0;
        gap: 20px;
    }
`
export const FlexEnd = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`
export const UplImgLabel = styled.label`
    display: flex;
    flex-direction: column;
    gap: 16px;
`
export const ContListTel = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    @media (max-width: 1210px) {
        display: none;
        //flex-direction: start;
    }
`
export const ContListTelMob = styled.div`
    display: none;
    
    width: 90%;
    @media (max-width: 1210px) {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
`


export const ContTextList = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    background-color: #F3F2F2;
    border-radius: 16px;
    @media (max-width: 1210px) {
        flex-direction: column;
        align-items: start;
    }
`
export const EncabezadoList = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1210px) {
        flex-direction: column;
        align-items: start;
    }
`
export const ContAddBtn = styled.div`
    display: flex;
    align-items: center;
    margin: 16px 0 0 24px;
`
export const ContLapizTrash = styled.div`
    display: flex;
    gap: 16px;
`

export const InfoTel = styled.div`
    display: flex;
`

export const ImgPerfText = styled.p`
    color: #5F6267;
    margin: 0;

    /* Common/Tittle */
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 1210px) {
        text-align: center;
        }
`
export const SelectCalendText = styled.p`
    color: #5F6267;
    margin: 0;

    /* Common/Tittle */
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`
export const CardListTitle = styled.p`
    color: #5F6267;

    /* Common/Tittle */
    font-family: Lato;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`
export const CardListText = styled.p`
    color: #5F6267;

    /* Common/Tittle */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`
export const AddTelBtn = styled.p`
    color: var(--Primary, #00C46B);
    text-align: start;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    margin: 0;
    cursor: pointer;
`
export const ListTelTitle = styled.p`
    color: #5F6267;

    /* Common/Tittle */
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`

export const SelImgBtn = styled.button`
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 40px;
    background: var(--Primary, #00C46B);
    border: none;
    cursor: pointer;

    color: var(--Background, #F6F6F6);
    text-align: center;
    /* Common/Button */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`
export const DivisorMiPerf = styled.div`
    width: 1px;
    height: 85%;
    background-color: var(--Body, #5F6267);
`
export const DivisorLisTel = styled.div`
    width: 100%;
    height: 1px;
    background-color: #5c5f64;
`
export const SelectGenPerf = styled.select`
    display: flex;
    width: 245px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 16px;
    border: none;
    cursor: pointer;

    border-radius: 16px;
    background: #F3F2F2;

    color: #6C81A0;

    /* Common/Button */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 1210px) {
        width: 130%;
        margin-top: 0;
    }
`
export const SelectCalenPerf = styled.input`
    display: flex;
    width: 245px;
    padding: 12px 16px;
    align-items: center;
    gap: 30px;
    margin-top: 20px;
    border: none;
    cursor: pointer;

    border-radius: 16px;
    background: #F3F2F2;

    color: #6C81A0;
    /* Common/Tittle */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 1210px) {
        width: 100%;
        margin-top: 0;
    }
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`
export const ButtonSave = styled.button`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  cursor: pointer;
  border-radius: 40px;
  border-color: #00C46B;
  border-style: solid;
  background: var(--Primary, #00C46B);
  color: var(--Background, #F6F6F6);
  text-align: center;

  /* Common/Button */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
export const ImgList = styled.img`
    width: auto;
    height: auto;
    cursor: pointer;
`
export const BtnEditTrash = styled.button`
    border: none;
    background: none;
`
export const TabRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap:16px
`


