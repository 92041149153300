import React from 'react';
import starfill from '../assets/StarFill.svg'
import star from '../assets/Star.svg'

const Star = ({ filled, onClick }) => (
  <img
    src={filled ? starfill : star}
    alt={filled ? 'Estrella con relleno' : 'Estrella sin relleno'}
    draggable={false}
    onClick={onClick}
    style={{ cursor: 'pointer', width: '51px', height: '51px' }}
  />
);

const StarRating = ({ valueRating, disabled, onSetValueRating }) => {

  const handleStarClick = (index) => {
    const newSelectedStars = valueRating.map((_, i) => i <= index);
    onSetValueRating(newSelectedStars);
  };

  return (
    <div>
      {Array.from({ length: 5 }, (_, index) => (
        <Star
          key={index}
          filled={valueRating[index]}
          onClick={disabled ? () => null : () => handleStarClick(index)}
        />
      ))}
    </div>
  );
};

export default StarRating;
