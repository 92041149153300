import styled from "styled-components";

export const MainContainer = styled.div`
    display: flex;
    //width: 100%;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 32px 20px 30px 20px;
    background: #FFF;
    box-shadow: 0px 36.727px 133.375px 0px rgba(224, 223, 233, 0.38);
    @media (max-width: 1210px) {
        padding: 32px 24px;
        text-align: center;
        flex-direction: column;
    }
`

export const ImgCard = styled.img`
        width:84px;
`
export const WarnigContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap:10px
`
export const DrNameContainer = styled.div`
    display: flex;
    flex-direction: column;
`
export const TitleDr = styled.p`
    color: #000;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`
export const DrName = styled.span`
    color: #000;

    /* Common/Body */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

export const Divisor = styled.div`
    width: 1px;
    height: 157px;
    background-color: var(--Body, #5F6267);;
    margin-right: 16PX;

    @media (max-width: 1210px) {
        width: 90%;
        height: 1px;
        background: var(--Background, #F6F6F6);
    }

`

export const TitleGray = styled.p`
    color: var(--Body, #5F6267);
    text-align: left;
    cursor: pointer;
    /* Desktop/H4 */
    font-family: Josefin Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
   

    &.open {
        border-bottom: 5px solid #86F2B1;
    }
    @media (max-width: 1210px) {
        margin: 12px;
    }
`

export const TitleGrayContaier = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    @media (max-width: 1210px) {
        gap:0;
        margin: 50x
    }

`

export const TextAreaCard = styled.textarea`
    display: flex;
    width: 422px;
    height: 185px;
    padding: 10px 8px 10px 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    background: var(--Background, #F6F6F6);
    color: #000000;
    resize: none;

    /* Common/Legal */
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &::placeholder {
        color: #d9d9d9;
    }
     @media (max-width: 1210px) {
        width: 100%;
        margin: 0 auto;
    }
`
export const ContainerTextArea = styled.div`
     @media (max-width: 1210px) {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }

`
export const ImgTextArea = styled.div`
    display: flex;
    gap: 32px;
    text-align: start;
    margin-right: 30px;
    @media (max-width: 800px) {
        margin-left: 32px;
        gap: 20px;
    }
`
