import React from 'react'
import CoverHeader from '../HistorialServicios/Hero/Hero'
import NavTabMenuClinicRecord from '../NavTabMenuClinicRecord'
import { Box, styled } from '@mui/material'

const Frame = styled(Box)`

`

export const WrapperMargin = styled(Box)`
    flex-direction: column;
    width: 90%;
    margin:0 auto;
    display: flex;
    flex-direction: column;
`
const ClinicalRecordBase = ({
    children,
    sectionSelected,
    onSetSectionSelected,
}) => {
    return (
        <Frame>
            <WrapperMargin>
                <CoverHeader title={"Expediente clínico"} />
            </WrapperMargin>
            <NavTabMenuClinicRecord
                sectionSelected={sectionSelected}
                onSetSectionSelected={onSetSectionSelected}
            />
            <WrapperMargin>
                {children}
            </WrapperMargin>
        </Frame>
    )
}

export default ClinicalRecordBase